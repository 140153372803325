import enError404 from "./texts/404"
import enArticles from "./texts/articles"
import enContact from "./texts/contactText"
import enCookieDisabled from "./texts/cookieDisabled"
import enFooter from "./texts/footerText"
import enHeader from "./texts/headerText"
import enHome from "./texts/homeText"
import enHomeCarousel from "./texts/homeCarouselText"
import enMaintenance from "./texts/maintenance"
import enPartners from "./texts/partnersText"
import enShows from "./texts/showsText"
import enTermsOfUse from "./texts/termOfuseText"
import enTroupe from "./texts/troupeText"
import enWorkshop from "./texts/workshopText"

const en = {
    moreDetails: 'Find out more',
    readMore: 'Lire',
    emptyList: "There is nothing here right know",
    send: 'Send',
    playVideo: 'Voir la vidéo',
    linkOpenImage: 'Link: Open image ',

    ...enMaintenance,
    ...enCookieDisabled,

    ...enHeader,
    ...enFooter,

    ...enHome,
    ...enHomeCarousel,

    ...enShows,
    ...enWorkshop,

    ...enTroupe,

    ...enContact,

    ...enArticles,
    ...enPartners,
    ...enTermsOfUse,

    ...enError404
}

export default en