const showCatList = {
    activityMusic: 'activity-music',
    angelImp: 'angel-imp',
    balloonSculpture: 'ballon-sculpture',
    bright: 'bright',
    bubbles: 'bubbles',
    carnival: 'carnival',
    charAmbulatory: 'char-ambulatory',
    cinema: 'cinema',
    clown: 'clown',
    clubbing: 'clubbing',
    chic: 'chic',
    christmas: 'christmas',
    christmasMarket: 'christmas-market',
    cosplay: 'cosplay',
    dance: 'dance',
    diaDeLosMuertos: 'dia-de-los-muertos',
    easter: 'easter',
    eroticism: 'eroticism',
    fantasy: 'fantasy',
    fireBreather: 'fire-breather',
    futuristic: 'futuristic',
    halloween: 'halloween',
    horror: 'horror',
    juggling: 'juggling',
    latexVinyl: 'latex-vinyl',
    love: 'love',
    medieval: 'medieval',
    mime: 'mime',
    movieFestival: 'movie-festival',
    music: 'music',
    musicFestival: 'music-festival',
    nationalDay: 'national-day',
    new: 'new',
    newYearEve: 'new-year-eve',
    parentsDay: 'parents-day',
    performer: 'performer',
    privateShow: 'private-show',
    product: 'product',
    pyrotechnics: 'pyrotechnics',
    shoppingGallery: 'shopping-gallery',
    skiResort: 'ski-resort',
    steampunk: 'steampunk',
    stilt: 'stilt',
    streetFestival: 'street-festival',
    strip: 'strip',
    tattooFair: 'tattoo-fair',
    valentine: 'valentine',
    wedding: 'wedding',
    witchDay: 'wich-day',
    white: 'white'
}

export default showCatList