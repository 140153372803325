const esWorkshop = {
    // General
    allWorkshopsTitle: 'Todos los talleres',
    allWorkshopText: <>
        <p>
            Aquí te <span className='uk-text-bold'>presentamos los Talleres</span>, <br/>
            La empresa CDLS te acompaña en tus primeros pasos como artista y <span className='uk-text-bold'>comparte su saber hacer</span> contigo!
        </p>

        <p>
            ¡En este espacio, <span className='uk-text-bold'>el aprendizaje y la creatividad</span> irradian <span
            className='uk-text-bold'>alegría y buen humor</span>!
            <br/>
            Adaptados a todos, niños y adultos, sabemos manejar la práctica mientras nos divertimos, <br/>
            todo <span className='uk-text-bold'>supervisado por animadores y artistas competentes</span>.
        </p>

        <p>
            Las actividades que se ofrecen son tanto manuales como sensacionales
            <br/>
            para <span className='uk-text-bold'>satisfacer al máximo sus expectativas</span>.
        </p>

        <p>
            ¡Aquí están NUESTROS TALLERES, ¡y te están esperando!
            <br/>
            ¡Es tu turno !
        </p>
    </>,
    allWorkshopsMetaDescription: 'Lista de todos los talleres ofrecidos por la compañía Clowns dans la sciure',
    workshopList: <>
        <p className='uk-text-center'>Voici tous les ateliers <br/>que nous vous proposons</p>
    </>,

    bubblesWorkshopTitle: "Taller de burbujas",
    bubblesWorkshopShortDescription: <>
        ¡Pequeñas, grandes o gigantes, <br/>el arte de las burbujas se puede aprender a cualquier edad <br/>ven y haz burbujas con nosotros!
    </>,
    bubblesWorkshopDescription: <>
        <p>
            Bienvenido a un <span className='uk-text-bold'>mundo de transparencia y pureza</span> para jóvenes y mayores.
            <br/>
            Todo el mundo sabe hacer burbujas, pero ¿qué pasa con <span className='uk-text-bold'>las gigantes y las multiburbujas</span> de 3 metros de altura?
            <br/>
            Mezcle tonos multicolores en esferas aún más grandes.
        </p>

        <p>
            Con <span className='uk-text-bold'>equipos adecuados para todos</span>, nuestros artistas burbujeantes estarán a su lado para <span
            className='uk-text-bold'>nuestros artistas burbujeantes estarán a su lado para</span>.
            <br/>
            ¡Creativa y visual, esta actividad cautiva a niños, padres y abuelos!
        </p>

        <p>
            <span className='uk-text-bold'>En show o en Workshop,</span>, <br/>
            Deje que la poesía tenga lugar en su evento.
            <br/>
            <span className='uk-text-bold'>¡Un cóctel mágico para un momento único!</span>
        </p>
    </>,

    circusWorkshopTitle: "Taller de circo",
    circusWorkshopShortDescription: <>
        Taller de malabares, pelotas, bolas, mazas...
        <br/>
        Aprenda los conceptos básicos con el nivel que le corresponda
    </>,
    circusWorkshopDescription: <>
        <p>
            – ¡¡¡¿Pero qué veo en el cielo? !!!
            <br/>
            – ¡¿Pero es una bala ?!
            <br/>
            – ¡¿Pero es una bala ?!
            <br/>
            – ¡¿Pero es una bala ?!
        </p>

        <p>
            ¡Fantástico! ¡Acaba de llegar a la zona para niños mayores!
            <br/>
            ¡Aquí te ofrecemos una animación sobre el <span className='uk-text-bold'>tema de las artes del malabarismo</span>!
            <br/>
            ¡<span className='uk-text-bold'>Una iniciación en el descubrimiento</span> del potencial de su cuerpo y la manipulación de varios objetos!
        </p>

        <p>
            <span className='uk-text-bold'>Diversión y buen ambiente garantizados</span> en compañía de apasionados <br/>
            siempre dispuestos a transmitir sus conocimientos con el inmenso <span className='uk-text-bold'>placer de compartir</span>.
        </p>
    </>,

    creativeWorkshopTitle: "Taller creativo",
    creativeWorkshopShortDescription: <>
        ¡El único límite es su imaginación!
        <br/>
        ¡El único límite es su imaginación!
    </>,
    creativeWorkshopDescription: <>
        <p>
            ¡Aquí está el taller que hace que todo suceda!
            <br/>
            Partimos de nada o poco y <span className='uk-text-bold'>construimos juntos para que te vayas con tu recuerdo ... ¡en la vida real!</span>
        </p>

        <p>
            Todo ello en <span className='uk-text-bold'>creatividad, sencillez y aprendizaje de forma divertida y lúdica</span>.
            <br/>
            <span className='uk-text-bold'>Nos adaptamos a tu programa</span> en función del evento ofreciéndote diferentes talleres de construcción como
            : <br/>
            construcción de esferas, fabricación de bolas, etc.
        </p>

        <p>
            Adaptado para adultos o niños, <span className='uk-text-bold'>sabremos despertar tu creatividad con siempre un animador a tu disposición</span>.
        </p>

        <p>
            Combinado con el taller de circo, <span
            className='uk-text-bold'>usted tiene una combinación perfecta para el descubrimiento, el aprendizaje y la realización</span>.
        </p>

        <p>¡Es tu turno!</p>
    </>,

    makeupWorkshopTitle: "Taller de maquillaje",
    makeupWorkshopShortDescription: <>
        ¡Sumérgete en la piel <br/>de tu personaje!
        <br/>
        ¡Bienvenido a los mil y un brillos!
    </>,
    makeupWorkshopDescription: <>
        <p>
            Mezcla de saber hacer, ocio y profesionalidad, <br/>
            Mezcla de saber hacer, ocio y profesionalidad <span className='uk-text-bold'>primeros pasos de maquillaje</span>.
        </p>

        <p>
            Aprende a combinar tu maquillaje, conocer el material y poner tu "garra" artística en tus cosplays más bonitos, <span
            className='uk-text-bold'>para vivir plenamente tu día</span>.
            <br/>
            ¡Deje espacio a la imaginación de jóvenes y mayores!
        </p>

        <p>
            Por supuesto, es posible tener <span className='uk-text-bold'>una maquilladora o un maquillador profesional <br/>
            para tu evento</span>, ¡no dudes en hacérnoslo saber!
        </p>

        <p>Entre polvos y purpurina, <span className='uk-text-bold'> responderemos a su solicitud</span>.</p>
    </>,

    musicalAwakeningWorkshopTitle: "Taller despertar musical",
    musicalAwakeningWorkshopShortDescription: <>
        Una mezcla de ritmo para aprender
        <br/>
        mientras te diviertes ... ¡ese boom boom!
    </>,
    musicalAwakeningWorkshopDescription: <>
        <p>¡Pum chack! ¡Pum chack! Ding Ding Ding!</p>

        <p>
            ¡Aquí estás en el taller de <span className='uk-text-bold'>despertar musical</span> !
            <br/>
            ¡Aquí vamos paso a paso para descubrir el <span className='uk-text-bold'>universo de la música y el ritmo</span> a través de nuestro cuerpo y lo que
            nos rodea!
            <br/>
            ¡Verás surgir al músico que se esconde en ti a través de nuestros <span className='uk-text-bold'>ejercicios  lúdicos y divertidos</span>!
        </p>

        <p>
            ¡Accesible para adultos y niños!
            <br/>
            ¡Perfecto para <span className='uk-text-bold'>crear lazos afectivos y divertirse juntos</span>!
        </p>

        <p>¡Despierta a tu artista interior!</p>
    </>,

    stiltWorkshopTitle: "Taller de zancos",
    stiltWorkshopShortDescription: <>
        Supervisado por nuestros profesionales <br/>del espectáculo, estará encantado de tomar <br/>altura
    </>,
    stiltWorkshopDescription: <>
        <p>
            ¡¿Zancos estáticos o urbanos, de <span className='uk-text-bold'>pequeña o gran altura</span>, <br/>
            para jóvenes y mayores?!
            <br/>
            ¡Has venido al lugar correcto!
            <br/>
            ¿Complejo de ser pequeño? ¿Frustrado por no poder llegar nunca a la mejor fruta del árbol?
            <br/>
            ¿Dolor de cuello a costa de besar a sus cónyuges demasiado altos?
            <br/>
            ¡El taller de zancos te invita a vivir una <span
            className='uk-text-bold'>experiencia sin precedentes en el universo  de las personas altas</span>!
        </p>

        <p>
            Un taller de calidad dirigido por profesionales <span className='uk-text-bold'>apasionados de la disciplina</span> <br/>
            en un ambiente de <span className='uk-text-bold'>tranquilidad y seguridad</span>!
            <br/>
            ¡Aquí vamos !
        </p>
    </>,

    theaterWorkshopTitle: 'Taller de teatro',
    theaterWorkshopShortDescription: <>
        Aprender los fundamentos del teatro a través de juegos.
        <br/>
        A divertirnos juntos y venid a superarse
    </>,
    theaterWorkshopDescription: <>
        <p>Ser o no ser ?! Tal es la cuestión !</p>

        <p>
            En este taller te invitamos a <span className='uk-text-bold'>SER simplemente</span> y a descubrir tus <span className='uk-text-bold'>habilidades teatrales de manera lúdica</span> a
            través de actividades inventadas por nosotros...
            <br/>
            Respiraciones, apertura al público, cohesión grupal...
            <br/>
            y <span className='uk-text-bold'>todo esto a través del juego</span>
        </p>

        <p>
            ¡Supervisados por artistas y entrenadores experimentados, <span className='uk-text-bold'>la alegría, la risa y el compartir estarán seguramente presentes</span>!
        </p>

        <p>
            SSi aún tienes dudas sobre unirte a nosotros, <span className='uk-text-bold'>¡este taller es para ti!</span>
            <br/>
            ¡Arrancó!
        </p>
    </>,

    writingWorkshopTitle: 'Taller de escritura',
    writingWorkshopShortDescription: <>¡Venga a descubrir el poder de las palabras!</>,
    writingWorkshopDescription: <>
        <p>
            Las posibilidades son enormes. El tiempo puede variar <span className='uk-text-bold'>desde una tarde hasta una semana completa</span>. <br/>
            <span className='uk-text-bold'>Un placer jugar con las palabras</span> para los <span className='uk-text-bold'>aficionados</span> o mejorar para un trabajo más <span className='uk-text-bold'>profesional</span>, <br/>
            o incluso en forma de <span className='uk-text-bold'>terapia</span>. <br/>
            Los temas pueden variar.
        </p>
        <p>
            Para los <span className='uk-text-bold'>aprendices de autores</span>: desde la escritura de una novela hasta su promoción... <br/>
            En la <span className='uk-text-bold'>escuela</span>: diferencia, discapacidad, fantasía... <br/>
            Para las <span className='uk-text-bold'>vacaciones</span>: el mar, la amistad... <br/>
            Cada taller <span className='uk-text-bold'>está hecho a medida</span> según el grupo de edad, el tema y el tiempo disponible.
        </p>
        <p>
            ¡Divertido, educativo y un poco loco!
        </p>
    </>,
}

export default esWorkshop