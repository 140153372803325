import Link from "next/link"
import {GetSVG} from "../../../providers/Language"
import path from '../../../contents/paths'

import beblibop from 'img/partners/troupes/logo_beblibop.png'
import elCircus from 'img/partners/troupes/el_circus.png'
import whiteTigers from 'img/partners/troupes/white_tiger_logo.png'

const frPartners = {
    partnersTitle: 'Les partenaires',
    partnerDescription: <>
        <h3 className='uk-text-bold'>Devenez partenaire</h3>

        <p>Être partenaire des &quot;Clowns dans la Sciure&quot;, c&apos;est associer l&apos;image de votre entreprise à la notre dans le secteur du spectacle
            vivant de la région
            et contribuer au rayonnement culturel du Midi-Pyrénées et Languedoc-Roussillon.</p>
        <p>Être partenaire de la Compagnie Clowns dans la Sciure, c&apos;est bénéficier d&apos;une relation privilégiée et plusieurs avantages :</p>

        <ul>
            <li>Invitations aux spectacles pour vos clients, relations d&apos;affaire, collaborateurs, etc...,</li>
            <li>Travailler main dans la main pour satisfaire les organisateurs et bien sûr le public</li>
            <li>Découverte de la saison en avant-première et accès prioritaire à vos artistes pour les spectacles</li>
        </ul>

        <h3 className='uk-text-bold'>Compagnies partenaires</h3>

        <ul>
            <li>
                <p>
                    Compagnie <a href='https://el-circus.fr/' target="_blank" rel='noopener noreferrer' className='uk-link-text uk-text-italic text-hover-gold'>
                    El Circus
                </a>&nbsp;
                    (Cirque, strip, performer, mascotte)
                    <br/>
                    Section spectacle <Link href={path.striptease}>
                    <span className='text-black text-hover-gold'><GetSVG id={'stripteaseTitle'} classes='uk-width-small'/></span>
                </Link>
                    <br/>
                    <a href='https://el-circus.fr/' target="_blank" rel='noopener noreferrer' className='uk-text-italic text-hover-gold'>
                        Site web
                    </a>
                </p>
                <img src={elCircus} alt={elCircus.text} loading='lazy' className='uk-width-medium' data-uk-img=''
                     onDragStart={(event => event.preventDefault())}/>
            </li>
            <li>
                <p>
                    Compagnie Beblibop (Cirque)
                    <br/>
                    Section spectacles
                    <Link href={path.clowns} className='text-black text-hover-gold'>
                        <GetSVG id={'clownsTitle'} classes='uk-width-small'/>
                    </Link>
                    et
                    <Link href={path.rosalie} className='text-black text-hover-gold'>
                        <GetSVG id={'rosalieTitle'} classes='uk-width-small'/>
                    </Link>
                </p>
                <img src={beblibop} alt={beblibop.text} loading='lazy' className='uk-width-small' data-uk-img=''
                     onDragStart={(event => event.preventDefault())}/>
            </li>
            <li>
                <p>
                    Andy Klein (Strip)<br/>
                    Section spectacle
                    <Link href={path.striptease} className='text-black text-hover-gold'>
                        <GetSVG id={'stripteaseTitle'} classes='uk-width-small'/>
                    </Link>
                </p>
            </li>
            <li>
                <p>
                    White-Tigers Performance (Cirque, strip, performer)
                    <br/>
                    Section spectacle
                    <Link href={path.striptease} className='text-black text-hover-gold'>
                        <GetSVG id={'stripteaseTitle'} classes='uk-width-small'/>
                    </Link>
                </p>
                <img src={whiteTigers} alt={whiteTigers.text} loading='lazy' className='uk-width-small' data-uk-img=''
                     onDragStart={(event => event.preventDefault())}/>
            </li>
        </ul>
    </>,
    partnersSlogan: "Rejoignez-nous et travaillons main dans la main. Faisons équipe pour notre public !"
}

export default frPartners