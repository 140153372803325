import {createContext, useContext, useState} from "react"
import {altImageWithFileName} from "../Global"
import langList from "../lang/langList"

/**
 * @type {React.Context<{dictionary: Object, userLanguage: string}>} Default website language and loaded dictionary
 */
const LanguageContext = createContext({
    userLanguage: 'fr',
    dictionary: langList.fr.dictionary
})

/**
 * @returns {{dictionary: Object, userLanguage: string}} Hook to get language context data
 */
function useLanguage() {
    return useContext(LanguageContext)
}

/**
 * @returns {string} Detect the language of the browser and update the language to the corresponding one available
 */
function detectLanguage() {
    const browserLang = navigator.language

    if (/^fr\b/.test(browserLang))
        return 'fr'

    if (/^en\b/.test(browserLang))
        return 'en'

    if (/^es\b/.test(browserLang))
        return 'es'

    return 'en'
}

/**
 * Provider to manage website's language
 * @param children Children components
 * @returns {JSX.Element} Rendered components
 * @constructor
 */
function LanguageProvider({children}) {
    const [userLanguage, setUserLanguage] = useState(detectLanguage())

    const provider = {
        userLanguage,
        dictionary: langList[userLanguage].dictionary,
        userLanguageUpdate: selected => {
            const lang = langList[selected] ? selected : detectLanguage()
            setUserLanguage(lang)

            localStorage.setItem('lang', lang)
        }
    }

    return (
        <LanguageContext.Provider value={provider}>
            {children}
        </LanguageContext.Provider>
    )
}

/**
 * Get SVG from language file
 * @param props id of file
 * @return {JSX.Element}
 * @constructor
 */
function GetSVG(props) {
    const lang = useLanguage()
    const file = lang.dictionary[props.id] || props.id

    return <img src={file} alt={altImageWithFileName(file)} loading='lazy' data-uk-img='' data-uk-svg='' onDragStart={(event => event.preventDefault())}
                className={`${props.classes}`}/>
}

/**
 * Get text from language file
 * @param id Key of the text to show
 * @returns {*}
 * @constructor
 */
function Text({id}) {
    const lang = useLanguage()
    return lang.dictionary[id] || id
}

export default LanguageProvider
export {GetSVG, Text, LanguageContext, useLanguage}