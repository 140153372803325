import esError404 from "./texts/404"
import esArticles from "./texts/articles"
import esContact from "./texts/contactText"
import esCookieDisabled from "./texts/cookieDisabled"
import esFooter from "./texts/footerText"
import esHeader from "./texts/headerText"
import esHome from "./texts/homeText"
import esHomeCarousel from "./texts/homeCarouselText"
import esMaintenance from "./texts/maintenance"
import esPartners from "./texts/partnersText"
import esShows from "./texts/showsText"
import esTermsOfUse from "./texts/termOfuseText"
import esTroupe from "./texts/troupeText"
import esWorkshop from "./texts/workshopText"

const es = {
    moreDetails: 'MÁS INFORMACIÓN',
    readMore: 'LEER',
    emptyList: "No hay nada aquí en este momento",
    send: 'ENVIAR',
    playVideo: 'Ver el vídeo',
    linkOpenImage: "Enlace: Abrir imagen",

    ...esMaintenance,
    ...esCookieDisabled,

    ...esHeader,
    ...esFooter,

    ...esHome,
    ...esHomeCarousel,

    ...esShows,
    ...esWorkshop,

    ...esTroupe,

    ...esContact,

    ...esArticles,
    ...esPartners,
    ...esTermsOfUse,

    ...esError404,
}

export default es