import dynamic from "next/dynamic"
import {useEffect, useState} from "react"
import ReactDOMServer, {renderToString} from "react-dom/server"
import {useMatomo} from "@jonkoops/matomo-tracker-react"
import axios from "axios"
import UIkit from "uikit"
import {Text, useLanguage} from "./providers/Language"
const Header = dynamic(() => import("../components/pageComponent/Header"))
const Footer = dynamic(() => import("../components/pageComponent/footer/Footer"))

import articles from "./contents/articles"

/**
 * Page model to include easily the Header and the footer
 * @param children DOM added in the component
 * @return {JSX.Element}
 * @constructor
 */
function PageProvider({children}) {
    const {trackPageView} = useMatomo()

    useEffect(() => {
        const toTop = document.getElementById('to-top')

        window.onscroll = () => {
            if (document.body.scrollTop > 400 || document.documentElement.scrollTop > 400) {
                toTop.style.visibility = 'visible'
                toTop.style.opacity = '1'
            }
            else {
                toTop.style.visibility = 'hidden'
                toTop.style.opacity = '0'
            }
        }

        trackPageView()
    }, [trackPageView])

    return (
        <>
            <Header/>
            <div className='uk-position-relative'>
                {children}
                <div id='to-top' className='uk-margin-small-bottom uk-margin-small-right uk-border-circle'>
                    <button className='uk-position-center uk-text-bold text-white' data-uk-totop='' data-uk-scroll=''>
                        <span className='accessibility-screen-reader'>To Top</span>
                    </button>
                </div>
            </div>
            <Footer/>
        </>
    )
}

/**
 * Listen to the browser screen size
 * @return {number[]}
 */
function useWindowSize() {
    const [size, setSize] = useState([undefined, undefined])

    useEffect(() => {
        function updateSize() {
            setSize([window.visualViewport.width, window.visualViewport.height])
        }

        window.visualViewport?.addEventListener('resize', updateSize)
        updateSize()

        return () => window.visualViewport?.removeEventListener('resize', updateSize)
    }, [])

    return size
}

function altImageWithFileName(file) {
    return file.substring(file.lastIndexOf('/') + 1)
}

/**
 * Orient a list of elements in a row for large screens and a column for small screens
 * @param props children elements, classNames and custom attributes (like data-uk-* if needed)
 * @return {JSX.Element}
 */
function FlexDirection(props) {
    const [screenWidth] = useWindowSize()
    const minWidth = props.minWidth || 1000

    if (screenWidth > minWidth) {
        return <div className={`uk-flex uk-flex-row ${props.classNameLarge}`}>{props.children}</div>
    }
    else {
        return <div className={`uk-flex uk-flex-column ${props.classNameSmall}`}>{props.children}</div>
    }
}

/**
 * Simulate click on a target
 * @param e Event
 * @param interval interval duration in ms
 * @return {number} Interval event response
 */
function handleClickHover(e, interval) {
    const target = e.target
    const doClick = target => target.click()

    return setInterval(() => {
        doClick(target)
    }, interval)
}

/**
 * Clear interval
 * @param slideInterval setIntervalEvent
 */
function clearSetInterval(slideInterval) {
    clearInterval(slideInterval)
}

/**
 * Retrieve data from localStorage
 * @param name Key name of value to retrieve in localStorage
 * @returns {string|undefined}
 */
function getLocalStorageValue(name) {
    const storedValue = localStorage.getItem(name)
    return storedValue ? storedValue : undefined
}

/**
 * Check if an input is empty of null
 * @param value Value from an input
 * @param fieldId Text id of the field label
 * @returns {boolean}
 */
function isEmptyValue(value, fieldId) {
    if (value === '' || value === null || value === undefined)
        return UIkit.notification({
            message: renderToString(<><Text id={'contactInputEmpty'}/><Text id={fieldId}/>.</>),
            status: 'danger',
            timeout: 3000
        })
}

/**
 * Prepare the received data (check of content to prevent useless emails and have some security) to send an email
 * @param name Lastname and Firstname of the contact
 * @param email Email address of the contact
 * @param phone Phone number of the contact
 * @param subject The purpose of the message
 * @param message Message content
 */
async function getContactFormData(name, email, phone, subject, message) {
    let nameStatus, emailStatus, phoneStatus, subjectStatus, messageStatus = false

    if (!isEmptyValue(name, 'nameContact')) {
        const nameRegex = new RegExp("[\\w\\s\\-\u00C0-\u00FF]+")

        nameStatus = nameRegex.test(name)
    }
    if (!isEmptyValue(email, 'emailContact')) {
        const emailRegex = new RegExp("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?")

        emailStatus = emailRegex.test(email)
    }
    if (!isEmptyValue(phone, 'phoneContact')) {
        const phoneRegex = new RegExp("[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s./0-9]*")

        phoneStatus = phoneRegex.test(phone)
    }
    if (!isEmptyValue(subject, 'subjectContact')) {
        subjectStatus = true
    }
    if (!isEmptyValue(message, 'Message')) {
        const messageRegex = new RegExp("<.*?script.*\\/?>", "gi")

        messageStatus = !messageRegex.test(message)
    }

    if (nameStatus && emailStatus && phoneStatus && subjectStatus && messageStatus) {
        await axios.post('/api/email', {
            name: name,
            email: email,
            phone: phone,
            subject: subject,
            message: message
        })
            .then(res => {
                switch (res.status) {
                    case 200:
                        return UIkit.notification({
                            message: renderToString(<Text id={'contactSuccess'}/>),
                            status: 'success',
                            timeout: 3000
                        })
                    case 400:
                        return UIkit.notification({
                            message: JSON.stringify(res.data.message),
                            status: 'warning',
                            timeout: 3000
                        })
                    default:
                        return UIkit.notification({
                            message: renderToString(<Text id={"contactSendFailed"}/>),
                            status: 'danger',
                            timeout: 3000
                        })
                }
            })

        return true
    }
    else {
        if (!messageStatus) {
            UIkit.notification({
                message: renderToString(<Text id={"contactScriptTagDetected"}/>),
                status: 'danger',
                timeout: 3000
            })
        }
        else {
            UIkit.notification({
                message: renderToString(<Text id={"contactRequirementsFailed"}/>),
                status: 'warning',
                timeout: 3000
            })
        }

        return false
    }
}

/**
 * Prepare the received data to send it to Sendinblue for newsletter management
 * @param email Email address from the user
 */
function getNewsletterData(email) {
    let emailStatus = false

    if (!isEmptyValue(email, '')) {
        const emailRegex = new RegExp("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?")

        emailStatus = emailRegex.test(email)
    }

    if (emailStatus) {
        axios.post('/api/subscribe', {
            email: email
        })
            .then(res => {
                switch (res.status) {
                    case 200:
                        return UIkit.notification({
                            message: renderToString(<Text id={'newsletterSuccess'}/>),
                            status: 'success',
                            timeout: 3000
                        })
                    case 400:
                        return UIkit.notification({
                            message: res.data.message,
                            status: 'warning',
                            timeout: 3000
                        })
                    default:
                        return UIkit.notification({
                            message: 'Impossible to send email',
                            status: 'danger',
                            timeout: 3000
                        })
                }
            })
    }
}

/**
 * Sort array of objects by date in descending order
 * @param array
 * @returns {*}
 */
function descendingSortArrayByDate(array) {
    return array.slice().sort(function (a, b) {
        return new Date(ReactDOMServer.renderToString(a.date)) - new Date(ReactDOMServer.renderToString(b.date))
    })
}

/**
 * Update the date format depending on the language
 * @param date Publication date of the article
 * @returns {string|*} Component with the right date format
 * @constructor
 */
function ChangeArticleDateFormat({date}) {
    const lang = useLanguage()
    const tempoDate = renderToString(date).split('/')

    switch (lang.userLanguage) {
        case 'fr':
            return date

        default:
        case 'en':
            return tempoDate[1] + '/' + tempoDate[0] + '/' + tempoDate[2]
    }
}

/**
 * Get an article by it date (normally there is not more than one article per day)
 * @param date
 * @returns {{date: JSX.Element, path: string, shortDescription: JSX.Element, title: JSX.Element, content: JSX.Element}|{date: JSX.Element, path: string, shortDescription: JSX.Element, title: JSX.Element, content: JSX.Element}|{date: JSX.Element, path: string, shortDescription: JSX.Element, title: JSX.Element, content: JSX.Element}|{date: JSX.Element, path: string, shortDescription: JSX.Element, title: JSX.Element, content: JSX.Element}}
 */
function extractArticle(date) {
    const lang = localStorage.getItem('lang')

    return articles.filter(function (article) {
        if (lang === 'en') {
            const tempoDate = renderToString(article.date).split('/')

            return tempoDate[1] + '/' + tempoDate[0] + '/' + tempoDate[2] === date
        }
        else {
            return article.date === date
        }
    })[0]
}

export default PageProvider
export {
    useWindowSize,
    altImageWithFileName,
    FlexDirection,
    handleClickHover,
    clearSetInterval,
    getLocalStorageValue,
    descendingSortArrayByDate,
    getContactFormData,
    getNewsletterData,
    ChangeArticleDateFormat,
    extractArticle
}