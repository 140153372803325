import followInsta from 'img/general/svg/social/suivez_insta.svg'

const frFooter = {
    // FOOTER MENU
    newsletterTitle: 'Abonnez-vous à la newsletter',
    newsletterText: 'Rester informé sur les actualités de la compagnie',
    newsletterButton: "S'ABONNER",
    newsletterSuccess: 'Félicitation ! Vous êtes inscrit',

    // ARTICLES
    waitingArticles: "Ça arrive bientôt !",

    // SOCIAL
    followInstagram: <>
        Voici quelques stories d'Instagram ! sur notre compte officiel @clownsdanslasciurespectacles <br/>
        Pour en voir davantage, rejoignez-nous !
    </>,
    followInstagramFooter: 'Suivez-nous sur Instagram',
    followInstagramButton: followInsta,

    // LEGAL
    partnersFooter: 'Partenaires',
    copyrightNewsletterFooter: 'Tous droits réservés © 2022 compagnie Clowns dans la Sciure',
    termsOfUseFooter: 'Mentions Légales',
}

export default frFooter