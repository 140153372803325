const enMaintenance = {
    maintenanceMetaTitle: 'Clowns dans la sciure - Maintenance',
    maintenanceMetaDescription: 'The Clowns dans la sciure website is currently under maintenance but will be back soon!',

    loginText: 'Log in',
    closeText: 'Close',

    loginInput: 'Username',
    passwordInput: 'Password',

    loginError: 'The username or the password is incorrect',
    loginEmpty: 'Please fill all inputs',

    maintenanceMessage: 'The website is currently under maintenance.',
    maintenanceWaiting: 'We will come back soon for more madness!',
    maintenanceSocialMedia: 'Meanwhile, go check out our social medias!',

    maintenanceDaysLeft: 'Days',
    maintenanceHoursLeft: 'Hours',
    maintenanceMinutesLeft: 'Minutes',
    maintenanceSecondsLeft: 'Seconds',
}

export default enMaintenance