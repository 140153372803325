const esHome = {
    // HOME PAGE
    homeMetaTitle: 'Clowns dans la sciure - Home',
    homeMetaDescription: 'La compañía Clowns dans la sciure trabaja en las artes de calle y nocturnas. Combina técnicas de circo y teatro para ofrecerle espectáculos originales y mágicos en múltiples universos.',

    introductionMessage: <>
        <p>
            La Compañía &quot;CDLS&quot; es una agrupación que trabaja en las artes de la calle y de la noche.
            <br/>
            Combina técnicas de circo y teatro para ofrecerle espectáculos mágicos y originales en múltiples universos.
        </p>

        <p>¡Elegancia y energía loca para el deleite de grandes y chicos!</p>

        <p>¡Nuestros más espectaculares saludos!</p>
    </>,

    // HOME TEXTS
    // Universe Grid
    universeTitle: 'Nuestros Universos',
    universeGridFirstDescription: <>Nuestros Universos</>,
    universeGridSecondDescription: <>¡Es tu día; <br/>¡Lo sublimaremos!</>,
    universeGridThirdDescription: <>El arte de entretener a tu audiencia. <br/>La diversión y el encanto son parte del juego.</>,
    universeGridFourth: 'Taller',
    universeGridFourthDescription: <>El arte de construir y aprender junto a nosotros!</>,
    universeGridFifth: 'Novedades',
    universeGridFifthDescription: <>¡No te quedes en la rutina, <br/>encuentra la originalidad!</>,
    universeGridSixth: 'En camarines',
    universeGridSixthDescription: <>¡Momentos de locura,<br/> el lugar guardado en secreto es develado aquí!</>,
}

export default esHome