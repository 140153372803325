const enHomeCarousel = {
    // January
    carouselJanuary1Title: 'NEW WEBSITE',
    carouselJanuary1Description: <>
        All new, all beautiful! Easy and intuitive <br/>
        <span className='uk-text-bold'>Venez décrouvrir notre compagnie !</span> <br/>
        Come and discover our company!
    </>,

    carouselJanuary2Title: 'HAPPY NEW YEAR',
    carouselJanuary2Description: <>
        The company wishes you a Happy New Year <br/>
        filled with love, joy and of course health <br/>
        And to get you started, here are <span className='uk-text-bold'>all our shows</span> !
    </>,

    carouselJanuary3Title: 'RESPONSIVE AND MUCH MORE..',
    carouselJanuary3Description: <>
        Our new website is adaptable to all media <br/>
        One site, 22 Shows, all together just for you! <br/>
        Et bien sur les réseaux sociaux pour toutes les actualités en temps réel <br/>
        Join us
    </>,

    carouselJanuary4Title: 'LOVE and FIRE',
    carouselJanuary4Description: <>
        Get ready to set your event alight <br/>
        Chic, fire and fireworks are the order of the day <br/>
        This is "<span className='uk-text-bold uk-text-italic'>Cracheurs de feu et jongleur pyrotechnie</span>"
    </>,

    carouselJanuary5Title: 'VALENTINE\'S DAY',
    carouselJanuary5Description: <>
        We present 3 shows <br/>
        under the sign of elegance in the colours of love. <br/>
        Rendezvous for a moment of madness, ultra chic and glamorous!
    </>,

    carouselJanuary6Title: 'TO THE RHYTHM OF YOUR HEART!',
    carouselJanuary6Description: <>
        Steampunk in the spotlight with "Fire's Coal",<br/>
        It's time to burn with envy with Un show Unique<br/>
        that will wake up your audience!
    </>,

    // February
    carouselFebruary1Title: 'CARNIVAL AND COTILLIONS',
    carouselFebruary1Description: <>
        It's time to have fun <br/>
        with colourful characters. <br/>
        Book us for moments of happiness!
    </>,

    carouselFebruary2Title: 'STREET ART',
    carouselFebruary2Description: <>
        Total immersion in the bubbles <br/>
        and in the madness of our actors <br/>
        Here are "<span className='uk-text-bold'>M'zelle Rosalie</span>" and "<span className='uk-text-bold'>Wotan project</span>"
    </>,

    carouselFebruary3Title: 'WORKSHOPS',
    carouselFebruary3Description: <>
        Come and discover our adapted workshops for young and old. <br/>
        Joy and good humour guaranteed for an explosion of creativity,<br/>
        At Easter... or throughout the year
    </>,

    // March
    carouselMarch1Title: 'TURN UP THE HEAT!!',
    carouselMarch1Description: <>
        Delicacy and sensuality are the order of the day,<br/>
        Here's a "showstopper" to spice up your evening. <br/>
        Our multi-faceted artists will make you shiver!
    </>,

    carouselMarch2Title: 'STREET FESTIVAL',
    carouselMarch2Description: <>
        Our shows are here to delight young and old,<br/>
        Plan your summer and make your audience happy. <br/>
        Don't wait any longer to experience fantastic moments! <br/>
    </>,

    carouselMarch3Title: 'Gala, Business meeting, Wedding,...',
    carouselMarch3Description: <>
        This is the big day, look no further! <br/>
        We are here to brighten your nights <br/>
        Ultra chic, lasers and leds are part of the game <br/>
    </>,

    // April
    carouselApril1Title: 'CINEMA... ON TOUR!',
    carouselApril1Description: <>
        Is your theme the "Big Screen" or a "Jungle" party?<br/>
        Acrobats and comedians will be able to embody our "Cosplay" with brio. <br/>
        An unforgettable memory? Take your photo or video with you! <br/>
    </>,

    carouselApril2Title: 'WORKSHOPS',
    carouselApril2Description: <>
        Come and discover our adapted workshops for young and old. <br/>
        Joy and good humour guaranteed for an explosion of creativity,<br/>
        The choice is yours! "<span className='uk-text-bold'>stilts</span>", "<span className='uk-text-bold'>bubbles</span>", "<span
        className='uk-text-bold'>juggling</span>", ...
    </>,

    carouselApril3Title: 'FILM FESTIVAL',
    carouselApril3Description: <>
        You're not ready! Amaze your guests <br/>
        With one of the most popular monsters in science fiction. <br/>
        A <span className='uk-text-bold'>mythical "Cosplay"</span> for an exceptional event!
    </>,

    // MAY
    carouselMay1Title: 'FESTIVALS',
    carouselMay1Description: <>
        Summer is coming! Plan your entertainment with our shows.<br/>
        They make you laugh, jump around! <br/>
        <span className='uk-text-bold'>Book us for crazy moments</span><br/>
    </>,

    carouselMay2Title: 'STREET FESTIVAL',
    carouselMay2Description: <>
        We imagine fire, gears, bubbles<br/>
        breathtaking costumes with humour<br/>
        Here is <span className='uk-text-bold'>a chic and unusual</span> universe for your events!<br/>
    </>,

    carouselMay3Title: "FATHER'S AND MOTHER'S DAY",
    carouselMay3Description: <>
        We plan everything! And yes, it's happening fast!<br/>
        So don't wait any longer, here is "<span className='uk-text-bold'>The Rainbow Man and his Angels</span>" <br/>
        And "<span className='uk-text-bold'>Lady and Gentleled's</span>" for a fragrance of delicacy and elegance<br/>
    </>,

    // JUNE
    carouselJune1Title: 'WEDDING...',
    carouselJune1Description: <>
        This is your big day, look no further!<br/>
        We are here to <span className='uk-text-bold'>brighten up your wonderful day</span> <br/>
        Elegance in reception and lasers and leds for the night<br/>
    </>,

    carouselJune2Title: 'NATIONAL DAY',
    carouselJune2Description: <>
        Get ready to set your event alight<br/>
        Chic, <span className='uk-text-bold'>fire and fireworks are in the mix</span>. It's the big day!<br/>
        This is "Fire-eaters and pyrotechnic jugglers"<br/>
    </>,

    carouselJune3Title: 'MUSIC FESTIVAL (STEAMPUNK)',
    carouselJune3Description: <>
        We imagine fire, gears,<br/>
        breathtaking costumes set to crazy music<br/>
        Here is a <span className='uk-text-bold'>chic and unusual universe</span> for your events<br/>
    </>,

    // JULY
    carouselJuly1Title: "COME ON! WE'RE GOING TO DANCE",
    carouselJuly1Description: <>
        And here we go for a crazy summer<br/>
        Our performers are there to set the dance floor alight<br/>
        <span className="uk-text-bold">Fire, lights, robots, jugglers, etc...</span> We are here!
    </>,

    carouselJuly2Title: 'FESTIVALS',
    carouselJuly2Description: <>
        It's nice and warm! Our shows are out<br/>
        <span className='uk-text-bold'>bubbles, stilt walkers, jugglers with humour and poetry.</span> <br/>
        If you forgot to book us, there's still next year!<br/>
    </>,

    carouselJuly3Title: "WITCHES' FESTIVAL",
    carouselJuly3Description: <>
        And yes, it's coming soon! It's time to think about it<br/>
        <span className='uk-text-bold'>Several worlds for a maximum of sensations</span> <br/>
        Don't wait! Book us now!<br/>
    </>,

    // August
    carouselAugust1Title: "WORKSHOPS",
    carouselAugust1Description: <>
        Come and discover our adapted workshops for young and old.<br/>
        Joy and good humour guaranteed for an explosion of creativity, <br/>
        Vous avez le choix ! "<span className='uk-text-bold'>stilts</span>", "<span className='uk-text-bold'>bubbles</span>", "<span
        className='uk-text-bold'>juggling</span>"...<br/>
    </>,

    carouselAugust2Title: "TURN UP THE HEAT!",
    carouselAugust2Description: <>
        <span className='uk-text-bold'>Summer is well and truly here</span>, find us at the erotica shows,<br/>
        tattooing or for unusual stripteases! <br/>
        <span className='uk-text-bold'>Choose your world</span> and spice up your event.<br/>
    </>,

    carouselAugust3Title: "IT'S SUMMER and INAUGURATION",
    carouselAugust3Description: <>
        It is hot! Show your guests the best<br/>
        <span className='uk-text-bold'>bubbles, stilt walkers, jugglers with humour and poetry</span>.<br/>
        An inauguration? a product launch? It is HERE<br/>
    </>,

    // SEPTEMBER
    carouselSeptember1Title: "IT'S BACK TO SCHOOL TIME",
    carouselSeptember1Description: <>
        Plan your <span className='uk-text-bold'>cinema entry!</span><br/>
        Several "Cosplay" at your disposal: "Black Panther",<br/>
        "Tarzan", "Alien" <span className="uk-text-bold">or for a party</span>, the choice is yours!<br/>
    </>,

    carouselSeptember2Title: "ITINERANT SHOW",
    carouselSeptember2Description: <>
        "Miss Rosalie" is back, have fun!<br/>
        With of course all the others that await you here.<br/>
        <span className='uk-text-bold'>Animate your future events... now!</span><br/>
    </>,

    carouselSeptember3Title: "THRILL RIDE!",
    carouselSeptember3Description: <>
        Plan your <span className='uk-text-bold'>medieval celebrations for the coming year</span>,<br/>
        <span className='uk-text-bold'>witch's day</span> and of course <span>Halloween</span> !<br/>
        "The Evil Forces" will make you tremble with pleasure<br/>
    </>,

    // OCTOBRE
    carouselOctober1Title: "HALLOWEEN! IT'S NOW",
    carouselOctober1Description: <>
        "The Evil Forces", a stroll of little devils, a cerberus and a leaping skeleton!<br/>
        And don't forget "Alien", a crazy cosplay that will surprise your guests<br/>
        <span className='uk-text-bold'>Here are some impressive costumes for this month of October</span><br/>
    </>,

    carouselOctober2Title: "FIRE AND BUBBLES WALK",
    carouselOctober2Description: <>
        Steampunk in the spotlight with "Fire's Coal",<br/>
        a unique show <span className='uk-text-bold'>with char and performances</span> !<br/>
        Animate your future events under the sign Industrial-Chic...<br/>
    </>,

    carouselOctober3Title: "THRILLING SHOWS!",
    carouselOctober3Description: <>
        Plan your Halloween party! Reservations are now open!<br/>
        "Día de Muertos" comes straight from Mexico!<br/>
        Without forgetting our "mummies" who will know how to make you a crazy evening<br/>
    </>,

    // NOVEMBER
    carouselNovember1Title: "HERE COME THE END OF YEAR CELEBRATIONS!",
    carouselNovember1Description: <>
        We present you with 3 walking shows for Christmas<br/>
        always <span className='uk-text-bold'>under the sign of elegance and madness</span>,<br/>
        here is "The Rainbow Man and his Angels" and the "White Dream" and of course the "Wadioui".<br/>
    </>,

    carouselNovember2Title: "NEW YEAR APPROACHING",
    carouselNovember2Description: <>
        <span className='uk-text-bold'>It's time to plan your New Year's Eve too</span>,<br/>
        LEDs, fire, chic to make the last day of the year a success!<br/>
        "Light me Up", "Fire's Coal" and "Fire-eater and Pyrotechnic Juggler"<br/>
    </>,

    carouselNovember3Title: "CHRISTMAS IS COMING !",
    carouselNovember3Description: <>
        It's the home stretch for your festive season.<br/>
        Demand the best with <span className='uk-text-bold'>technique and humour for a Christmas-Chic</span><br/>
        The golden and silver sprites the "<span className='uk-text-bold'>Wadioui</span>" are in the game<br/>
    </>,

    // DECEMBER
    carouselDecember1Title: "HAPPY NEW YEAR",
    carouselDecember1Description: <>
        We wish you a merry Christmas <br/>
        <span className='uk-text-bold'>Follow us on our social networks</span> and especially... <br/>
        Don't forget to book us <span className='uk-text-bold'>for next year</span>
    </>,

    carouselDecember2Title: "NEW YEAR",
    carouselDecember2Description: <>
        Put your guests in the light!! <br/>
        LEDs, fire, chic so that <span className='uk-text-bold'>the last day of the year is a success</span>! <br/>
        "Light me Up" and "Fire-Eater and Pyrotechnic Juggler"
    </>,

    carouselDecember3Title: "FINAL STRETCH!",
    carouselDecember3Description: <>
        An Ultra Chic welcome for your New Year's Eve guests.. <br/>
        <span className='uk-text-bold'>Elegance, technique and madness</span>, <br/>
        This is "Lady and Gentleled's" and "White Dream".
    </>,

    carouselDecember4Title: "WITHOUT FORGETTING!",
    carouselDecember4Description: <>
        The magic of fire with "Fire's Coal", <br/>
        A unique show with a float and fire/pyrotechnics performance! <br/>
        Enter the <span className='uk-text-bold'>Chic-Steampunk universe for your New Year's Eve</span>
    </>,
}

export default enHomeCarousel