import followInsta from 'img/general/svg/social/siganos_insta.svg'

const esFooter = {
    // FOOTER MENU
    newsletterTitle: 'Suscríbase al boletín',
    newsletterText: 'Manténgase informado sobre las actualidades de la compañía',
    newsletterButton: "SUSCRIBIRSE",
    newsletterSuccess: '¡Enhorabuena! Usted está registrado',

    waitingArticles: "¡Pronto estará disponible!",

    // SOCIAL
    followInstagram: <>
        ¡Aquí tienes algunas historias de Instagram! en nuestra cuenta oficial @clownsinlasciurespectacles <br/>
        ¡Para ver más, únase a nosotros!
    </>,
    followInstagramFooter: 'Síganos en Instagram',
    followInstagramButton: followInsta,

    partnersFooter: 'Partenaires',
    copyrightNewsletterFooter: 'Todos los derechos reservados © 2022 Compagnie Clowns dans la Sciure - Socios - Aviso legal',
    termsOfUseFooter: 'Aviso legal y política de privacidad',
}

export default esFooter