const frContact = {
    contactPageDesc: <>
        Vous avez Besoin d&apos;un concept, duo, trio ou plus encore...
        <br/>
        C&apos;est avec joie que nous vous écouterons, nos personnages peuvent se croiser et jouer ensemble,<br/> tout est prévu pour que votre évènement soit une
        réussite.
    </>,
    pressTitle: 'Avis de la presse',

    nameContact: 'NOM Prénom',
    phoneContact: 'Téléphone',
    emailContact: 'E-mail',
    subjectContact: 'Objet',
    subjectSelectionContact: 'Veuillez choisir le sujet',
    informationSubjectContact: 'Demande de renseignements',
    flyerSubjectContact: 'Demande de plaquettes / brochures',
    quotationSubjectContact: 'Demande de devis',
    bookingSubjectContact: 'Demande de réservation',
    messageSubjectContact: 'Message',

    contactRequiredFields: <><span className='uk-text-bold text-gold'>*</span> indique un champ obligatoire</>,

    contactInputEmpty: 'Veuillez remplir le champ ',
    contactScriptTagDetected: "J'ai vu la tentative d'utilisation de script JavaScript. Ce n'est pas bien, veuillez ne pas recommencer.",
    contactRequirementsFailed: "Veuillez saisir tous les champs correctement.",
    contactSuccess: 'Le message a bien été envoyé',
    contactSendFailed: "Le message n'a pas pu être envoyé.",

    referenceTitle: 'Les références',
    referenceText: <>
        <p className='uk-text-center uk-text-bold'>
            Depuis sa création, en 2011, la compagnie a eu l&apos;occasion de se produire un peu partout.
            <br/>
            Voici une liste non exhaustive des lieux ou évènements parmi lesquels nous sommes <br/>passés pour jouer nos spectacles.
        </p>

        <div className='uk-width-1-2@m uk-margin-auto' style={{paddingBottom: '1em'}}>
            ville de Fontaines-sur-Saône (Fantasti&apos;Art), ville de Mourenx, ville d&apos;Orthez, ville de Bordeaux, ville de Saillagouse, ville de Bourg Madame, ville
            de Narbonne, ville de Pau, Bruxelles (Belgique), ville de Monein, ville de Gdansk et Varsovie (Pologne), ville de Toulouse, ville de Narbonne, ville
            de Nice, ville de Gueret, ville de Rouen, ville de s, ville de Bascons, ville de Cannes, ville de Perpignan, ville de Saint-Cyprien, ville
            des Angles, ville de Formigueres, ville de Biguglia (Corse), ville de Quillan, L&apos;Oréal, ville d&apos;Estavar, ville de Luc-sur-Orbier, ville de Cadours,
            ville de P2000, salle de concert &quot;Bikini&quot; Toulouse, ville de Creil, ville de Lautrec, ville d&apos;Albi, ville de Moissac, ville de cadours, ville de
            Fleury d&apos;Aude, ville de Tarbes, ville de Montfaucon, ville de Salies-de-Béarn, ville de Lisle-sur-Tarn, ville de Paris, site &quot;Airbus&quot; à Toulouse, ville de
            Muret, ville d&apos;Ausonne...
        </div>
    </>,
}

export default frContact