const frHome = {
    // HOME PAGE
    homeMetaTitle: 'Clowns dans la sciure - Accueil',
    homeMetaDescription: 'La compagnie Clowns dans la sciure travaille dans les arts de rue et de la nuit. Elle allie techniques de cirque et théâtre afin de vous proposer des spectacles féériques et originaux dans de multiples univers.',

    introductionMessage: <>
        <p>
            La &quot;CDLS Compagny&quot; est une compagnie travaillant dans les arts de rue et de la nuit.
            <br/>
            Elle allie techniques de cirque et théâtre afin de vous proposer des spectacles féériques et originaux dans de multiples univers.
        </p>

        <p>De l&apos;élégance et des énergies de folie <br/>pour le plus grand bonheur des grands et des petits!</p>

        <p>Nos salutations les plus spectaculaires !</p>
    </>,

    // HOME TEXTS
    // Universe Grid
    universeTitle: 'Nos Univers',
    universeGridFirstDescription: <>Choisissez la « Couleur » <br/>de votre soirée</>,
    universeGridSecondDescription: <>C'est VOTRE journée; <br/>Nous allons la sublimer !</>,
    universeGridThirdDescription: <>L'art de distraire votre public. <br/>Amusement et féérie sont de la partie</>,
    universeGridFourth: 'Atelier',
    universeGridFourthDescription: <>L'art de construire et d'apprendre <br/>Rejoignez nous !</>,
    universeGridFifth: 'Nouveautés',
    universeGridFifthDescription: <>Ne restez pas dans la routine, <br/>trouvez l'originalité !</>,
    universeGridSixth: 'En loge',
    universeGridSixthDescription: <>Des instants de folies,<br/> le lieu gardé secret dévoilé ici !</>,
}

export default frHome