const esTroupe = {
    troupeDescription: <>
        <p>¡Bienvenidos a nuestros universos!</p>

        <p>
            <span className='uk-text-bold'>Desde hace más de 11 años</span>, la compañía "Clowns dans la sciure" es un grupo de artistas que evoluciona
            continuamente en varios campos, que intenta hacer todo lo posible para estar siempre en <span
            className='uk-text-bold'>constante evolución</span> en sus técnicas y su puesta en escena.
        </p>

        <p>
            En particular nos destacamos en el arte de la <span className='uk-text-bold'>creación de personajes</span> adaptados a diversos medios de día y de
            noche y en la elección de sus artistas para encarnarlos, así hacemos lo mejor que podemos a fin de presentarles lo mejor en materia de espectáculos.
        </p>

        <p>
            Surgido del <span className='uk-text-bold'>teatro y el circo</span> con disciplinas como zancos "fijos y rebotantes", malabares "luz y fuego",
            el payaso, el mimo o incluso las acrobacias de todo tipo, nosotros <span className='uk-text-bold'>total seguridad</span>.
        </p>

        <p>
            Mezclando danza y música, aquí estamos en el corazón de un <span className='uk-text-bold'>cóctel explosivo para satisfacer a su público</span>. El
            todo ! por supuesto, adaptado en <span className='uk-text-bold'>total seguridad</span>.
        </p>

        <p>
            Nosotros evolucionamos en diferentes oficios artísticos, costura, bricolaje y muchos otros con el fin de construir personajes y <span
            className='uk-text-bold'>vestimentas atípicas</span> dentro de nuestra compañía. Para que podamos ir aún más lejos, hasta construir <span
            className='uk-text-bold'>carros luminosos y sonoros adaptados a vuestros lugares</span>...
            <br/>
            ¡¡¡Y si!!! La compañía es un equipo de artistas que trabajan juntos para traerles un hermoso proyecto a término, siendo nuestra fuerza y nuestra
            felicidad.
        </p>

        <p>
            Para satisfacer sus deseos, es necesaria una buena organización y por eso todo está preparado de antemano.
            <br/>
            Para que su evento sea perfecto el día D.
        </p>

        <p>
            Nuestro objetivo :
            <br/>
            <span className='uk-text-bold'>Ofrecer un espectáculo de calidad con un bello toque de poesía, técnica y magia</span> <br/>
            ... mejor que un sueño ... una realidad.
        </p>

        <p>
            ¡Así que no esperéis más!
            <br/>
            ¡Nuestros saludos más espectaculares!
        </p>
    </>,
    troupeMetaDescription: '¡Venga a descubrir todos los secretos de nuestra empresa!',

    troupeWorkshopSection: "El taller",
    troupeWorkshopDescription: <>
        <p>
            Este es un lugar de encuentro y de intercambio entre artistas y realizadores.
            <br/>
            Aquí es donde <span className='uk-text-bold'>desarrollamos nuevos proyectos</span> (vestuario, complementos, carros ...), <br/>
            creamos, reparamos ... y muchas ideas se fusionan para encontrar el "artificio" <span className='uk-text-bold'>que le hará soñar</span>.
        </p>

        <p>
            Es un punto importante de la compañía decirle que <span className='uk-text-bold'>la mayoría de nuestras creaciones son hechas al 90% en nuestros talleres y en familia</span>, <br/>
            Trabajamos madera, metal, látex ... moldeamos, perforamos, soldamos y ...<br/>
            <span className='uk-text-bold'>Todo en seguridad</span>.
        </p>

        <p>
            Por lo demás, recurrimos a artistas talentosos, a empresas, a amigos ! Todos competentes en su campo con el fin de compartir y <span
            className='uk-text-bold'>presentarle lo mejor en materia de disfraces</span>.
        </p>

        <p>
            Y sí, <span className='uk-text-bold'>somos polivalentes</span>, llenos de ideas con una buena dosis de diversión y sudor.
            <br/>
            <span className='uk-text-bold'>¡Ésta es nuestra FUERZA!</span> ¡Bienvenido a nuestra casa !
        </p>
    </>,
    troupeWorkshopSlogan: "¿Te gusta trabajar manualmente, invertir en hermosos proyectos? ¡Únete a nosotros!",

    troupeBTSSection: 'La sede',
    troupeBTSDescription: <>
        <p>
            Bienvenidos al <span className='uk-text-bold'>lugar de los Artistas</span>, <br/>
            Aquí ellos preparan disfraces, maquillajes, ensayos de textos, movimientos y vocalizaciones sin olvidar la preparación deportiva y mucho más ...
        </p>

        <p>
            <span className='uk-text-bold'>Los organizadores tienen mucho que ver con nuestra comodidad</span> (boxes calefactados, catering, etc.), <br/>
            para poder ofrecernos lugares de preparación adecuados ... Por todo <span
            className='uk-text-bold'>esto les agradecemos y sabemos devolverles</span> esto les agradecemos y sabemos devolverles
        </p>

        <p>
            Descanso (micro siesta), catering, briefing, etc. son <span className='uk-text-bold'>las claves para presentarte un espectáculo de calidad</span>.
            Es un lugar de descanso pero también de delirio y de intercambio, <br/>
            Y por todos estos buenos momentos, <span className='uk-text-bold'>nosotros queremos compartirlo.</span>.
        </p>

        <p>¡¡Sean bienvenidos !! ¡Y <span className='uk-text-bold'>nos vemos EN EL ESPECTÁCULO!</span></p>
    </>,

    troupeDataCountries: 'PAISES VISITADOS',
    troupeDataDepartments: 'DEPARTAMENTOS RECORRIDOS',
    troupeDataCities: 'CIUDADES CUBIERTAS',
    troupeDataKMs: 'KILOMETROS RECORRIDOS',
    troupeDataShows: 'ESPECTACULOS DIFERENTES',

    sponsorTitle: 'Ellos confían en nosotros',
    sponsorText: <>
        La Compañía Les Clowns dans la Sciure (CDLS) acompaña y ofrece sus espectáculos a comunidades, comités de empresa, agencias de eventos, etc ... <br/>
        ¡Para las realizaciones artísticas a fin de responder a sus proyectos más alocados!
        <br/>
        §	Gracias a todos nuestros socios antiguos y nuevos por su confianza renovada constantemente.
    </>,

    contactUs: "CONTÁCTENOS",
}

export default esTroupe