import en from "./en/en"
import fr from "./fr/fr"
import es from "./es/es"

import france_flag from "img/general/svg/countries/France.svg"
import uk_flag from "img/general/svg/countries/United_Kingdom.svg"
import es_flag from "img/general/svg/countries/Spain.svg"

const languageOptions = {
    en: {key: 'en', name: 'English', dictionary: en, flag: uk_flag},
    fr: {key: 'fr', name: 'Français', dictionary: fr, flag: france_flag},
    es: {key: 'es', name: 'Español', dictionary: es, flag: es_flag}
}

export default languageOptions