const frMaintenance = {
    maintenanceMetaTitle: 'Clowns dans la sciure - Maintenance',
    maintenanceMetaDescription: 'Le site Clowns dans la sciure est actuellement en maintenance mais il reviendra très vite !',

    loginText: 'Se connecter',
    closeText: 'Fermer',

    loginInput: 'Identifiant',
    passwordInput: 'Mot de passe',

    loginError: <>L&#39;identifiant ou le mot de passe est incorrect</>,
    loginEmpty: 'Veuillez remplir tous les champs',

    maintenanceMessage: 'Le site est actuellement en maintenance.',
    maintenanceWaiting: 'Nous reviendrons pour vous proposer encore plus de folies !',
    maintenanceSocialMedia: 'En attendant, retrouvez-nous sur nos réseaux sociaux !',

    maintenanceDaysLeft: 'Jours',
    maintenanceHoursLeft: 'Heures',
    maintenanceMinutesLeft: 'Minutes',
    maintenanceSecondsLeft: 'Secondes',
}

export default frMaintenance