const frTroupe = {
    troupeDescription: <>
        <p>Bienvenue dans nos Univers !</p>

        <p>
            <span className='uk-text-bold'>Depuis plus de 11 ans</span>, la compagnie « Clowns dans la sciure » est une troupe d&apos;artistes qui évolue continuellement dans plusieurs domaines, <br/>
            qui essaie de mettre tout en œuvre pour être toujours en <span className='uk-text-bold'>constante évolution</span> dans ses techniques et ses mises en scène.
        </p>

        <p>
            Notamment nous excellons dans l&apos;art de la <span className='uk-text-bold'>création de personnages</span> adaptés dans divers milieux de jour comme de nuit et dans le choix de ses artistes
            pour les incarner, Ainsi nous faisons du mieux que nous pouvons <br/>
            afin de vous présenter le meilleur en matière de spectacles.
        </p>

        <p>
            Issu du <span className='uk-text-bold'>théâtre et du cirque</span> avec des disciplines tel que les échasses &quot;fixes et bondissantes&quot;, la jonglerie &quot;lumineuse et de feu&quot;, <br/>
            le clown, le mime ou encore les acrobaties en tout genre, nous <span className='uk-text-bold'>relevons tous les défis</span>.
        </p>

        <p>
            En y mélangeant la danse et la musique, nous voilà au cœur d&apos;un <span className='uk-text-bold'>cocktail explosif pour satisfaire votre public</span>. Le tout ! bien sûr, adapté en <span
            className='uk-text-bold'>totale sécurité</span>.
        </p>

        <p>
            Nous évoluons dans différents corps de métiers artistiques, la couture, le bricolage et bien d&apos;autres <br/>
            afin de construire des personnages et des <span className='uk-text-bold'>costumes atypiques</span> au sein de notre compagnie. Ainsi nous pouvons aller encore plus loin, jusqu&apos;à
            construire des <span className='uk-text-bold'>chars lumineux et sonores adaptables à vos lieux</span>.
            <br/>
            Et oui !!! La compagnie est une équipe d&apos;artistes travaillant tous ensemble pour vous amener un beau projet à terme, faisant notre force et notre
            bonheur.
        </p>

        <p>
            Pour répondre à vos souhaits, une bonne organisation est nécessaire et c&apos;est pour cela que tout est préparé en amont.
            <br/>
            Ainsi votre évènement sera parfait le jour J.
        </p>

        <p>
            Notre but :
            <br/>
            <span className='uk-text-bold'>Offrir un spectacle de qualité avec une belle touche de poésie, de technique et de féérie</span> <br/>
            ...mieux qu&apos;un rêve...une réalité.
        </p>

        <p>
            Alors n&apos;attendez plus !
            <br/>
            Nos salutations les plus spectaculaires !
        </p>
    </>,
    troupeMetaDescription: 'Venez découvrir tous les secrets derrière notre compagnie !',

    troupeWorkshopSection: "L'atelier",
    troupeWorkshopDescription: <>
        <p>
            Voici un lieu de rencontre et d&apos;échange entre artistes et bricoleurs.
            <br/>
            C&apos;est ici que nous y <span className='uk-text-bold'>développons des nouveaux projets</span> (costumes, accessoires, chars...), <br/>
            nous créons, nous réparons... et les idées fusent à plusieurs pour trouver &quot;l&apos;artifice&quot; <span className='uk-text-bold'>qui vous fera rêver</span>.
        </p>

        <p>
            C&apos;est un gros point de la compagnie de vous dire que <span className='uk-text-bold'>la majorité de nos créations sont créée à 90% dans nos ateliers et en famille</span>, <br/>
            Nous y travaillons le bois, le métal, le latex... On meule, on perce, on soude, et... <br/>
            <span className='uk-text-bold'>Le tout en sécurité</span>.
        </p>

        <p>
            Pour le reste, nous faisons appel à des artistes talentueux, des sociétés, des amis ! Tous compétents dans leur domaine afin de partager <br/>
            et de <span className='uk-text-bold'>vous présenter le meilleur en matière de costumes</span>.
        </p>

        <p>
            Et Oui, <span className='uk-text-bold'>Nous sommes polyvalent</span>, bourré d&apos;idées avec une bonne dose de rigolade et de sueur.
            <br/>
            <span className='uk-text-bold'>C&apos;est notre FORCE</span> ! Bienvenue chez nous !
        </p>
    </>,
    troupeWorkshopSlogan: "Vous aimez bricoler, vous investir dans de beaux projets? Rejoignez-nous !",

    troupeBTSSection: 'La loge',
    troupeBTSDescription: <>
        <p>
            Bienvenue dans l&apos;<span className='uk-text-bold'>endroit des Artistes</span>, <br/>
            Ici, ils préparent costumes, maquillage, répétitions des textes, déplacements et vocalises sans oublier la préparation sportive et bien plus...
        </p>

        <p>
            <span className='uk-text-bold'>Les organisateurs y sont pour beaucoup sur notre confort</span> (loges chauffées, catering, etc...), <br/>
            afin de nous proposer des lieux de préparations adéquates... Pour tout cela <span className='uk-text-bold'>nous les remercions et savons bien leur rendre <br/>
            en donnant le maximum</span> de nous tous pour VOUS...... le public !!
        </p>

        <p>
            Repos (micro sieste), catering, briefing, etc.. sont <span className='uk-text-bold'>les clefs pour vous présenter un spectacle de qualité</span>.
            C&apos;est un endroit de repos mais aussi de délires et de partage, <br/>
            Et pour tous ces bon moments, <span className='uk-text-bold'>nous voulons vous le faire partager</span>.
        </p>

        <p>Soyez les bienvenus !! Et <span className='uk-text-bold'>rendez-vous POUR LE SHOW</span> !</p>
    </>,

    
    troupeDataCountries: 'Pays visités',
    troupeDataDepartments: 'Départements traversés',
    troupeDataCities: 'Villes couvertes',
    troupeDataKMs: 'Kilomètres parcourus',
    troupeDataShows: 'Spectacles différents',

    sponsorTitle: 'Ils nous font confiance',
    sponsorText: <>
        La Compagnie Les Clowns dans la Sciure (CDLS) accompagne et propose ses spectacles aux collectivités, aux comités d’entreprises, aux agences
        d’évènementiel, etc... <br/>
        Pour des réalisations artistiques afin de répondre à vos projets les plus fous !
        <br/>
        Merci à tous nos anciens et nouveaux partenaires pour leur confiance sans cesse renouvelée.
    </>,

    contactUs: "Contactez-nous",
}

export default frTroupe