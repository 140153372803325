import followInsta from 'img/general/svg/social/follow_insta.svg'

const enFooter = {
    // FOOTER MENU
    newsletterTitle: 'Subscribe to our newsletter',
    newsletterText: 'Keep your finger on the pulse of the troupe',
    newsletterButton: 'Subscribe',
    newsletterSuccess: 'Congratulations! You are registered',

    // ARTICLES
    waitingArticles: "Coming soon!",

    // SOCIAL
    followInstagram: <>
        Here are some Instagram stories! on our official account @clownsinlasciurespectacles <br/>
        To see more, join us!
    </>,
    followInstagramFooter: 'Follow us on Instagram',
    followInstagramButton: followInsta,

    // LEGAL
    partnersFooter: 'Partners',
    copyrightNewsletterFooter: 'Copyright © 2022 Clowns dans la Sciure company. All rights reserved.',
    termsOfUseFooter: 'Terms of Use',
}

export default enFooter