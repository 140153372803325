const enHeader = {
    // HEAD BAR
    headbarDesc: 'International circus/theater company shows/walkabouts',

    // HEADER MENU
    navbarHome: 'Home',
    navbarShows: 'Our show',
    navbarWorkshops: 'Workshops',
    navbarTroupe: 'The troupe',
    navbarContact: 'Contact',

    // MOBILE MENU
    navAllShows: 'All show',
    navAllWorkshops: 'All workshops',
    navAllEvents: 'All events',
    navAllThemes: 'All themes',
    navAllActivities: 'All activities',

    navDesktopAllShows: 'All show',

    // OUR SHOWS MENU
    navEventsColumn: 'Events',
    navEventValentine: "Valentine's Day",
    navEventHalloween: 'Halloween',
    navEventWedding: 'Wedding',
    navEventCarnival: 'Carnival',
    navEventProduct: 'Inauguration / Product launch',
    navEventChristmasMarket: 'Christmas market',
    navEventClubbing: 'Discotheque',
    navEventStreetFestival: 'Street festival',
    navEventMusicFestival: 'Music festival',
    navEventMovieFestival: 'Movie festival',
    navEventWitchDay: "Witch's Day",
    navEventTattooFair: 'Tattoo fair',
    navEventBirthday: 'Birthday',
    navEventEroticismFair: 'Eroticism fair',
    navEventPrivateShow: 'Private show, gala, inauguration...',
    navEventNewYear: "New Year's Eve",
    navEventSkiResort: 'Ski resort',
    navEventParentsDay : "Father's / Mother's Day",
    navEventNationalDay: 'National Day',
    navEventEaster: 'Easter',

    navThemeColumn: 'Themes',
    navThemeChristmas: 'Christmas',
    navThemeMedieval: 'Medieval',
    navThemeSteampunk: 'Steampunk',
    navThemeCinema: 'Cinema',
    navThemeDiaDeLosMuertos: 'Dia de los Muertos',
    navThemeWhite: 'White',
    navThemeLight: 'Bright',
    navThemeFantasy: 'Fantasy',
    navThemeMusic: 'Music',
    navThemeFuturistic: 'Futuristic',
    navThemeChic: 'Chic / elegant',
    navThemeLatexVinyl: 'Latex and Vinyl',
    navThemeAngelImp: 'Angel et imp',
    navThemeLove: 'Love',
    navThemeHorror: 'Thrill / Horror',
    navThemeCosplay: 'Cosplay',

    navActivityColumn: 'Activities',
    navActivityStilt: 'Stilt',
    navActivityJuggling: 'Juggling',
    navActivityMusic: 'Music',
    navActivityMime: 'Mime',
    navActivityClown: 'Clown',
    navActivityMakeup: 'Makeup',
    navActivitySoapBubbles: 'Bubble performer',
    navActivityPerformer: 'Performer',
    navActivityBalloonSculpture: 'Balloon sculpture',
    navActivityMagic: 'Magic',
    navActivityFireBreather: 'Fire eater',
    navActivityCharAmbulatory: 'Char Ambulatory',
    navActivityWorkshop: 'Workshops',
    navActivityTheater: 'Theater',
    navActivityStrip: 'Strip / stripping',
    navActivityPyrotechnics: 'Fire / pyrotechnics',
    navActivityDance: 'Dance',
    navActivityShoppingGallery: 'Shopping Gallery',
}

export default enHeader