import space from "img/space.jpg";
import Link from "next/link";

const enArticles = {
    // Article 4
    title240521: 'New website! Boooooom',
    publishDate240521: '31/12/2021',
    shortDescription240521: <>
        Il est la, Mesdames et Messieurs, rien que pour vous, nous...
    </>,
    content240521: <>
        <p className='uk-text-lead'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam dolores, eos fuga itaque magnam natus obcaecati rem sequi.
            Consequuntur exercitationem, libero odit officiis pariatur possimus quibusdam recusandae sed voluptas voluptates!Aperiam aspernatur assumenda aut
            beatae blanditiis, cum debitis dolores eaque fugiat harum ipsa molestiae molestias nam nostrum nulla obcaecati, odit omnis optio pariatur quae rem
            rerum sed sunt totam unde?</p>

        <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa cumque eius eligendi facilis hic impedit quam quasi tenetur? Consectetur
            debitis, dolor eius enim facere nesciunt non quasi voluptates. Est, omnis?
            <br/>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aperiam delectus excepturi, neque optio quam sed ullam vel voluptate. At consectetur
            consequuntur exercitationem nihil obcaecati officia perferendis sint unde voluptatibus! Ab autem beatae dolores eius fugit, illo incidunt magnam nam
            nesciunt nisi pariatur quod, rem tenetur! Atque cum dolores non perspiciatis praesentium rem vel voluptas. At ipsam itaque temporibus voluptate?
            Amet deleniti eos et eum itaque nisi nulla quaerat qui sunt voluptatum. Animi architecto commodi consequatur deleniti dolor dolorem ea eos, est
            itaque obcaecati odit quo, quod rem, repudiandae tempore. Ab aperiam deserunt iusto nemo neque omnis similique ut. A aut commodi consectetur
            dignissimos dolore fuga hic illo minus neque numquam, odio omnis perferendis possimus quod repudiandae sit suscipit voluptates. A atque culpa ea
            eligendi error facilis fugit hic impedit incidunt ipsa iure magni maxime molestiae nam, nulla odit officiis omnis perferendis quam qui quia
            reprehenderit sequi sit temporibus voluptate!
        </p>
    </>,

    // Article 3
    title250521: 'Merry Christmas! Great new features for this year',
    publishDate250521: '01/12/2021',
    shortDescription250521: <>
        It is here, ladies and gentlemen, just for you, we...
    </>,
    content250521: <>
        <p className='uk-text-lead'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam dolores, eos fuga itaque magnam natus obcaecati rem sequi.
            Consequuntur exercitationem, libero odit officiis pariatur possimus quibusdam recusandae sed voluptas voluptates!Aperiam aspernatur assumenda aut
            beatae blanditiis, cum debitis dolores eaque fugiat harum ipsa molestiae molestias nam nostrum nulla obcaecati, odit omnis optio pariatur quae rem
            rerum sed sunt totam unde?</p>

        <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa cumque eius eligendi facilis hic impedit quam quasi tenetur? Consectetur
            debitis, dolor eius enim facere nesciunt non quasi voluptates. Est, omnis?
            <br/>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aperiam delectus excepturi, neque optio quam sed ullam vel voluptate. At consectetur
            consequuntur exercitationem nihil obcaecati officia perferendis sint unde voluptatibus! Ab autem beatae dolores eius fugit, illo incidunt magnam nam
            nesciunt nisi pariatur quod, rem tenetur! Atque cum dolores non perspiciatis praesentium rem vel voluptas. At ipsam itaque temporibus voluptate?
            Amet deleniti eos et eum itaque nisi nulla quaerat qui sunt voluptatum. Animi architecto commodi consequatur deleniti dolor dolorem ea eos, est
            itaque obcaecati odit quo, quod rem, repudiandae tempore. Ab aperiam deserunt iusto nemo neque omnis similique ut. A aut commodi consectetur
            dignissimos dolore fuga hic illo minus neque numquam, odio omnis perferendis possimus quod repudiandae sit suscipit voluptates. A atque culpa ea
            eligendi error facilis fugit hic impedit incidunt ipsa iure magni maxime molestiae nam, nulla odit officiis omnis perferendis quam qui quia
            reprehenderit sequi sit temporibus voluptate!
        </p>
    </>,

    // Article 2
    title010621: "An extreme photo shoot",
    publishDate010621: '04/01/2021',
    shortDescription010621: <>
        A crazy day ahead for photos of......
    </>,
    content010621: <>
        <p className='uk-text-lead uk-dropcap'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam dolores, eos fuga itaque magnam natus obcaecati rem sequi.
            Consequuntur exercitationem, libero odit officiis pariatur possimus quibusdam recusandae sed voluptas voluptates!Aperiam aspernatur assumenda aut
            beatae blanditiis, cum debitis dolores eaque fugiat harum ipsa molestiae molestias nam nostrum nulla obcaecati, odit omnis optio pariatur quae rem
            rerum sed sunt totam unde?</p>

        <p className='uk-dropcap'>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa cumque eius eligendi facilis hic impedit quam quasi tenetur? Consectetur
            debitis, dolor eius enim facere nesciunt non quasi voluptates. Est, omnis?
            <br/>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aperiam delectus excepturi, neque optio quam sed ullam vel voluptate. At consectetur
            consequuntur exercitationem nihil obcaecati officia perferendis sint unde voluptatibus! Ab autem beatae dolores eius fugit, illo incidunt magnam nam
            nesciunt nisi pariatur quod, rem tenetur! Atque cum dolores non perspiciatis praesentium rem vel voluptas. At ipsam itaque temporibus voluptate?
            Amet deleniti eos et eum itaque nisi nulla quaerat qui sunt voluptatum. Animi architecto commodi consequatur deleniti dolor dolorem ea eos, est
            itaque obcaecati odit quo, quod rem, repudiandae tempore. Ab aperiam deserunt iusto nemo neque omnis similique ut. A aut commodi consectetur
            dignissimos dolore fuga hic illo minus neque numquam, odio omnis perferendis possimus quod repudiandae sit suscipit voluptates. A atque culpa ea
            eligendi error facilis fugit hic impedit incidunt ipsa iure magni maxime molestiae nam, nulla odit officiis omnis perferendis quam qui quia
            reprehenderit sequi sit temporibus voluptate!
        </p>

        <div className='uk-panel'>
            <img src={space} alt={space.text} loading='lazy' className='uk-width-medium uk-align-center uk-align-left@m uk-margin-remove-adjacent' data-uk-img=''/>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa cumque eius eligendi facilis hic impedit quam quasi tenetur? Consectetur
                debitis, dolor eius enim facere nesciunt non quasi voluptates. Est, omnis?
                <br/>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aperiam delectus excepturi, neque optio quam sed ullam vel voluptate. At consectetur
                consequuntur exercitationem nihil obcaecati officia perferendis sint unde voluptatibus! Ab autem beatae dolores eius fugit, illo incidunt magnam nam
                nesciunt nisi pariatur quod, rem tenetur! Atque cum dolores non perspiciatis praesentium rem vel voluptas. At ipsam itaque temporibus voluptate?
                Amet deleniti eos et eum itaque nisi nulla quaerat qui sunt voluptatum. Animi architecto commodi consequatur deleniti dolor dolorem ea eos, est
                itaque obcaecati odit quo, quod rem, repudiandae tempore. Ab aperiam deserunt iusto nemo neque omnis similique ut. A aut commodi consectetur
                dignissimos dolore fuga hic illo minus neque numquam, odio omnis perferendis possimus quod repudiandae sit suscipit voluptates. A atque culpa ea
                eligendi error facilis fugit hic impedit incidunt ipsa iure magni maxime molestiae nam, nulla odit officiis omnis perferendis quam qui quia
                reprehenderit sequi sit temporibus voluptate!
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores, at consequuntur corporis cum dignissimos dolore eius fuga ipsa iusto labore
                maiores, odit officia recusandae repellat rerum sapiente totam ullam voluptas. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cupiditate
                eius esse eum iste optio, soluta tempora! Accusantium eaque eveniet, ipsum minima minus non officiis provident quasi ratione, rerum sed vero.
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. <br/>
                Aliquam, aperiam deleniti esse fugiat id minus molestiae nostrum, officiis quidem quisquam
                repellendus, voluptatibus? Accusantium consequatur fuga fugiat, ipsam nisi ut voluptas? Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Culpa cumque facere id neque nesciunt odio quas sed tempore vero voluptate! Debitis distinctio earum eos fugiat molestias totam unde. Id, ut! Lorem
                ipsum dolor sit amet, consectetur adipisicing elit. Ab amet consequatur consequuntur, cumque dicta harum maxime numquam pariatur perspiciatis quae
                quod sed tenetur totam, vero voluptate. Molestias ratione reprehenderit vitae!
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda consectetur delectus, esse fugiat, fugit, harum hic id illum ipsam ipsum labore
                minima nam neque nihil non pariatur quis sed sequi?
            </p>
        </div>

        <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa cumque eius eligendi facilis hic impedit quam quasi tenetur? Consectetur
            debitis, dolor eius enim facere nesciunt non quasi voluptates. Est, omnis?
            <br/>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aperiam delectus excepturi, neque optio quam sed ullam vel voluptate. At consectetur
            consequuntur exercitationem nihil obcaecati officia perferendis sint unde voluptatibus! Ab autem beatae dolores eius fugit, illo incidunt magnam nam
            nesciunt nisi pariatur quod, rem tenetur! Atque cum dolores non perspiciatis praesentium rem vel voluptas. At ipsam itaque temporibus voluptate?
            Amet deleniti eos et eum itaque nisi nulla quaerat qui sunt voluptatum. Animi architecto commodi consequatur deleniti dolor dolorem ea eos, est
            itaque obcaecati odit quo, quod rem, repudiandae tempore. Ab aperiam deserunt iusto nemo neque omnis similique ut. A aut commodi consectetur
            dignissimos dolore fuga hic illo minus neque numquam, odio omnis perferendis possimus quod repudiandae sit suscipit voluptates. A atque culpa ea
            eligendi error facilis fugit hic impedit incidunt ipsa iure magni maxime molestiae nam, nulla odit officiis omnis perferendis quam qui quia
            reprehenderit sequi sit temporibus voluptate!
        </p>

        <blockquote cite="#">
            <p className="uk-margin-small-bottom">The blockquote element represents content that is quoted from another source, optionally with a citation which
                must be within a footer or cite element.</p>
            <footer>Someone famous in <cite><Link href={""}>Source Title</Link></cite></footer>
        </blockquote>

        <div className='uk-flex-center' data-uk-grid=''>
            <img src={space} alt={space.text} loading='lazy' className='uk-width-large' data-uk-img=''/>
            <img src={space} alt={space.text} loading='lazy' className='uk-width-large' data-uk-img=''/>
        </div>

        <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa cumque eius eligendi facilis hic impedit quam quasi tenetur? Consectetur
            debitis, dolor eius enim facere nesciunt non quasi voluptates. Est, omnis?
            <br/>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aperiam delectus excepturi, neque optio quam sed ullam vel voluptate. At consectetur
            consequuntur exercitationem nihil obcaecati officia perferendis sint unde voluptatibus! Ab autem beatae dolores eius fugit, illo incidunt magnam nam
            nesciunt nisi pariatur quod, rem tenetur! Atque cum dolores non perspiciatis praesentium rem vel voluptas. At ipsam itaque temporibus voluptate?
            Amet deleniti eos et eum itaque nisi nulla quaerat qui sunt voluptatum. Animi architecto commodi consequatur deleniti dolor dolorem ea eos, est
            itaque obcaecati odit quo, quod rem, repudiandae tempore. Ab aperiam deserunt iusto nemo neque omnis similique ut. A aut commodi consectetur
            dignissimos dolore fuga hic illo minus neque numquam, odio omnis perferendis possimus quod repudiandae sit suscipit voluptates. A atque culpa ea
            eligendi error facilis fugit hic impedit incidunt ipsa iure magni maxime molestiae nam, nulla odit officiis omnis perferendis quam qui quia
            reprehenderit sequi sit temporibus voluptate!
        </p>
    </>,

    // Article 1
    title270421: "The Rainbow Man in Ausonne",
    publishDate270421: '06/10/2021',
    shortDescription270421: <>
        The day starts with a nice load and..
    </>,
    content270421: <>
        <p className='uk-text-lead'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam dolores, eos fuga itaque magnam natus obcaecati rem sequi.
            Consequuntur exercitationem, libero odit officiis pariatur possimus quibusdam recusandae sed voluptas voluptates!Aperiam aspernatur assumenda aut
            beatae blanditiis, cum debitis dolores eaque fugiat harum ipsa molestiae molestias nam nostrum nulla obcaecati, odit omnis optio pariatur quae rem
            rerum sed sunt totam unde?</p>

        <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa cumque eius eligendi facilis hic impedit quam quasi tenetur? Consectetur
            debitis, dolor eius enim facere nesciunt non quasi voluptates. Est, omnis?
            <br/>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aperiam delectus excepturi, neque optio quam sed ullam vel voluptate. At consectetur
            consequuntur exercitationem nihil obcaecati officia perferendis sint unde voluptatibus! Ab autem beatae dolores eius fugit, illo incidunt magnam nam
            nesciunt nisi pariatur quod, rem tenetur! Atque cum dolores non perspiciatis praesentium rem vel voluptas. At ipsam itaque temporibus voluptate?
            Amet deleniti eos et eum itaque nisi nulla quaerat qui sunt voluptatum. Animi architecto commodi consequatur deleniti dolor dolorem ea eos, est
            itaque obcaecati odit quo, quod rem, repudiandae tempore. Ab aperiam deserunt iusto nemo neque omnis similique ut. A aut commodi consectetur
            dignissimos dolore fuga hic illo minus neque numquam, odio omnis perferendis possimus quod repudiandae sit suscipit voluptates. A atque culpa ea
            eligendi error facilis fugit hic impedit incidunt ipsa iure magni maxime molestiae nam, nulla odit officiis omnis perferendis quam qui quia
            reprehenderit sequi sit temporibus voluptate!
        </p>
    </>,
}

export default enArticles