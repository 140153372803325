import showCatList from "./showCatList"

const paths = {
    // GENERAL
    home: '/',
    shows: '/shows',
    workshops: '/workshops',
    troupe: '/troupe',
    contact: '/contact',
    blog: '/blog',
    partners: '/partners',
    termOfUse: '/terms-of-use',
    error: '/404',
    maintenance: '/maintenance',
    cookieDisabled: '/cookie-disabled',

    // SOCIAL MEDIA
    facebook: 'https://facebook.com/cieclownsdanslasciure',
    instagram: 'https://instagram.com/clownsdanslasciurespectacles',
    youtube: 'https://www.youtube.com/user/clownsdanslasciure',

    // COOKIE MANAGEMENT TUTORIAL
    cookieFirefox: 'https://www.whatismybrowser.com/guides/how-to-enable-cookies/firefox',
    cookieChrome: 'https://www.whatismybrowser.com/guides/how-to-enable-cookies/chrome',
    cookieEdge: 'https://www.whatismybrowser.com/guides/how-to-enable-cookies/edge',
    cookieIE: 'https://www.whatismybrowser.com/guides/how-to-enable-cookies/internet-explorer',
    cookieOpera: 'https://www.whatismybrowser.com/guides/how-to-enable-cookies/opera',
    cookieSafari: 'https://www.whatismybrowser.com/guides/how-to-enable-cookies/safari',

    // CATEGORIES
    activities: '/activities',
    events: '/events',
    new: '/new',
    themes: '/themes',

    // EVENTS
    carnival: `/event/${showCatList.carnival}`,
    christmasMarket: `/event/${showCatList.christmasMarket}`,
    clubbing: `/event/${showCatList.clubbing}`,
    easter: `/event/${showCatList.easter}`,
    eroticism: `/event/${showCatList.eroticism}`,
    halloween: `/event/${showCatList.halloween}`,
    movieFestival: `/event/${showCatList.movieFestival}`,
    musicFestival: `event/${showCatList.musicFestival}`,
    nationalDay: `/event/${showCatList.nationalDay}`,
    newYearEve: `/event/${showCatList.newYearEve}`,
    parentsDay: `/event/${showCatList.parentsDay}`,
    privateShow: `/event/${showCatList.privateShow}`,
    product: `/event/${showCatList.product}`,
    skiResort: `/event/${showCatList.skiResort}`,
    streetFestival: `/event/${showCatList.streetFestival}`,
    tattooFair: `/event/${showCatList.tattooFair}`,
    valentine: `/event/${showCatList.valentine}`,
    wedding: `/event/${showCatList.wedding}`,
    witchDay: `/event/${showCatList.witchDay}`,

    // THEME
    angelImp: `/theme/${showCatList.angelImp}`,
    bright: `/theme/${showCatList.bright}`,
    chic: `/theme/${showCatList.chic}`,
    christmas: `/theme/${showCatList.christmas}`,
    cinema: `/theme/${showCatList.cinema}`,
    cosplay: `/theme/${showCatList.cosplay}`,
    diaDeLosMuertos: `/theme/${showCatList.diaDeLosMuertos}`,
    fantasy: `/theme/${showCatList.fantasy}`,
    futuristic: `/theme/${showCatList.futuristic}`,
    horror: `/theme/${showCatList.horror}`,
    latexVinyl: `/theme/${showCatList.latexVinyl}`,
    love: `/theme/${showCatList.love}`,
    medieval: `/theme/${showCatList.medieval}`,
    music: `/theme/${showCatList.music}`,
    steampunk: `/theme/${showCatList.steampunk}`,
    white: `/theme/${showCatList.white}`,


    // ACTIVITIES
    balloonSculpture: `/activity/${showCatList.balloonSculpture}`,
    bubbles: `/activity/${showCatList.bubbles}`,
    charAmbulatory: `/activity/${showCatList.charAmbulatory}`,
    clown: `/activity/${showCatList.clown}`,
    dance: `/activity/${showCatList.dance}`,
    fireBreather: `/activity/${showCatList.fireBreather}`,
    juggling: `/activity/${showCatList.juggling}`,
    magic: `/activity/magic`,
    makeup: `/activity/makeup`,
    mime: `/activity/${showCatList.mime}`,
    musicActivity: `/activity/${showCatList.music}`,
    performer: `/activity/${showCatList.performer}`,
    pyrotechnics: `/activity/${showCatList.pyrotechnics}`,
    shoppingGallery: `/activity/${showCatList.shoppingGallery}`,
    stilt: `/activity/${showCatList.stilt}`,
    strip: `/activity/${showCatList.strip}`,
    theater: `/activity/theater`,
    workshopsActivity: `/activity/workshops`,

    // SHOWS
    alien: '/show/alien-and-ripley',
    augustin: '/show/augustin',
    blackPanther: '/show/black-panther',
    bondy: '/show/bondy-and-maia',
    clowns: '/show/clowns',
    diaDeLosMuertosShow: '/show/dia-de-los-muertos',
    fireCoal: '/show/fire-coal',
    horde: '/show/horde',
    ladyGentleLEDs: '/show/lady-and-gentle-leds',
    lightMeUp: '/show/light-me-up',
    lutinery: '/show/lutinery',
    momies: '/show/momies',
    pyrotechnicsShow: '/show/pyrotechnics-show',
    rainbowMan: '/show/rainbow-man-and-his-angels',
    rosalie: '/show/rosalie',
    striptease: '/show/striptease',
    tarzan: '/show/tarzan',
    timBurton: '/show/bim-turton',
    wadioui: '/show/wadioui',
    whiteDreams: '/show/white-dreams',
    wotan: '/show/wotan',

    // Workshop
    bubblesWorkshop: '/workshop/bubbles',
    creativeWorkshop: '/workshop/creative',
    circusWorkshop: '/workshop/circus',
    makeupWorkshop: '/workshop/makeup',
    musicalAwakening: '/workshop/musical-awakening',
    stiltWorkshop: '/workshop/stilt',
    theaterWorkshop: '/workshop/theater',
    writingWorkshop: '/workshop/writing',

    // ARTICLES
    article1: '/blog/article/article-1',
    article2: '/blog/article/article-2',
    article3: '/blog/article/article-3',
    article4: '/blog/article/article-4',
}

export default paths