const enTroupe = {
    troupeDescription: <>
        <>
            <p className='uk-text-italic'>Welcome to our Universe</p>
            <p>
                Eleven years ago, a troupe of performers in various fields got together to put all their time and <br/>efforts into developing the company
                “Clowns
                amongst the Sawdust”, which is continuously <br/>evolving in its artistic, dramatic and staging skills.
            </p>
            <p>
                In particular, we excel in the art of creating incredible characters adapted for all sorts of settings.
                <br/>
                Whether for daytime events or night-time, the choice of performers, acts and characters can be <br/>suited (roaming or a fixed “stage”) so that
                we
                can present for you the very best in entertainment.
            </p>
            <p>
                With acts from the theatre and the circus with disciplines such as "fixed and bouncing" stilts,<br/>
                "light and fire" juggling, clowning, mime, and even, acrobatics - we rise to any challenge.
                <br/>
                (All designed for total safety for young and old).
            </p>
            <p>
                By mixing in dance and music too, we present an explosive cocktail of dramatic acts to satisfy any <br/>audience.
            </p>
            <p>
                We work together in different creative and artistic areas, within the troupe; sewing, doing DIY and <br/>using dramatic art to create unusual
                imaginative and indeed fantastical extraordinary characters and <br/>costumes. Our “sound and special effects” trailer enables us to create
                lighting
                and sound, plus more,<br/> ideal for your particular setting.
                <br/>
                Indeed! The “Clowns dans la Sciure” company is a troupe of performers who work together to bring <br/>beautiful productions to fruition. This is
                our
                forté which brings us both great satisfaction as well as happiness.
            </p>
            <p>
                We recognise that to meet your wishes and needs, good organisation is essential which is why <br/>everything is prepared well in advance, so
                that
                your event will be perfect on D-Day.
            </p>
            <p>
                Our goal is to offer a high quality Artistic, Dramatic and “Magical” show with a touch of poetry
                <br/>
                ... Better than a Dream, in fact! ... an Incredible Reality!!
            </p>
            <p>
                So, don't wait!
                <br/>
                With our most Creative Greetings!
            </p>
        </>
    </>,
    troupeMetaDescription: 'Come and discover all the secrets behind our company!',

    troupeWorkshopSection: "The workshop",
    troupeWorkshopDescription: <>
        <p>
            Here is a place for artists and do-it-yourselfers to meet and exchange ideas.
            <br/>
            We develop new projects (costumes, accessories, floats...), we create,<br/> we fix...and ideas come together to have the best ideas.
        </p>

        <p>
            It's a big point of the company to tell you that the majority of our creations <br/>rare 90% created in our workshops and with the family.
            <br/>
            We work with wood, metal, latex...we grind, drill, weld, and... All in safety.
        </p>
        <p>
            For the rest, we call on talented artists, companies, friends!
            <br/>
            All competent in their field in order to share and present you the best in terms of costumes.
        </p>
        <p>
            And yes, we're versatile, full of ideas with a good dose of fun and sweat.
            <br/>
            That's our STRENGTH! Welcome to our place!
        </p>
    </>,
    troupeWorkshopSlogan: "You love create and you want to invest in beautiful projects ? Come with us and apply !",

    troupeBTSSection: "Behind the scenes",
    troupeBTSDescription: <>
        <p>
            Welcome to the Artists' Area.
            Here, they prepare their costumes, make-up, rehearse texts and movements, do their vocalizations <br/>without forgetting the sports preparation and
            much more...
        </p>
        <p>
            The organizers are there for a lot on our comfort (heated dressing rooms, catering, etc...) in order to <br/>propose us places of adequate
            preparations ;
            For all that we thank them and know how to give back <br/>to them by giving the maximum of all of us in front of YOU ! the public.
        </p>
        <p>
            Rest (micro siesta), catering, briefing, etc... are the keys to present you a quality show.
            <br/>
            It is a place of rest but also of delirium and sharing,<br/>
            and for all these good times, we want to show you that.
        </p>
        <p>
            You are welcome ! And see you FOR THE SHOW !
        </p>
    </>,

    troupeDataCountries: 'Countries visited',
    troupeDataDepartments: 'Departments crossed',
    troupeDataCities: 'Cities covered',
    troupeDataKMs: 'Kilometers traveled',
    troupeDataShows: 'Unique Shows',

    sponsorTitle: 'They trust us',
    sponsorText: <>
        The Clowns dans la Sciure (CDLS) escorts and offers its shows to local authorities, works councils, event agency, etc... <br/>
        For artistic production to meet
        your
        wildest projects!
        <br/>
        Thanks to all old and new partners' trust constantly renewed.
    </>,

    contactUs: "Contact us",
}

export default enTroupe