const esContact = {
    contactPageDesc: <>
        Usted tiene Necesidad de un concepto, dúo, trío o más ...
        <br/>
        Es con alegría que lo escucharemos, nuestros personajes pueden cruzarse y jugar juntos, todo está planeado para que su evento sea un éxito.
    </>,
    pressTitle: 'Comunicados de prensa',

    nameContact: 'APELLIDO nombre',
    phoneContact: 'Teléfono',
    emailContact: 'Correo electrónico',
    subjectContact: 'Objeto',
    subjectSelectionContact: 'Por favor, elija el tema',
    informationSubjectContact: 'Solicitud de información',
    flyerSubjectContact: 'Solicitud de folletos / catálogos',
    quotationSubjectContact: 'Solicitud de presupuesto',
    bookingSubjectContact: 'Solicitud de reserva',
    messageSubjectContact: 'Mensaje',

    contactRequiredFields: <><span className='uk-text-bold text-gold'>*</span> indica un campo obligatorio</>,

    contactInputEmpty: 'Por favor, rellene el campo ',
    contactScriptTagDetected: "He visto el intento de utilizar JavaScript. Esto no es bueno, por favor no lo vuelvas a hacer.",
    contactRequirementsFailed: "Por favor, introduzca todos los campos correctamente.",
    contactSuccess: 'El mensaje ha sido enviado',
    contactSendFailed: "El mensaje no pudo ser enviado.",

    referenceTitle: 'Las referencias',
    referenceText: <>
        <p className='uk-text-center uk-text-bold'>
            Desde su creación en 2011, la compañía ha tenido la oportunidad de actuar en todas partes.
            <br/>
            Aquí hay una lista no exhaustiva de lugares o eventos entre los que hemos <br/>pasado para realizar nuestros espectáculos.
        </p>

        <div className='uk-width-1-2@m uk-margin-auto' style={{paddingBottom: '1em'}}>
            ciudad de Fontaines-sur-Saône (Fantasti'Art), ciudad de Mourenx, ciudad d'Orthez, ciudad de Burdeos, ciudad de Saillagouse, ciudad de Bourg Madame,
            ciudad de Narbonne, ciudad de Pau, Bruselas (Belgique), ciudad de Monein, ciudad de Gdansk y Varsovia (Polonia), ciudad de Toulouse, ciudad de
            Narbonne, ciudad de Nice, ciudad de Gueret, ciudad de Rouen, ciudad de s. city de Bascons, ciudad de Cannes, ciudad de Perpignan, ciudad de Saint
            -Cyprien, ciudad des Angles, ciudad de Formigueres, ciudad de Biguglia (Corse), ciudad de Quillan, L'Oréal, ciudad d'Estavar, ciudad de
            Luc-sur-Orbier, ciudad de Cadours, ciudad de P2000, concierto sala "Bikini" Toulouse, ciudad de Creil, ciudad de Lautrec, ciudad de Albi, ciudad de
            Moissac, ciudad de cadours, ciudad de Fleury d'Aude, ciudad de Tarbes, ciudad de Montfaucon, ciudad de Salies-de-Béarn, ciudad de Lisle-sur-Tarn,
            ciudad de París, sitio de "Airbus" en Toulouse, ciudad de Muret, ciudad de Ausonne ...
        </div>
    </>,
}

export default esContact