import Link from "next/link"
import {GetSVG} from "../../../providers/Language"
import path from '../../../contents/paths'

import beblibop from 'img/partners/troupes/logo_beblibop.png'
import elCircus from 'img/partners/troupes/el_circus.png'
import whiteTigers from 'img/partners/troupes/white_tiger_logo.png'

const enPartners = {
    partnersTitle: 'Partners',
    partnerDescription: <>
        <h3 className='uk-text-bold'>Become a partner</h3>

        <p>
            Being a partner of &quot;Clowns dans la Sciure&quot; means associating your company&apos;s image with ours in the performing arts sector of the
            region and
            contributing to the cultural influence of Midi-Pyrénées and Languedoc-Roussillon.
        </p>
        <p>
            Being a partner of the Compagnie Clowns dans la Sciure means benefiting from a privileged relationship and several advantages:
        </p>

        <ul>
            <li>Invitations to shows for your clients, business relations, employees, etc...,</li>
            <li>Working hand in hand to satisfy the organisers and of course the public</li>
            <li>Preview of the season and priority access to your artists for shows</li>
        </ul>

        <h3 className='uk-text-bold'>Partner companies</h3>

        <ul>
            <li>
                <p>
                    <a href='https://el-circus.fr/' target="_blank" rel='noopener noreferrer' className='uk-link-text uk-text-italic text-hover-gold'>
                        El Circus
                    </a> Troupe
                    (Circus, strip, performer, mascot)
                    <br/>
                    <Link href={path.striptease} className='text-black text-hover-gold'>
                        <GetSVG id={'stripteaseTitle'} classes='uk-width-small'/>
                    </Link> Section
                    <br/>
                    <a href='https://el-circus.fr/' target="_blank" rel='noopener noreferrer' className='uk-text-italic text-hover-gold'>
                        Website
                    </a>
                </p>
                <img src={elCircus} alt={elCircus.text} loading='lazy' className='uk-width-medium' data-uk-img=''
                     onDragStart={(event => event.preventDefault())}/>
            </li>
            <li>
                <p>
                    Beblibop Troupe (Circus)
                    <br/>
                    <Link href={path.clowns} className='text-black text-hover-gold'>
                        <GetSVG id={'clownsTitle'} classes='uk-width-small'/>
                    </Link> Section
                    and
                    <Link href={path.rosalie} className='text-black text-hover-gold'>
                        <GetSVG id={'rosalieTitle'} classes='uk-width-small'/>
                    </Link> Section
                </p>
                <img src={beblibop} alt={beblibop.text} loading='lazy' className='uk-width-small' data-uk-img=''
                     onDragStart={(event => event.preventDefault())}/>
            </li>
            <li>
                <p>
                    Andy Klein (Strip)<br/>
                    <Link href={path.striptease} className='text-black text-hover-gold'>
                        <GetSVG id={'stripteaseTitle'} classes='uk-width-small'/>
                    </Link> Section
                </p>
            </li>
            <li>
                <p>
                    White-Tigers Performance (Circus, strip, performer)
                    <br/>
                    <Link href={path.striptease} className='text-black text-hover-gold'>
                        <GetSVG id={'stripteaseTitle'} classes='uk-width-small'/>
                    </Link> Section
                </p>
                <img src={whiteTigers} alt={whiteTigers.text} loading='lazy' className='uk-width-small' data-uk-img=''
                     onDragStart={(event => event.preventDefault())}/>
            </li>
        </ul>
    </>,
    partnersSlogan: "Join us and work hand in hand. Let's team up for our audience!"
}

export default enPartners