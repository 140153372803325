const enWorkshop = {
    // General
    allWorkshopsTitle: 'All workshops',
    allWorkshopText: <>
        <p>
            Here, we will <span className='uk-text-bold'>introduce you to the Workshops</span>, <br/>
            The CDLS company accompanies you in your first steps as an artist and <span className='uk-text-bold'>share their know-how with you</span>!
        </p>

        <p>
            In this space, it is the learning and creativity that shine through in <span className='uk-text-bold'>joy and good mood</span>!
            <br/>
            Suitable for all, children and adults, we know how to handle practice while having fun.
            <br/>
            All <span className='uk-text-bold'>supervised by competent entertainers and artists</span>.
        </p>

        <p>
            The proposed activities are as much hands-on as they are sensational <br/>
            to <span className='uk-text-bold'>best meet all your expectations</span>.
        </p>

        <p>
            Here are OUR WORKSHOPS, and they are just waiting for you!
            <br/>
            <span className='uk-text-bold'>It's up to you!</span>
        </p>
    </>,
    allWorkshopsMetaDescription: 'List of all workshops offered by the company Clowns dans la sciure',
    workshopList: <>
        <p className='uk-text-center'>
            Here are all the workshops <br/>
            that we have to offer you.
        </p>
    </>,

    bubblesWorkshopTitle: "Bubbles workshop",
    bubblesWorkshopShortDescription: <>
        Small, big or giant, the art of bubbles
        <br/>
        learning at any age, come and bubble with us!
    </>,
    bubblesWorkshopDescription: <>
        <p>
            Welcome to a world of transparency and purity for young and old.
            <br/>
            Everyone knows how to make bubbles,<br/> but what about giants and multi-bubbles at 3 meters high?
            <br/>
            Mix multicolored hues on even bigger spheres.
        </p>

        <p>
            With the right equipment for everyone, our bubble artists will be at your side <br/>to teach you how to bubble dynamically.
            <br/>
            Creative and visual, this activity captivates children, parents, and grandparents alike!
        </p>

        <p>
            In a show or in a workshop,<br/> let poetry take place in your event.
            <br/>
            A magical cocktail for a unique moment!
        </p>
    </>,

    circusWorkshopTitle: "Circus workshop",
    circusWorkshopShortDescription: <>
        Juggling workshop, balls, bolas, clubs...
        <br/>
        learn the basics with the certified juggler who suits you.
    </>,
    circusWorkshopDescription: <>
        <p>
            "But what do I see in the sky?!!"
            <br/>
            "But is it a ball?!"
            <br/>
            "No, I think it's a club!"
            <br/>
            "Look carefully, it's a ring!"
        </p>

        <p>
            Fantastic! You've just arrived on the big kids area!
            <br/>
            Here we propose you an animation on the theme of juggling arts!
            <br/>
            An initiation to the discovery of the potential of your body and the manipulation of various objects!
        </p>

        <p>
            Fun and good atmosphere in the company of passionate people,<br/> always ready to transmit their knowledge with an immense pleasure to share.
        </p>
    </>,

    creativeWorkshopTitle: "Creative workshop",
    creativeWorkshopShortDescription: <>
        The only limit is your imagination!
        <br/>
        Learning through creation
    </>,
    creativeWorkshopDescription: <>
        <p>
            This is the workshop that makes it all happen!
            <br/>
            We start from nothing, or at least not much, and we <span className='uk-text-bold'>build something together so that you leave with some really fantastic memories!</span>
        </p>

        <p>
            All this <span className='uk-text-bold'>simply and creatively, just learning in a fun and playful way</span>.
            <br/>
            <span className='uk-text-bold'>We can adapt your program</span>, dependent on the event offering you different creative workshops such as: <br/>
            construction of balls, manufacture of bolas, etc.
        </p>

        <p>Adapted for adults or children, <span className='uk-text-bold'>
            we know how to awaken your creativity with a co-ordinator always at your disposal
        </span>.</p>

        <p>Combined with the circus workshop, <span className='uk-text-bold'>you have a perfect mix for exciting discovery, learning and achievement</span>.</p>

        <p>It's up to you now !</p>
    </>,

    makeupWorkshopTitle: "Makeup workshop",
    makeupWorkshopShortDescription: <>
        Immerse yourself in the skin of your character!
        <br/>
        Welcome to the thousand and one glitter!
    </>,
    makeupWorkshopDescription: <>
        <p>
            Mixing know-how, leisure and professionalism,<br/> our company accompanies you, between eyeshadows and brushes, on your first steps in make-up.
        </p>

        <p>
            Learn how to combine your makeup, learn the material and put your artistic "paw" on your most <br/>beautiful cosplays, in order to live your day to
            the
            fullest.
            <br/>
            Leave room for the imagination of young and old!
        </p>

        <p>
            It is of course possible to have a professional make-up artist <br/>for your event, don't hesitate to let us know!
        </p>

        <p>
            Between powders and flakes, we will answer your request.
        </p>
    </>,

    musicalAwakeningWorkshopTitle: "Musical Awakening workshop",
    musicalAwakeningWorkshopShortDescription: <>
        A mix of rhythm to learn
        <br/>
        and having fun...boom tchat boom boom!
    </>,
    musicalAwakeningWorkshopDescription: <>
        <p>
            Lung chack! Lung chack! Ding ding ding ding!
        </p>

        <p>
            Here you are in the musical awakening workshop !
            <br/>
            Here, we go step by step to discover the world of music and rhythm, <br/>through our body and what surrounds us!
            <br/>
            You are going to see the musician who hides in you coming out through <br/>with fun and entertaining exercises!
        </p>

        <p>
            Accessible for everyone!
            <br/>
            Perfect for bonding and having fun together!
        </p>

        <p>Wake up your inner artist!!</p>
    </>,

    stiltWorkshopTitle: "Stilt-walker workshop",
    stiltWorkshopShortDescription: <>
        Supervised by our professionals of show,
        <br/>
        you'll be delighted to gain height
    </>,
    stiltWorkshopDescription: <>
        <p>
            Static or urban stilts, of small or great height,<br/> for all ages?!
        </p>
        <p>
            You've come to the right place!
        </p>

        <p>
            Having a complex about being small? Frustrated that you can never reach the best fruit in the tree?
            <br/>
            Pain in the neck from kissing your spouse too tall?
            <br/>
            The stilts workshop invites you to an unprecedented experience <br/>in the universe of tall people!
        </p>

        <p>
            A quality workshop lead by professionals who are passionate about the discipline <br/>in an atmosphere of relaxation and safety!
            <br/>
            Let's go!
        </p>
    </>,

    theaterWorkshopTitle: 'Theater workshop',
    theaterWorkshopShortDescription: <>
        Learning the basics of theatre through games. <br/>
        Let's laugh together and come to surpass yourself.
    </>,
    theaterWorkshopDescription: <>
        <p>To be or not to be?! That is the question!</p>

        <p>
            In this Workshop we invite you to <span className='uk-text-bold'>BE, quite simply</span>, and to discover your <span className='uk-text-bold'>Theatrical abilities playfully</span>
            through activities concocted by us...
            <br/>
            exposure to the public, group cohesion ...
            <br/>
            and <span className='uk-text-bold'>all this through the game</span>.
        </p>

        <p>Supervised by experienced artistes and trainers, <span className='uk-text-bold'>you are sure to be sharing joy and laughter!</span></p>

        <p>
            Do you still have doubts about joining us? <span className='uk-text-bold'>This workshop is made for you!
            <br/>
            Let's go!
            </span>
        </p>
    </>,

    writingWorkshopTitle: 'Writing workshop',
    writingWorkshopShortDescription: <>Come and discover the power of words!</>,
    writingWorkshopDescription: <>
        <p>
            The possibilities are vast. The time can vary from <span className='uk-text-bold'>an afternoon to a full week</span>. <br/>
            <span className='uk-text-bold'>A pleasure to play with words</span> for the <span className='uk-text-bold'>amateurs</span> or to improve for a more <span className='uk-text-bold'>professional</span> work, <br/>
            or even in the form of <span className='uk-text-bold'>therapy</span>. <br/>
            Themes can vary.
        </p>
        <p>
            For <span className='uk-text-bold'>apprentice authors</span>: from writing a novel to promoting it... <br/>
            At <span className='uk-text-bold'>school</span>: the difference, the handicap, the fantastic... <br/>
            For the <span className='uk-text-bold'>vacations</span>: the sea, friendship... <br/>
            Each workshop is <span className='uk-text-bold'>tailored</span> to the age group, the theme and the time available.
        </p>
        <p>
            Fun, educational with a touch of madness!
        </p>
    </>,
}

export default enWorkshop