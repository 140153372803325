const frHomeCarousel = {

    // January
    carouselJanuary1Title: 'NOUVEAU SITE INTERNET',
    carouselJanuary1Description: <>
        Tout neuf, tout beau ! Facile et intuitif <br/>
        <span className='uk-text-bold'>Venez décrouvrir notre compagnie !</span> <br/>
        Vous y trouverez votre bonheur pour toute l'année.
    </>,

    carouselJanuary2Title: 'JOYEUSE ANNÉE',
    carouselJanuary2Description: <>
        La compagnie vous souhaite une Joyeuse année <br/>
        remplie d'amour, de joie et bien sur la santé <br/>
        Et pour bien commencer, voici <span className='uk-text-bold'>tous nos spectacles</span> !
    </>,

    carouselJanuary3Title: 'RESPONSIVE ET BIEN PLUS..',
    carouselJanuary3Description: <>
        Notre nouveau site internet est adaptable sur tous supports <br/>
        Un site, 22 Spectacles, tous réunis rien que pour vous! <br/>
        Et bien sur les réseaux sociaux pour toutes les actualités en temps réel <br/>
        Rejoignez-nous !
    </>,

    carouselJanuary4Title: 'LOVE and FIRE',
    carouselJanuary4Description: <>
        Préparez vous à enflammer votre évènement <br/>
        Chic, feu et artifices sont de la partie <br/>
        Voici "<span className='uk-text-bold uk-text-italic'>Cracheurs de feu et jongleur pyrotechnie</span>"
    </>,

    carouselJanuary5Title: 'SAINT-VALENTIN',
    carouselJanuary5Description: <>
        Nous vous présentons 3 spectacles <br/>
        sous le signe de l'élegance aux couleurs de l'amour. <br/>
        Rendez vous pour un moment de folie, Ultra chic et glamour !
    </>,

    carouselJanuary6Title: 'AU RYTHME DE VOTRE COEUR !',
    carouselJanuary6Description: <>
        Le Steampunk à l'honneur avec "Fire's Coal",<br/>
        Il est l'heure de bruler d'envie avec Un show Unique <br/>
        qui saura réveiller votre public !
    </>,

    // February
    carouselFebruary1Title: 'CARNAVAL ET COTILLONS',
    carouselFebruary1Description: <>
        C'est le moment de s'éclater <br/>
        avec des personnages haut en couleur. <br/>
        Réservez-nous pour des moments de bonheur !
    </>,

    carouselFebruary2Title: 'ART DE RUE',
    carouselFebruary2Description: <>
        Immersion totale dans les bulles <br/>
        et dans la folie de nos comédiens <br/>
        Voici "<span className='uk-text-bold'>M'zelle Rosalie</span>" et "<span className='uk-text-bold'>Wotan project</span>"
    </>,

    carouselFebruary3Title: 'ATELIERS',
    carouselFebruary3Description: <>
        Venez découvrir nos ateliers adaptés pour petits et grands. <br/>
        Joie et bonne humeur assurée pour une explosion de créativité,<br/>
        À Pâques.. ou tout le long de l'année
    </>,

    // March
    carouselMarch1Title: 'MONTEZ LA TEMPERATURE !!',
    carouselMarch1Description: <>
        Délicatesse et sensualité sont au rendez vous,<br/>
        Voici "le clou du spectacle" pour pimenter votre soirée. <br/>
        Nos artistes aux multiples facettes vont vous faire frissonner !
    </>,

    carouselMarch2Title: 'FESTIVAL DE RUE',
    carouselMarch2Description: <>
        Nos spectacles sont ici pour ravir grands et petits,<br/>
        Prévoyez votre été et faites plaisir à votre public. <br/>
        N'attendez plus pour vivre des moments fantastiques ! <br/>
    </>,

    carouselMarch3Title: 'Gala, C.E, Mariage,...',
    carouselMarch3Description: <>
        C'est le grand jour, Ne cherchez plus ! <br/>
        Nous sommes la pour illuminer vos nuits <br/>
        Ultra chic, lasers et leds sont de la partie <br/>
    </>,

    // April
    carouselApril1Title: 'CINEMA..ON TOURNE !',
    carouselApril1Description: <>
        Votre thème est le "Grand écran" ou une soirée "Jungle" ?<br/>
        Acrobates et comédiens sauront incarner nos "Cosplay" avec brio. <br/>
        Un souvenirs inoubliable ? Repartez avec votre photo ou vidéo ! <br/>
    </>,

    carouselApril2Title: 'ATELIERS',
    carouselApril2Description: <>
        Venez découvrir nos ateliers adaptés pour petits et grands. <br/>
        Joie et bonne humeur assuré pour une explosion de créativité,<br/>
        Vous avez le choix ! "<span className='uk-text-bold'>échasses</span>", "<span className='uk-text-bold'>bulles</span>", "<span
        className='uk-text-bold'>jonglerie</span>", ...
    </>,

    carouselApril3Title: 'FESTIVAL DU CINEMA',
    carouselApril3Description: <>
        Vous n'êtes pas prêt !! épatez vos invités <br/>
        Avec un des monstres les plus populaire de la science fiction. <br/>
        Un <span className='uk-text-bold'>"Cosplay" mythique</span> pour un événement d'exception !
    </>,

    // MAY
    carouselMay1Title: 'FESTIVALS',
    carouselMay1Description: <>
        L'été arrive !! Prévoyez vos animations avec nos spectacles.<br/>
        Ils font rire, sautent dans tous les sens ! <br/>
        <span className='uk-text-bold'>Réservez-nous pour des moments de folies</span><br/>
    </>,

    carouselMay2Title: 'FESTIVAL DE RUE',
    carouselMay2Description: <>
        On imagine du feu, des engrenages, des bulles<br/>
        des costumes à couper le souffle le tout avec humour <br/>
        Voici <span className='uk-text-bold'>un univers chic et hors du commun</span> pour vos événements !<br/>
    </>,

    carouselMay3Title: 'FÊTE DES PÈRES ET MÈRES',
    carouselMay3Description: <>
        Nous prévoyons tout ! Et oui ça arrive très vite !<br/>
        Alors n'attendez plus, voici "<span className='uk-text-bold'>L'homme Arc en ciel et ses Anges</span>" <br/>
        Et "<span className='uk-text-bold'>Lady et Gentleled's</span>" pour un parfum de délicatesse et d'élégance<br/>
    </>,

    // JUNE
    carouselJune1Title: 'MARIAGE...',
    carouselJune1Description: <>
        C'est votre plus grand jour, Ne cherchez plus !<br/>
        Nous sommes la pour <span className='uk-text-bold'>illuminer votre merveilleuse journée</span> <br/>
        De l'élégance en accueil et lasers et leds pour la nuit<br/>
    </>,

    carouselJune2Title: 'FÊTE NATIONALE',
    carouselJune2Description: <>
        Préparez vous à enflammer votre évènement<br/>
        Chic, <span className='uk-text-bold'>feu et artifices sont de la partie</span>. C'est le jour J !<br/>
        Voici "Cracheurs de feu et jongleur pyrotechnie"<br/>
    </>,

    carouselJune3Title: 'FÊTE DE LA MUSIQUE (STEAMPUNK)',
    carouselJune3Description: <>
        On imagine du feu, des engrenages,<br/>
        des costumes à couper le souffle sur de la musique de folie<br/>
        Voici un <span className='uk-text-bold'>univers chic et hors du commun</span> pour vos événements<br/>
    </>,

    // JULY
    carouselJuly1Title: "VIENS ! ON VA DANSER..",
    carouselJuly1Description: <>
        Et c'est partie pour un été de folie<br/>
        Nos performers sont présents pour enflammé le dance floor <br/>
        <span className="uk-text-bold">Feu, Lumineux, robots, jongleurs, etc...</span> Nous sommes ici !
    </>,

    carouselJuly2Title: 'FESTIVALS',
    carouselJuly2Description: <>
        Il fait bon, il fait chaud ! Nos spectacles sont de sorties<br/>
        <span className='uk-text-bold'>bulles, échassiers, jongleurs avec humour et poésie.</span> <br/>
        Si vous avez oublier de nous réservez, il y a encore l'année prochaine !<br/>
    </>,

    carouselJuly3Title: "FETE DES SORCIÈRES",
    carouselJuly3Description: <>
        Et oui c'est pour bientôt ! C'est le moment d'y penser<br/>
        <span className='uk-text-bold'>Plusieurs univers pour un maximum de sensations</span> <br/>
        N'attendez pas ! Réservez-nous des maintenant !<br/>
    </>,

    // AUGUST
    carouselAugust1Title: "ATELIERS",
    carouselAugust1Description: <>
        Venez découvrir nos ateliers adaptés pour petits et grands.<br/>
        Joie et bonne humeur assuré pour une explosion de créativité, <br/>
        Vous avez le choix ! "<span className='uk-text-bold'>échasses</span>", "<span className='uk-text-bold'>bulles</span>", "<span
        className='uk-text-bold'>jonglerie</span>"...<br/>
    </>,

    carouselAugust2Title: "MONTEZ LA TEMPÉRATURE !!",
    carouselAugust2Description: <>
        <span className='uk-text-bold'>L'été est bien arrivé</span>, retrouvez nous dans les salons d'érotisme,<br/>
        du tatouage ou pour des stripteases hors du commun ! <br/>
        <span className='uk-text-bold'>Choisissez votre univers</span> et pimenter votre évènement.<br/>
    </>,

    carouselAugust3Title: "C'EST L'ÉTÉ et INAUGURATION",
    carouselAugust3Description: <>
        Il fait chaud ! Montrez ce qu'il y a de plus beaux à vos invités<br/>
        <span className='uk-text-bold'>bulles, échassiers, jongleurs avec humour et poésie</span>.<br/>
        Une inauguration? lancement de produits? C'est ICI<br/>
    </>,

    // SEPTEMBER
    carouselSeptember1Title: "C'EST LA RENTRÉE",
    carouselSeptember1Description: <>
        Prévoyez votre <span className='uk-text-bold'>rentrée Cinéma !</span><br/>
        Plusieurs "Cosplay" à votre disposition :"Black Panther",<br/>
        "Tarzan", "Alien" <span className="uk-text-bold">ou pour une soirée</span>, à vous de choisir !<br/>
    </>,

    carouselSeptember2Title: "SPECTACLE DÉAMBULATOIRE",
    carouselSeptember2Description: <>
        "M'zelle Rosalie" est de retour , Faites vous plaisir !<br/>
        Avec bien sur toutes les autres qui vous attendent ici.<br/>
        <span className='uk-text-bold'>Animer vos futurs évènements...c'est maintenant !</span><br/>
    </>,

    carouselSeptember3Title: "DÉAMBULATION FRISSONS !",
    carouselSeptember3Description: <>
        Prévoyez vos <span className='uk-text-bold'>fêtes Médiévales pour l'année qui arrive</span>,<br/>
        <span className='uk-text-bold'>fêtes de la sorcière</span> et bien sur <span>Halloween</span> !<br/>
        "La horde" vas vous faire trembler de plaisir<br/>
    </>,

    // OCTOBRE
    carouselOctober1Title: "HALLOWEEN ! C'EST MAINTENANT",
    carouselOctober1Description: <>
        "La Horde", déambulation de p'tits diablotins, un cerbère et un squelette bondissant !<br/>
        Sans oublier "Alien", un cosplay de folie qui saura surprendre vos invités<br/>
        <span className='uk-text-bold'>Voici des costumes impressionnant pour ce mois d'octobre</span><br/>
    </>,

    carouselOctober2Title: "DÉAMBULATION FEU ET BULLES",
    carouselOctober2Description: <>
        Le Steampunk à l'honneur avec "Fire's Coal",<br/>
        un show Unique <span className='uk-text-bold'>avec char et performances</span> !<br/>
        Animer vos futurs évènements sous le signe Industriel-Chic...<br/>
    </>,

    carouselOctober3Title: "SPECTACLES FRISSONS !",
    carouselOctober3Description: <>
        Prévoyez votre fête d'Halloween! les réservations, C'est maintenant !<br/>
        "Día de Muertos" nous arrive tout droit du Mexique !<br/>
        Sans oublier nos "momies" qui sauront vous faire une soirée de folie<br/>
    </>,

    // NOVEMBER
    carouselNovember1Title: "VOILA LES FÊTES DE FIN D'ANNÉES !",
    carouselNovember1Description: <>
        Nous vous présentons 3 spectacles déambulatoire pour Noël<br/>
        toujours <span className='uk-text-bold'>sous le signe de l'élégance et de la folie</span>,<br/>
        voici "L'homme Arc-en-ciel et ses Anges" et le "Rêve blanc" et bien sur les "Wadioui"<br/>
    </>,

    carouselNovember2Title: "NOUVEL AN APPROCHE",
    carouselNovember2Description: <>
        <span className='uk-text-bold'>C'est le moment de prévoir aussi votre réveillon</span>,<br/>
        Des LEDs, du feu, du chic pour que le dernière jour de l'année sois une réussite !<br/>
        "Light me Up", "Fire's Coal" et les "Cracheurs de feu"<br/>
    </>,

    carouselNovember3Title: "NOEL ARRIIIIIIVE !!",
    carouselNovember3Description: <>
        C'est la dernière ligne droite pour vos fêtes de fin d'années.<br/>
        Exigez le meilleur avec <span className='uk-text-bold'>technique et humour pour un Noël-Chic</span><br/>
        Les lutins d'or et d'argent les "<span className='uk-text-bold'>Wadioui</span>" sont de la partie<br/>
    </>,

    // DECEMBER
    carouselDecember1Title: "JOYEUSE FÊTES DE FIN D'ANNÉES",
    carouselDecember1Description: <>
        Nous vous souhaitons de joyeuse fêtes <br/>
        <span className='uk-text-bold'>Suivez nous sur nos réseaux sociaux</span> et surtout.. <br/>
        N'oubliez pas de nous <span className='uk-text-bold'>réserver pour l'année prochaine</span>
    </>,

    carouselDecember2Title: "NOUVEL AN",
    carouselDecember2Description: <>
        Mettez vos invités dans la lumière !! <br/>
        Des LEDs, du feu, du chic pour que <span className='uk-text-bold'>le dernier jour de l'année sois une réussite</span> ! <br/>
        "Light me Up" et "Cracheur de feu et Jongleur Pyrotechnie"
    </>,

    carouselDecember3Title: "DERNIÈRE LIGNE DROITE!",
    carouselDecember3Description: <>
        Un accueil Ultra Chic pour vos invités du réveillon..  <br/>
        <span className='uk-text-bold'>Élégance, technique et folie</span>, <br/>
        Voici "Lady and Gentleled's" et le "Rêve blanc"
    </>,

    carouselDecember4Title: "SANS OUBLIER !",
    carouselDecember4Description: <>
        La magie du feu avec "Fire's Coal", <br/>
        Un show Unique avec char et performance feu/pyrotechnie ! <br/>
        Entrez dans l'univers <span className='uk-text-bold'>Chic-Steampunk pour votre réveillon</span>
    </>,
}

export default frHomeCarousel