const frWorkshop = {
    // General
    allWorkshopsTitle: 'Toutes les activités',
    allWorkshopText: <>
        <p>
            Ici, Nous allons <span className='uk-text-bold'>vous présenter les Ateliers / Activités</span>, <br/>
            La compagnie CDLS vous accompagne dans vos premiers pas d&apos;artiste et vous <span className='uk-text-bold'>partage leur savoir-faire</span> !
        </p>

        <p>
            Dans cet espace, c&apos;est l&apos;<span className='uk-text-bold'>apprentissage et la créativité</span> qui rayonne dans la <span
            className='uk-text-bold'>joie et la bonne humeur</span> !
            <br/>
            Adapté à tous et toutes, enfant et adulte, nous savons manier la pratique tout en s&apos;amusant, <br/>
            le tout <span className='uk-text-bold'>encadré par des animateurs et artistes compétents</span>.
        </p>

        <p>
            Les activités proposées sont autant manuelles que sensationnelles
            <br/>
            pour <span className='uk-text-bold'>satisfaire au mieux vos attentes</span>.
        </p>

        <p>
            Voici NOS ATELIERS, et ils n&apos;attendent que vous !
            <br/>
            A vous de jouer !
        </p>
    </>,
    allWorkshopsMetaDescription: 'Liste de tous les ateliers / activités proposés par la compagnie Clowns dans la sciure',
    workshopList: <>
        <p className='uk-text-center'>Voici tous les ateliers / activités <br/>que nous vous proposons</p>
    </>,

    bubblesWorkshopTitle: "Atelier bulles",
    bubblesWorkshopShortDescription: <>
        Petites, grandes ou géantes, <br/>l&apos;art des bulles s&apos;apprend à tout âge, <br/>venez buller avec nous !
    </>,
    bubblesWorkshopDescription: <>
        <p>
            Bienvenue dans un <span className='uk-text-bold'>monde de transparence et de pureté</span> pour petits et grands.
            <br/>
            Tout le monde sait faire des bulles, Mais qu&apos;en est il des <span className='uk-text-bold'>géantes et des multibulles</span> à 3 mètre haut ?
            <br/>
            Entremêlez des teintes multicolores sur des sphères encore plus grosses.
        </p>

        <p>
            Avec du <span className='uk-text-bold'>matériel adapté à tous</span>, nos artistes bulleurs seront à vos côtés pour vous <span
            className='uk-text-bold'>apprendre à buller dynamiquement</span>.
            <br/>
            Créative et visuelle, cette activité captive enfant, parent, et grand parent !
        </p>

        <p>
            <span className='uk-text-bold'>En spectacle ou en Atelier</span>, <br/>
            Laissez la poésie prendre place dans votre événement.
            <br/>
            <span className='uk-text-bold'>Un cocktail magique pour un moment unique</span> !
        </p>
    </>,

    circusWorkshopTitle: "Atelier cirque",
    circusWorkshopShortDescription: <>
        Atelier jonglerie, balles, bolas, massues...
        <br/>
        Apprenez les bases avec l&apos;agrès <br/>qui vous correspond
    </>,
    circusWorkshopDescription: <>
        <p>
            – Mais que vois-je dans le ciel ?!!!
            <br/>
            – Mais c&apos;est une balle ?!
            <br/>
            – Non, je crois bien que c&apos;est une massue !
            <br/>
            – Regardez bien c&apos;est un anneau !
        </p>

        <p>
            Fantastique ! Vous venez d&apos;arriver sur l&apos;aire des grands enfants !
            <br/>
            Ici nous vous proposons une animation sur le <span className='uk-text-bold'>thème des arts de la jonglerie</span> !
            <br/>
            <span className='uk-text-bold'>Une initiation à la découverte</span> du potentiel de son corps et à la manipulation de divers objets !
        </p>

        <p>
            <span className='uk-text-bold'>Rigolade et bonne ambiance assurée</span> en compagnie de passionnés <br/>
            toujours prêt à transmettre leurs savoirs avec un immense <span className='uk-text-bold'>plaisir de partager</span>.
        </p>
    </>,

    creativeWorkshopTitle: "Atelier créatif",
    creativeWorkshopShortDescription: <>
        N&apos;est de limite que son imagination!
        <br/>
        L&apos;apprentissage par la création
    </>,
    creativeWorkshopDescription: <>
        <p>
            Voici l&apos;atelier qui concrétise le tout!
            <br/>
            On part de rien ou de pas grand-chose et <span className='uk-text-bold'>nous construisons ensemble afin que vous repartiez avec votre souvenir... en réel</span> !
        </p>

        <p>
            Tout cela dans la <span className='uk-text-bold'>créativité, la simplicité et l&apos;apprentissage de façon ludique et joueuse</span>.
            <br/>
            <span className='uk-text-bold'>Nous nous adaptons à votre programme</span> selon l&apos;événement en vous proposant différents ateliers de
            construction
            comme par exemple : <br/>
            construction de balles, fabrication de bolas, etc.
        </p>

        <p>
            Adapté pour les grands ou les petits, <span className='uk-text-bold'>nous saurons réveiller votre créativité avec toujours un animateur à votre écoute</span>.
        </p>

        <p>
            Combiné avec l&apos;atelier cirque, <span
            className='uk-text-bold'>Vous avez un mélange parfait pour la découverte, l&apos;apprentissage et la réalisation</span>.
        </p>

        <p>À vous de jouer !</p>
    </>,

    makeupWorkshopTitle: "Atelier maquillage",
    makeupWorkshopShortDescription: <>
        Plongez vous dans la peau <br/>de votre personnage !
        <br/>
        Bienvenue aux mille et une paillettes !
    </>,
    makeupWorkshopDescription: <>
        <p>
            Mélangeant savoir faire, loisir et professionnalisme, <br/>
            notre compagnie vous accompagne, entre fards et pinceaux, sur vos <span className='uk-text-bold'>premiers pas maquillage</span>.
        </p>

        <p>
            Apprenez à combiner votre maquillage, d&apos;apprendre la matière et de mettre votre « patte » artistique à vos plus beaux cosplays, <span
            className='uk-text-bold'>afin de vivre
            pleinement votre journée</span>.
            <br/>
            laissez place à l&apos;imagination des petits et des grands !
        </p>

        <p>
            Il est bien sur possible d&apos;avoir <span className='uk-text-bold'>une maquilleuse ou maquilleur professionnel <br/>
            pour votre événement</span>, n&apos;hésitez pas à nous en faire part !
        </p>

        <p>Entre poudres et paillettes, <span className='uk-text-bold'>Nous répondrons à votre demande</span>.</p>
    </>,

    musicalAwakeningWorkshopTitle: "Atelier éveil musical",
    musicalAwakeningWorkshopShortDescription: <>
        Un mélange de rythmique pour apprendre
        <br/>
        en s&apos;amusant..boum cthat boum boum !
    </>,
    musicalAwakeningWorkshopDescription: <>
        <p>Poum chack ! Poum chack ! Ding Ding Ding !</p>

        <p>
            Vous voici dans l&apos;atelier d’<span className='uk-text-bold'>éveil musical</span> !
            <br/>
            Ici, nous avançons pas à pas pour découvrir l&apos;<span className='uk-text-bold'>univers de la musique et du rythme</span> au travers de notre
            corps et
            de ce qui nous entoure !
            <br/>
            Vous allez voir jaillir le musicien qui se cache en vous au travers de nos <span className='uk-text-bold'>exercices ludiques et amusants</span> !
        </p>

        <p>
            Accessible pour les grands et les plus petits !
            <br/>
            Parfait pour <span className='uk-text-bold'>créer des liens et rigoler tous ensemble</span> !
        </p>

        <p>Réveillez votre artiste intérieur !!!</p>
    </>,

    stiltWorkshopTitle: "Atelier échasses",
    stiltWorkshopShortDescription: <>
        Encadré par nos professionnels <br/>du spectacle, vous serez ravi de prendre <br/>de la hauteur
    </>,
    stiltWorkshopDescription: <>
        <p>
            Échasses statiques ou urbaines, de <span className='uk-text-bold'>petite ou grande hauteur</span>, <br/>
            pour les grand et les petits ?!
            <br/>
            Vous êtes bien au bon endroit !
            <br/>
            Complexé d’être petit ? Frustré de ne jamais pouvoir atteindre les meilleurs fruits dans l&apos;arbre ?
            <br/>
            Mal au cou à force d&apos;embrasser votre conjoints trop grand ?
            <br/>
            L&apos;atelier échasse vous invite dans une <span
            className='uk-text-bold'>expérience sans précédent dans l&apos;univers des personnes de grandes tailles</span> !
        </p>

        <p>
            Un atelier de qualité animé par des professionnels <span className='uk-text-bold'>passionnés de la discipline</span> <br/>
            dans une ambiance de <span className='uk-text-bold'>détente et de sécurité</span> !
            <br/>
            Allez c&apos;est parti !
        </p>
    </>,

    theaterWorkshopTitle: 'Atelier théâtre',
    theaterWorkshopShortDescription: <>
        Apprentissage des bases du théâtre par le jeu.
        <br/>
        Rigolons ensemble et venez vous surpasser
    </>,
    theaterWorkshopDescription: <>
        <p>Être ou ne pas être ?! Tel est la question !</p>

        <p>
            Dans cet atelier nous vous invitons à <span className='uk-text-bold'>ÊTRE tout simplement</span> et à découvrir vos <span className='uk-text-bold'>capacités théâtrales
            ludiquement</span> au travers d&apos;activités concoctés par nos soins...
            <br/>
            Respirations, ouverture vers le public, cohésion de groupe...
            <br/>
            et <span className='uk-text-bold'>tout cela par le jeu</span>
        </p>

        <p>Encadrés par des artistes et formateurs expérimentés, <span className='uk-text-bold'>la joie, les rires et le partage seront à coups sûr au rendez-vous</span> !
        </p>

        <p>
            Si vous doutez encore à nous rejoindre, c&apos;est que <span className='uk-text-bold'>cet atelier est fait pour vous</span> !
            <br/>
            C&apos;est parti !
        </p>
    </>,

    writingWorkshopTitle: 'Atelier écriture',
    writingWorkshopShortDescription: <>Venez découvrir le pouvoir des mots !</>,
    writingWorkshopDescription: <>
        <p>
            Les possibilités sont vastes. Le temps peut varier <span className='uk-text-bold'>d’une après-midi à une semaine complète</span>. <br/>
            <span className='uk-text-bold'>Un plaisir de jouer avec les mots</span> pour les <span className='uk-text-bold'>amateurs</span> ou de se
            perfectionner pour un travail plus <span className='uk-text-bold'>professionnel</span>, <br/>
            ou même encore sous forme de <span className='uk-text-bold'>thérapie</span>. <br/>
            Les thématiques peuvent varier.
        </p>
        <p>
            Pour des <span className='uk-text-bold'>apprentis auteurs</span> : de l’écriture d’un roman à sa promotion... <br/>
            À l’<span className='uk-text-bold'>école</span> : la différence, le handicap, le fantastique... <br/>
            Pour les <span className='uk-text-bold'>vacances</span> : la mer, l’amitié... <br/>
            Chaque atelier est <span className='uk-text-bold'>préparé sur mesure</span> selon la tranche d’âge, la thématique et le temps disponible.
        </p>
        <p>
            Ludique, pédagogique avec un brin de folie !
        </p>
    </>,
}

export default frWorkshop