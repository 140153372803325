import Link from 'next/link'

import path from '../../../contents/paths'

import alienTitle from 'img/general/svg/shows/Alien.svg'
import augustinTitleEN from 'img/general/svg/shows/Augustin_UK.svg'
import blackPantherTitle from 'img/general/svg/shows/Black.svg'
import bondyTitle from 'img/general/svg/shows/Bondy.svg'
import calissonTitle from 'img/general/svg/shows/Clowns.svg'
import diaDeLosMuertosTitle from 'img/general/svg/shows/Alejandro.svg'
import fireTitle from 'img/general/svg/shows/Fire.svg'
import hordeTitleEN from 'img/general/svg/shows/La_Horde_UK.svg'
import ladyGentleTitle from 'img/general/svg/shows/Lady.svg'
import lightMeUpTitle from 'img/general/svg/shows/Light_Me_Up.svg'
import lutineryTitle from 'img/general/svg/shows/Lutinery_UK.svg'
import momiesTitleEN from 'img/general/svg/shows/Momies_UK.svg'
import pyrotechnicsShowTitleEN from 'img/general/svg/shows/PyrotechnicsShow_UK.svg'
import rainbowManTitleEN from 'img/general/svg/shows/L_homme_UK.svg'
import reveBlancEN from 'img/general/svg/shows/Le_REVE_UK.svg'
import rosalieTitleEN from 'img/general/svg/shows/Rosalie_UK.svg'
import stripteaseTitle from 'img/general/svg/shows/Striptaese.svg'
import tarzanTitleEN from 'img/general/svg/shows/Tarzan_UK.svg'
import timTitleEN from 'img/general/svg/shows/Tim_UK.svg'
import wadiouiTitleEN from 'img/general/svg/shows/WadOuiUK.svg'
import wotanTitle from 'img/general/svg/shows/Wotan.svg'

const enShows = {
    // General
    allShowsTitle: 'All show',
    allShowsMetaDescription: 'List of all the shows offered by the company Clowns dans la sciure',

    newShowsTitle: 'New show',

    eventMetaDescription: 'An event to organize? Come and see what we have to offer!',
    themeMetaDescription: "We offer a wide variety of universes to match any occasion!",
    activitiesMetaDescription: "Do you want to interact with the public or organize workshops? This is the place to be!",

    videoShow: 'Video',
    videoShowDescription: 'Choose the video!',
    galleryShow: 'Photo gallery',

    eventFileTitle: "Create an event that suits you !",
    fileClickMessage: "Click on the image to see the preview",
    askBrochure: 'Presentation brochure on demand',
    askQuote: 'Get a quotation',

    prevShow: 'Previous show',
    nextShow: 'Next show',

    showDataTechnics: 'Technically autonomous',
    showPyrotechnicsAvailable: 'Pyrotechnic option',
    showDurationVariable: 'Variable',
    showPositionAmbulatory: 'Ambulatory with small fixed scenes',
    showStiltAvailable: 'On the ground or (and) with stilts',
    showSantaAvailable: 'Santa Claus option',
    showFireAvailable: 'Fire option',
    showFloatAvailable: 'Float option',
    showMusicOption: 'Music option',

    // Alien & Ripley
    alienRipleyTitle: alienTitle,
    alienRipleyMetaTitle: 'Alien and Ripley',
    alienRipleyShortDescription: <>
        Embark for Big Chills <br/>
        in this Science-Fiction universe!
    </>,
    alienRipleyDescription: <>
        <p>
            From the Alien Cult Movie "the 8th Passenger", come aboard with us in the Nostromo,<br/>
            for <span className='uk-text-bold'>a unique show mixing Fire and Pyrotechnic effects</span>,<br/>
            in the fantastic and unavoidable Universe of the great Master <span className='uk-text-bold'>Mr Giger!</span>
        </p>

        <p>
            Our Alien is a unique piece, its exo-skeleton moves, drools, opens its mouth, <span className='uk-text-bold'>a real performance</span>...
            <br/>
            Enhanced by <span className='uk-text-bold'>a fire-eater with of course, "Ripley"</span> straight out of the late 70's!
        </p>

        <p>
            Here is a wandering and a cosplay which will delight all ages!
            <br/>
            Memories, a revelation for the youngest, but all without creating fear, thanks to our acting talents.
        </p>

        <p>
            The Wandering «Cosplay» show, avalaible day or night.
            <br/>
            Book now for an <span className='uk-text-bold'>EXCEPTIONAL SHOW!!</span>
        </p>
    </>,
    alienRipleyTechnics: 'Autonomous if 2 artists present',
    alienRipleyDataCostume: 'Impressive costume',
    alienRipleyNbArtists: '1 to 2 artists',

    // Augustin
    augustinTitle: augustinTitleEN,
    augustinMetaTitle: 'Augustin the Scarecrow',
    augustinShortDescription: <>
        Wandering of an atypical character <br/>
        mixing curiosity and poetry
    </>,
    augustinDescription: <>
        <p>Fantastic character wandering on the ground or on stilts.</p>

        <p>Do you want <span className='uk-text-bold'>a muffled and magical atmosphere</span>?</p>

        <p>
            <span className='uk-text-bold'>Impact on the environment</span>: ecologically and eco-designed.
            <br/>
            All the props are hand-made with barely any electrical power (less than 10% on the whole character) <br/>
            in the atmosphere of <span className='uk-text-bold'>Mr. Tim Burton, and the fairy-tales from Grimm and Perrault</span>...
        </p>

        <p>
            "Augustin" is an endearing character <span className='uk-text-bold'>making sweeties appear magically from here and there and everywhere</span>,<br/>
            and <span className='uk-text-bold'>from his hat with an extraordinary joyfulness</span>...
            <br/>
            Descended from his musical planet to arrive in the Humans world, this scarecrow really knows how to thrill both young and old with his humour and
            kindness.
        </p>

        <p>
            You won't want him to go back to his planet...
            <br/>
            Did you really want to even try?!
        </p>
    </>,
    augustinDataCostume: 'Impressive costume',
    augustinDataMusic: 'Electro music on the floor from 3 artists',
    augustinNbArtist: '1 to 6 artists',

    // Black Pather
    blackPantherTitle: blackPantherTitle,
    blackPantherMetaTitle: 'Black Panther',
    blackPantherShortDescription: <>
        Embark for Big Chills <br/>
        in this Science-Fiction universe!
    </>,
    blackPantherDescription: <>
        <p>
            Here is the cosplay of <span className='uk-text-bold'>"T'Challa"</span>, alias the Black Panther,<br/>
            emblematic fictional character of the <span className='uk-text-bold'>"Marvel Comics" universe</span>.
        </p>

        <p>
            What else would you need for your "Cinema" event? <span className='uk-text-bold'>A beloved superhero acrobat</span> <br/>
            loved by all with the allure worthy of a feline?
            <br/>
            Look no further, here he is!
        </p>

        <p>
            More than a costume, "Black panther" knows how to sneak in and stir <span className='uk-text-bold'>the emotions of your public</span> <br/>
            with his technical prowess as well as with his gentleness.
        </p>

        <p>
            Having become part of the pop-culture since the release of the movie,<br/>
            Many fans wish to be photographed with <span className='uk-text-bold'>the famous "Black panther"</span>,<br/>
            the wish can come true in the kingdom of Wakanda or... at home!
            <br/>
            It is HERE now to delight and inspire young and old.
        </p>
    </>,
    blackPantherDataCostume: '"Cosplay" costume',
    blackPantherNbArtist: '1 artist',


    // Bondy & Maya
    bondyMaiaTitle: bondyTitle,
    bondyMetaTitle: 'Bondy & Maïa',
    bondyMayaShortDescription: <>
        Find our teasing duo <br/>
        in the BDSM universe
    </>,
    bondyMayaDescription: <>
        <p>Forget your prejudices and convictions!</p>

        <p>
            Come and discover a world where whip and caresses are mixed.
            <br/>
            Where skin and chains clash, for the pleasure and ropes <span className='uk-text-bold'>"Shibari"</span>,<br/>
            unite them under the gaze of your audience.
        </p>

        <p>
            On the floor, moulded from <span className='uk-text-bold'>latex and vinyl, the beautiful</span> "Maïa" will not leave it alone! <br/>
            It is she who subjects the stilt-walker "Bondie" to her wildest desires.
            <br/>
            All this <span className='uk-text-bold'>with a crazy love</span>...
        </p>

        <p>
            Immerse yourself with them in the <span className='uk-text-bold'>world of BDSM</span>: <br/>
            Bondage, Domination and Submission...
            <br/>
            There is definitely a discipline that <span className='uk-text-bold'>will awaken your fantasies</span>.
            <br/>
            Ready to play? It's Now!
        </p>
    </>,
    bondyMayaDataCostume: 'Latex and vinyl costume',
    bondyMayaNbArtist: '2 artists',

    // Clown Calisson & Berlingo
    clownsTitle: calissonTitle,
    clownsMetaTitle: 'Clowns',
    clownsShortDescription: <>
        Timeless, full of humor and surprises,<br/>
        to rekindle everyone's emotions
    </>,
    clownsDescription: <>
        <p>Which character will give you the <span className='uk-text-bold'>maximum of emotions?</span></p>

        <p>
            Yes !! It's the clown, the Company offers you <span className='uk-text-bold'>colourful artists for a good dose of humour</span> <br/>
            Out of nothing, there is completeness!!
            <br/>
            In the infinite smallness, we have <span className='uk-text-bold'>an ocean of good humour...... and we really know how to display it</span>.
        </p>

        <p>
            On the ground, on stilts, on rollerblades, on a unicycle or on a motorbike; the clown, knows how to do everything!
            <br/>
            He can be a juggler, an acrobat or a dancer, that's for sure!!
        </p>

        <p>
            His art will give you <span className='uk-text-bold'>fantastic sensations</span>.
            <br/>
            If you really want to <span className='uk-text-bold'>bring pleasure to your guests of all ages</span>, this is the right choice!
        </p>

        <p>
            <span className='uk-text-bold'>Tenderness but with mad laughter</span>, our clowns will give you your <span className='uk-text-bold'>fulfilment of happiness</span>!
        </p>
    </>,
    clownsDataCostume: 'Funny costume',
    clownsNbArtist: '1 to 2 artists',

    // Cracheur de feu et pyrotechnie
    pyrotechnicsShowTitle: pyrotechnicsShowTitleEN,
    pyrotechnicsShowMetaTitle: 'Fire-eater and Pyrotechnic Juggler',
    pyrotechnicsShowShortDescription: <>
        Pyrotechnics, acrobatic carrying <br/>and fire-breather... All is there!
    </>,
    pyrotechnicsShowDescription: <>
        <p>
            Please discover this exceptional "Act"!
            <br/>
            <span className='uk-text-bold'>Of classy and refined appearance</span>, I can assure you that they are not in lace!
            <br/>
            We have our "fire artists", <span className='uk-text-bold'>all prodigious jugglers and manipulators</span>, <br/>
            knowing how to handle their flaming instruments with unequalled ease.
        </p>

        <p>
            You will have noticed, someone is missing!
            <br/>
            Beauty is also part of the party, admit it, her <span className='uk-text-bold'>flamboyant beauty</span>...
            <br/>
            and her ember look have already conquered you!
        </p>

        <p>
            Let your child's heart take over at the sight of these gigantic flames.
            <br/>
            We will offer you some <span className='uk-text-bold'>sparkling tricks and acrobatic lifts</span>!
            <br/>
            All in safety! 3...,2..,1!! Our "burners" will warm you up!
        </p>
    </>,
    pyrotechnicsNbArtist: '2 to 5 artists',

    // Dia de los Muertos
    diaDeLosMuertosTitle: diaDeLosMuertosTitle,
    diaDeLosMuertosMetaTitle: 'Alejandro & Gabriella - Dia de los Muertos',
    diaDeLosMuertosShortDescription: <>
        A perfume of elegance in this day <br/>
        of the feast of the dead
    </>,
    diaDeLosMuertosDescription: <>
        <p>
            Back from the dead, you are invited to join the colourful world <br/>
            of this charming deceased couple.
            <br/>
            <span className='uk-text-bold'>Able to appear on the ground or on stilts</span>, <br/>
            these lovers will bewitch you with their presence.
        </p>

        <p>
            These two characters love to attract attention, and for the occasion, <br/>
            they have put on their most beautiful pageantry and reserve <span className='uk-text-bold'>their most bewitching dances</span> for you!
            <br/>
            Coming from the middle of the " Funeral Royalty ", their princely attitudes <br/>
            will add <span className='uk-text-bold'>a very classy touch to your event</span>!
        </p>

        <p>
            Alejandro and Gabriella are already here!
            <br/>
            Let's get the party started!
        </p>
    </>,
    diaDeLosMuertosDataCostume: 'Luminous costume',
    diaDeLosMuertosNbArtists: '2 artists',

    // Fire's Coal
    fireCoalTitle: fireTitle,
    fireCoalMetaTitle: "Fire's Coal",
    fireCoalShortDescription: <>
        This Victorian-looking family <br/>will know how to rekindle the flame of our bonfires.
    </>,
    fireCoalDescription: <>
        <p>
            Imagine yourself in a <span className='uk-text-bold'>Victorian science fiction Universe</span>, <br/>
            somewhere between H.G. Wells and Jules Verne, <br/>
            but then....
            <br/>
            Add some <span className='uk-text-bold'>English charm with its “retro” style costumes, Chic and elegant</span>, <br/>
            and some Fire and Steam from our “special effects”....
        </p>

        <p>
            Here we are at the heart of an explosive cocktail which will transport you <br/>
            in a <span className='uk-text-bold'>fantastic, breath-taking show</span>.
        </p>

        <p>
            Everything is arranged for the total safety of young and old.
            <br/>
            The entertainers will <span className='uk-text-bold'>amaze you with their prowess on their stilts, <br/>
            with the pyrotechnics, the juggling, and the fire-eating</span>!
            <br/>
            Why not book now for your journey to our fantastic “Steampunk“ Universe?
        </p>
    </>,
    fireCoalDataCostume: 'Steampunk costume',
    fireCoalNbArtists: '4 to 6 artists',

    // La Horde
    hordeTitle: hordeTitleEN,
    hordeMetaTitle: 'The Evile Forces',
    hordeShortDescription: <>
        A fantastic mythological universe <br/>
        of creatures straight from the far north
    </>,
    hordeDescription: <>
        <p>
            Cunning yet talented, these <span className='uk-text-bold'>legendary creatures</span> <br/>
            are distinguished by their <span className='uk-text-bold'>extraordinary prowess</span> and their sense of welcome.
        </p>


        <p>
            Descendant straight from Helheim, one of the nine worlds of <span className='uk-text-bold'>Nordic Mythology</span>, <br/>
            they know how to <span className='uk-text-bold'>warm up your guests with their Music, Games</span> <br/>
            and, of course.... <span className='uk-text-bold'>Fire</span>.
        </p>

        <p>
            “<span className='uk-text-bold'>A harmonious and fantastic Magical mixture</span> of the Art of Juggling, Stilt-performances, the Magic of Fire, and
            a 3-headed Animal...... the legendary Cerberus!”
        </p>
    </>,
    hordeDataCostume: 'Impressive costume',
    hordeNbArtists: '5 to 7 artists',

    // Lady & Gentle-leds
    ladyGentleTitle: ladyGentleTitle,
    ladyGentleMetaTitle: 'Lady & Gentle-leds',
    ladyGentleShortDescription: <>
        A comic and luminous trio!
        <br/>
        Put some elegance for your event.
    </>,
    ladyGentleDescription: <>
        <p>
            The Mag-pies, <span className='uk-text-bold'>2 miming gentlemen, sophisticated and charming</span>, <br/>
            accompanied by their radiant and dancing lady-dressed in red for love.
            <br/>
            They prance together with the sparkling energy of a thousand fires.
        </p>

        <p>
            Dressed in the most beautiful costumes of gold and silver, <br/>
            They will <span className='uk-text-bold'>welcome your guests</span>, <br/>
            <span className='uk-text-bold'>Invoking a friendly interaction</span> around them, as they promenade around on their stilts * <br/>
            They will give the utmost “height” of sophistication to your event!
        </p>

        <p>
            The “Lady and Gentle-leds” company will <span className='uk-text-bold'>spice up and amaze your guests</span> <br/>
            in perfect harmony and <span className='uk-text-bold'>sophistication</span>!!
        </p>

        <p>* According to demand.</p>
    </>,
    ladyGentleDataCostume: 'Luminous costume',
    ladyGentleNbArtists: '2 to 4 artists',

    // Light Me Up
    lightMeUpTitle: lightMeUpTitle,
    lightMeUpMetaTitle: 'Light Me Up',
    lightMeUpShortDescription: <>
        LEDs, lasers and a lot of joy <br/>
        For an ultra bright atmosphere
    </>,
    lightMeUpDescription: <>
        <p>
            Here you will meet a mixture of all our characters, more resplendent even than the others, <br/>
            AND tonight ... they are putting on their best illuminated outfits!
        </p>

        <p>
            Drowned in an intense layer of <span className='uk-text-bold'>LED and laser lights plus smoke effects <br/>
            our Robotic “breakdancers”, disjointed dolls, circus performers</span> on the ground, or on <span className='uk-text-bold'>stilts</span> <br/>
            will come to entertain you, illuminated and artistically!
        </p>

        <p>
            When <span className='uk-text-bold'>street art performances blend with the night world</span>, <br/>
            The stars are on track and will shine brilliantly like a thousand micro-fires!
        </p>

        <p>Let yourself be dazzled and ... let the party begin!!!</p>
    </>,
    lightMeUpDataCostume: 'LEDs and laser costume',
    lightMeUpNbArtist: '2 artists minimum',

    // Lutinery
    lutineryTitle: lutineryTitle,
    lutineryMetaTitle: 'Lutinery',
    lutineryShortDescription: <>
        A fantasy world awaits you,<br/>
        welcome our fantastic elves <br/>
        for a nice dose of enchantment
    </>,
    lutineryDescription: <>
        <p>
            Here are our <span className="uk-text-bold">prankster elves</span>, <span className="uk-text-bold">musicians</span>, <span
            className="uk-text-bold">troubadours</span>... <br/>
            always ready to give <span className="uk-text-bold">emotions and smiles</span> wherever they go!
        </p>
        <p>
            <span className="uk-text-bold">Their world is fantastic</span>: <br/>
            Between <span className="uk-text-bold">bubbles</span>, <span className="uk-text-bold">juggling </span>, and <span
            className="uk-text-bold">stilts</span>, dressed in their best clothes,<br/>
            they will know how to carry out your event and <span className='uk-text-bold'>entertain your audience</span>.
        </p>
        <p>
            Coming all from a different country, with their legends and their big noses,<br/>
            they will take you on an <span className="uk-text-bold">elegant and poetic wandering</span>, and all with <span className='uk-text-bold'>humor</span>.
        </p>
        <p>
            Don't wait any longer, they are here!<br/>
            Come closer!! Come closer!! <span className='uk-text-bold'>The Fairy is coming</span>!
        </p>
    </>,
    lutineryDataCostume: 'Fantastic costume',
    lutineryNbArtists: '3 to 7 artists',
    lutineryDataMusic: 'Musical with instruments and background music',

    // Les Momies
    momiesTitles: momiesTitleEN,
    momiesMetaTitle: 'Mummies',
    momiesShortDescription: <>
        Although their appearance is dusty take care,<br/>
        they have more than one trick under their strip...
    </>,
    momiesDescription: <>
        <p>
            Straight out of their tombs in the heart of Giza Pyramids, <br/>
            discover Cheops and Luxor, our two Mummies.
            <br/>
            To the jerky rhythm of Michael Jackson's Thriller, <br/>
            they will make you discover the <span className='uk-text-bold'>art of Mime, <br/>
            Contemporary Dance, Acrobatic Gestures</span> and <span className='uk-text-bold'>Contortion</span>.
        </p>

        <p>
            Funny, endearing and a bit creepy you can but love them !
            <br/>
            A <span className='uk-text-bold'>unique and atypical</span> circus show, very far from the stereotypes!
        </p>

        <p>
            A real treat for lovers of Ancient Egypt and sensational films.
            <br/>
            Look no further! Your mummies are here !
        </p>
    </>,
    momiesNbArtists: '2 artists',

    // L'Homme arc-en-ciel et ses anges
    rainbowManTitle: rainbowManTitleEN,
    rainbowManMetaTitle: "The Rainbow Man and his Angels",
    rainbowManShortDescription: <>
        Dressed in their 31, they arrive from the moon.
        <br/>
        to sublimate your party with color
    </>,
    rainbowManDescription: <>
        <p>
            The Rainbow-man, a colourful stilt-walker / jumper and dancer, <br/>
            <span className='uk-text-bold'>A feeling of gentleness, joy and well-being</span> will envelope you, when you get to know him. <br/>
            He will be accompanied by his benevolent but mysterious Angels <br/>
            who will bring you in a whirlwind of satin, <span className='uk-text-bold'>gentleness and purity</span>.
        </p>

        <p>
            <span className='uk-text-bold'>Close like Siamese twins, the Angels, a bubble-blower and a juggler</span>, <br/>
            and not forgetting the stilt-entertainer.
            <br/>
            Big angel wings, amid a <span className='uk-text-bold'>shower of rainbow bubbles</span>,
        </p>

        <p>
            Stroll with a <span className='uk-text-bold'>musical bubble wagon</span> pushed by their curious Cat'crobate, juggler and crazy at these hours.
            <br/>
            Ambling around with bubble-blowing and wonderful sound, <br/>
            a harmony of colors is offered to you.
        </p>
    </>,
    rainbowManDataCostume: 'Luminous costume',
    rainbowManNbArtists: '4 to 5 artists',

    // Le Rêve Blanc
    reveBlancTitle: reveBlancEN,
    reveBlancMetaTitle: 'The White Dream',
    reveBlancShortDescription: <>
        When white sublimates elegance,<br/>
        excellence is only to be found there.
    </>,
    reveBlancDescription: <>
        <p><span className='uk-text-bold'>Luxury and White</span> in all their splendour.</p>

        <p>
            The Marquise invites you to share her <span className='uk-text-bold'>elegant stroll</span>.
            <br/>
            Surrounded by extravagant characters, let her take you aboard the white dream.
            <br/>
            An immersion in their universe, between heights and juggling, <br/>
            <span className='uk-text-bold'>comedy and distinction</span>, they will communicate <span className='uk-text-bold'>energy and good humour</span> to
            you!
        </p>

        <p>
            Colourful protagonists, <span className='uk-text-bold'>majestic stilt-walkers</span>, they captivate the crowd.
            <br/>
            with their <span className='uk-text-bold'>scathing and delirious repartee</span>.
            <br/>
            Make way, the "white dream" comes to amaze young and old alike, <br/>
            in a <span className='uk-text-bold'>whirlwind of softness and elegance</span>.
        </p>
    </>,
    reveBlancDataCostume: 'Luminous costume',
    reveBlancNbArtists: '2 to 5 artists',

    // M'zelle Rosalie
    rosalieTitle: rosalieTitleEN,
    rosalieMetaTitle: "Miss Rosalie",
    rosalieShortDescription: <>
        Wacky wandering with our 3 friends ... <br/>
        Welcome to the 30's
    </>,
    rosalieDescription: <>
        <p>
            Straight out of the 30's movie studio, <br/>
            <span className='uk-text-bold'>The beautiful Rosalie is out</span>!!!
            <br/>
            She accompanies our 3 companions in the aisles of your event <br/>
            who will not hesitate to improvise <span className='uk-text-bold'>scenarios worthy of the greatest Hollywood films</span>.
            <br/>
            All this... <span className='uk-text-bold'>thanks to you</span>!
            <br/>
            The "car" will surprise and charm you with more than one of its <span className='uk-text-bold'>multiple surprises</span>.
            <br/>
            Hector, Oscar and Bidule have more than one trick.
            <br/>
            in their «Rosalie» and know how to make <span className='uk-text-bold'>fun for young and old</span>.
        </p>

        <p>
            A <span className='uk-text-bold'>wacky wander-around</span> dotted with impromptu interludes <br/>
            will entertain the crowd.
            <br/>
            3,2,1 .. Let's go!!!
        </p>


        <p className='uk-text-bold uk-text-italic'>
            In <Link href={path.partners} legacyBehavior><a className='uk-link-reset text-hover-gold'>partnership</a></Link>&nbsp; with the company "Beblibop"
        </p>
    </>,
    rosalieDataCostume: '',
    rosalieNbArtist: '3 artists',

    // Striptease / Effeuillage
    stripteaseTitle: stripteaseTitle,
    stripteaseMetaTitle: 'Striptease',
    stripteaseShortDescription: <>
        The art of stripping, sensual and sensational,<br/>
        for a memorable moment
    </>,
    stripteaseDescription: <>
        <p>
            Have you ever wondered - “<span className='uk-text-bold'>How can I boost my evening?</span>”
            <br/>
            You put on some music, enjoy a good drink with friends, play some game...
        </p>

        <p>And then... Do you feel that there is <span className='uk-text-bold'>some sparkle missing to make your guests groove</span>?</p>

        <p>
            It's time!!
            <br/>
            <span className='uk-text-bold'>Make way for deliciousness and sensuality</span>!
        </p>

        <p>
            Let yourself be absorbed by the charisma, the beauty and the sheer presence of our dancer artistes with dream bodies, without forgetting <span
            className='uk-text-bold'>that touch of madness that will surprise your guests</span>.
        </p>

        <p>
            It is with all these qualities that <span className='uk-text-bold'>our artists will spice up memories</span> of your birthday, bachelor/bachelorette
            party, or a simple evening with friends.
            <br/>
            Fire-eating, a contortionist act, pole dancing ... all of this can take place and that is why <span className='uk-text-bold'>the art of stripping is part of our show company</span>.
        </p>

        <p>
            <span className='uk-text-bold'>Festivities and a good atmosphere</span> will accompany the outfits and costumes that will make you fantasize!
            <br/>
            Don't wait any longer to <span className='uk-text-bold'>turn up the temperature of your evening</span>.
        </p>

        <p className='uk-text-bold uk-text-italic'>
            In <Link href={path.partners} legacyBehavior><a className='uk-link-reset text-hover-gold'>partnership</a></Link>&nbsp;
            with the companies "El Circus", "White Tigers" and Andy Klein
        </p>
    </>,
    stripteaseDataCostume: 'Luminous costume',
    stripteaseNbArtist: '1 artist minimum',

    // Tarzan, Jane & le Gorille
    tarzanTitle: tarzanTitleEN,
    tarzanMetaTitle: 'Tarzan, Jane et le Gorille',
    tarzanShortDescription: <>
        A cosplay that will delight all age groups, <br/>
        with a very offbeat humor
    </>,
    tarzanDescription: <>
        <p>
            Our “<span className='uk-text-bold'>Cosplay</span>” show, “Tarzan , Jane and Cheeta”, mixes aerial acts, <br/>
            acrobatic lifts with, of course, a comedian.
            <br/>
            A <span className='uk-text-bold'>good dose of comic repartee and the collaboration</span> <br/>
            of the three entertainers, Tarzan, Jane and Cheeta, take you and your guests <br/>
            on a trip down Memory Lane to the Cinema classic, Tarzan!
        </p>

        <p>The entertainers <span className='uk-text-bold'>will put your guests at ease</span> in the most relaxed yet stylish atmosphere.</p>

        <p>
            Ideal for: <br/>
            Receptions, “<span className='uk-text-bold'>Cinema</span>” <span className='uk-text-bold'>theme nights</span>, street performances for a festival,
            private parties.......
        </p>
    </>,
    tarzanDataCostume: '"Cosplay" costume',
    tarzanNbArtists: '3 artists',

    // Tim Burton
    timBurtonTitle: timTitleEN,
    timBurtonMetaTitle: 'Bim Turton is not far',
    timBurtonShortDescription: <>
    </>,
    timBurtonDescription: <>
        <p>
            Here is a <span className='uk-text-bold'>fantasy creation "Burtonesque"</span> mixing the art of stilts, <br/>
            juggling, lights and music for an <span className='uk-text-bold'>unforgettable moment</span>.
        </p>

        <p>
            Let yourself be guided by "Oscar" the stilt-walker, <br/>
            character with an <span className='uk-text-bold'>exuberant and hypnotic charm</span> accompanied by his sidekick Mister "Bim", the crazy juggler.
            <br/>
            Not forgetting the luminous Cyborg LEDs and lasers "Lx-1" illuminations, <br/>
            their <span className='uk-text-bold'>charismatic and eccentric dancer friend</span>.
        </p>

        <p>
            Ringgg!! rinnng!! ringgg!! ringgg!!
            <br/>
            The hour of the beautiful "Rose" is ringing!! An attractive doll, bewitching and gone crazy, <br/>
            <span className='uk-text-bold'>Will you be able to help her keep the rhythm going?</span>
            <br/>
            We have the solution ! "Mimo", our mime-musician will help you !! We promise!
        </p>

        <p>
            Moments of poetry, technical prowess and above all... <span className='uk-text-bold'>a magical universe</span>!
            <br/>
            Embark into this enchanted world!
        </p>
    </>,
    timBurtonDataCostume: 'LEDs and laser costume',
    timBurtonNbArtist: '2 to 5 artists',

    // Wadioui
    wadiouiTitle: wadiouiTitleEN,
    wadiouiMetaTitle: "Les WadiOui & the Gold and Silver Elfes",
    wadiouiShortDescription: <>
        This band of merry men <br/>
        will make you live the most magical of moments.
    </>,
    wadiouiDescription: <>
        <p>
            <span className='uk-text-bold'>Illuminated Elves</span>, Jumping stilt-walkers, Jugglers and Bubble-blowers <br/>
            Prance with their <span className='uk-text-bold'>sparkling energy of a thousand fires</span>.
        </p>

        <p>
            The “WadiOui Elves” are surprising “little people” who bring their <br/>
            <span className='uk-text-bold'>Colourful and Magical Universe</span> to the humdrum World of the human beings.
            <br/>
            Of all sizes, these mischievous, laughing beings will surprise you with <span className='uk-text-bold'>their good humour</span>.
            <br/>
            You will easily recognise them thanks to their little ears and their charming smiles.
        </p>

        <p>The snow flutters, the red waistcoats and white pompoms are part of it all.</p>

        <p>The “WadiOui Elves” will <span className='uk-text-bold'>amaze both Young and Old</span>!!!</p>
    </>,
    wadiouiDataCostume: 'Luminous costume',
    wadiouiDataMusic: 'With music',
    wadiouiNbArtists: '5 to 7 artists',

    // Wotan
    wotanTitle: wotanTitle,
    wotanMetaTitle: 'Wotan Project',
    wotanShortDescription: <>
        Immerse yourself in a retro universe <br/>
        and let yourself be carried away...by their bubbles...
    </>,
    wotanDescription: <>
        <p>
            A meeting of <span className='uk-text-bold'>2 clowns and 1 young lady linked by the circus domain</span> <br/>
            of stilts, mime, comedy and marionette-puppetry.
        </p>

        <p>
            Here is "Wotan Projet" <span className='uk-text-bold'>mobile sound show</span>.
            <br/>
            1 clown with stilts is controlling a human "marionette-puppet," with a wooden control-bar, <br/>
            who himself is holding his own marionette-puppet, <br/>
            Multiple levels for even more <span className='uk-text-bold'>pleasure and participation with the public</span>.
        </p>

        <p>
            Not forgetting Miss Mary who will give us a <span className='uk-text-bold'>multitude of bubbles</span> and who will give Mr Calisson, <br/>
            the clown on stilts, a hard time!
        </p>

        <p>
            So, don't wait any longer !
            <br/>
            <span className='uk-text-bold'>Poetry, smiles and emotions are all part of the fun !</span>
        </p>
    </>,
    wotanDataCostume: 'Steampunk costumes',
    wotanNbArtist: '2 to 3 artists',
}

export default enShows
