const enContact = {
    contactPageDesc: <>
        You need a concept, dup, trio or more...
        <br/>
        We are pleased to hear you, our characters can be mixed and played together,<br/>everything is prepared to make your event a success.
    </>,
    pressTitle: 'Press review',

    nameContact: 'Lastname Firstname',
    phoneContact: 'Phone',
    emailContact: 'Email',
    subjectContact: 'Subject',
    subjectSelectionContact: 'Please select the subject',
    informationSubjectContact: 'Information request',
    flyerSubjectContact: 'Brochure request',
    quotationSubjectContact: 'Get a quotation',
    bookingSubjectContact: 'Booking a show',
    messageSubjectContact: 'Message',

    contactRequiredFields: <>
        <span className='uk-text-bold text-gold'>*</span> indicates a required field
    </>,

    contactInputEmpty: 'Please fill the field ',
    contactScriptTagDetected: 'I saw that you used some JavaScript. It is bad, please do not retry it.',
    contactRequirementsFailed: "Please enter all fields correctly.",
    contactSuccess: 'The message has been set',
    contactSendFailed: "The message could not be send.",

    referenceTitle: 'References',
    referenceText: <>
        <p className='uk-text-center uk-text-bold'>
            Since its creation, in 2011, the troupe has had many opportunities to perform in many places.
            <br/>
            Here is a non-exhaustive list of locations or event among <br/>which we went to play our shows.
        </p>

        <p className='uk-width-1-2@m uk-margin-auto' style={{paddingBottom: '1em'}}>
            City of Fontaines-sur-Saône (Fantasti'Art), city of Mourenx, city of d'Orthez, city of Bordeaux, city of Saillagouse, city of Bourg Madame, city of
            de Narbonne, city of Pau, Brussels (Belgium), city of Monein, city of Gdansk and Warsaw (Poland), city of Toulouse, city of Narbonne, city of
            de Nice, city of Gueret, city of Rouen, city of Marseille, city of Bascons, city of Cannes, city of Perpignan, city of Saint-Cyprien, city of
            des Angles, city of Formigueres, city of Biguglia (Corsica), city of Quillan, L'Oréal, city of d'Estavar, city of Luc-sur-Orbier, city of Cadours,
            P2000 ski resort, "Bikini" concert hall of Toulouse, city of Creil, city of Lautrec, city of d'Albi, city of Moissac, city of
            Fleury d'Aude, city of Tarbes, city of Montfaucon, city of Salies-de-Béarn, city of Lisle-sur-Tarn, city of Paris, "Airbus" site in Toulouse, city of
            Muret, city of d'Aussonne...
        </p>
    </>,
}

export default enContact