const enTermOfUse = {
    termsOfUseTitle: 'Legal notice and privacy policy',
    termsOfUseMetaDescription: 'Legal notice and privacy policy of https://clownsdanslasciure.com website',

    termsOfUseText: <>
        <h2>Credits</h2>

        <p>
            Any professional, natural person or legal entity capable within the meaning of Articles 1123 et seq. of the Civil Code who visits the Site which is
            the subject of the present general conditions terms and conditions makes available to Clients :
        </p>
        <p>
            <span className='uk-text-bold'>Content</span>: All the elements constituting the information present on the Site, in particular texts, images and
            images and videos.
        </p>
        <p>
            <span className='uk-text-bold'>Customer Information</span>: Hereinafter referred to as "Information" which corresponds to all personal data
            personal data that may be held by the Clowns in the Sawdust Site for the management of the customer relationship for analysis purposes, statistical
            and marketing purposes.
        </p>

        <p>
            <span className='uk-text-bold'>User</span>: Internet user connecting, using the above-mentioned site.
        </p>

        <p>
            <span className='uk-text-bold'>Personal information</span>: "Information which allows, in any form whatsoever, directly or identification of the
            natural persons to whom they apply" (Article 4 of Law No. 78-17 of 6 January 1978).
        </p>

        <p>
            The terms "personal data", "data subject" and "sensitive data" have the meaning defined by the General Data Protection (GDPR: n°2016-679)
        </p>

        <h3>Website presentation</h3>

        <p>
            Pursuant to Article 6 of Law No. 2004-575 of 21 June 2004 on confidence in the digital economy, users of the
            internet <a href='https://clownsdanslasciure.com' rel='noopener noreferrer'>https://clownsdanslasciure.com</a> the identity of the various parties
            involved in its creation and monitoring:
        </p>

        <h4>Publisher</h4>
        <p>
            Clowns dans la sciure <br/>
            Email: <a href={`mailto:${process.env.NEXT_PUBLIC_CDLS_MAIL}`} className='uk-link-text'>{process.env.NEXT_PUBLIC_CDLS_MAIL}</a> <br/>
            Phone: <a href={`tel:${process.env.NEXT_PUBLIC_CDLS_PHONE}`}>{process.env.NEXT_PUBLIC_CDLS_PHONE}</a>
        </p>

        <h4>Publication director</h4>
        <p>
            Françoise N. Founding President of Clowns dans la Sciure
        </p>

        <h4>Design and production</h4>
        <p>
            Amandine TOURNAY <br/>
            <a href='https://www.linkedin.com/in/amandine-tournay/' target='_blank' rel='noopener noreferrer' className='uk-link-text uk-text-italic'>
                LinkedIn Page
            </a> <br/>
            Email: amandine.tournay@outlook.fr
        </p>

        <h4>Photo credits</h4>
        <p>
            Photo credits: © Clowns dans la sciure, unless otherwise indicated.
        </p>

        <h4>Web host</h4>
        <p>
            o2switch <br/>
            222-224 Boulevard Gustave Flaubert <br/>
            63000 Clermont-Ferrand <br/>
            Register of companies: 510 909 80700024 <br/>
            Phone: <a href="tel:+33444446040" className='uk-link-text'>+33 4 44 44 60 40</a>
        </p>

        <h2>Mentions Légales</h2>

        <h2>Legal Notices</h2>

        <h3>Terms and conditions of use of the site and the services offered</h3>

        <p>
            The Site constitutes a work of the mind protected by the provisions of the Intellectual Property Code and the International regulations
            regulations. The Client may not in any way reuse, transfer or exploit for his own account all or part of the elements or works of the
            Site.
        </p>
        <p>
            The use of the Site implies full acceptance of the general conditions of use described below. These conditions of use
            may be modified or supplemented at any time, and Users of the Site are therefore invited to consult them regularly.
        </p>
        <p>
            The Site constitutes an intellectual work protected by the provisions of the Intellectual Property Code and the applicable International
            Regulations.
            Regulations. The Client may not in any way reuse, transfer or exploit for his own account all or part of the elements or works of the
            Site.
        </p>
        <p>
            This website is normally accessible to users at all times. An interruption for technical maintenance may be decided by La Compagnie Clown
            decided by La Compagnie Clowns dans la sciure, which will then endeavour to inform users in advance of the dates and times of
            of the intervention.
            The website is regularly updated by the Company. In the same way, the legal notices may be modified at any time: they are nevertheless
            Nevertheless, they are binding on the user who is invited to refer to them as often as possible in order to read them.
        </p>

        <h3>Description of services provided</h3>

        <p>
            The purpose of the website is to provide information about all the company's activities. The Company Clowns dans la sciure
            strives to provide information on the Website that is as accurate as possible. However, it cannot be held responsible for omissions, inaccuracies
            inaccuracies and shortcomings in updating, whether caused by the company or by third-party partners who provide the information.
            information.
        </p>
        <p>
            All the information indicated on the Site is given as an indication, and is likely to change. Furthermore, the information
            on the Site is not exhaustive. It is given subject to modifications having been made since it was put online.
        </p>

        <h3>Contractual limitations on technical data</h3>

        <p>
            The website uses JavaScript technology. The website cannot be held responsible for any material damage related to the use of the Site. From
            Furthermore, the user of the site undertakes to access the Site using recent equipment, free of viruses and with a latest generation browser.
            The Site is hosted by a service provider in the European Union in accordance with the provisions of the General Data Protection Regulation (GDPR).
            General Data Protection Regulation (RGPD: n° 2016-679)
        </p>
        <p>
            The objective is to provide a service that ensures the best accessibility rate. The host ensures the continuity of its service 24 hours a day
            24 hours a day, every day of the year. However, it reserves the right to interrupt the hosting service for the shortest possible period of time
            for maintenance purposes, to improve its infrastructure, in the event of a failure of its infrastructure or if the Services generate traffic deemed
            to be
            and Services generate traffic deemed abnormal.
        </p>
        <p>
            The Site and the Host cannot be held responsible in the event of malfunction of the Internet network, telephone lines
            or computer and telephone equipment, particularly in the event of network congestion preventing access to the server.
        </p>

        <h3>Intellectual property and counterfeiting</h3>

        <p>
            The Clowns dans la sciure Troupe is the owner of the intellectual property rights and holds the rights of use on all the elements accessible on
            the website, including texts, images, graphics, logos, videos, icons and sounds. Any reproduction, representation, modification, publication,
            adaptation of all or part of the elements of the site, whatever the means or the process used, is prohibited, except with the prior prior written
            permission from the Troupe.
        </p>
        <p>
            Any unauthorised use of the site or of any of the elements it contains will be considered as constituting an infringement and will be prosecuted in
            accordance with the provisions of articles L.335-2 et seq. prosecuted in accordance with the provisions of articles L.335-2 and following of the
            Intellectual Property Act.
        </p>

        <p>
            Copyright © 2021 Clowns dans la sciure Troupe All rights reserved.
        </p>

        <h3>Limits of liability</h3>

        <p>
            The Clowns in Sawdust Company acts as the publisher of the site. The Content Manager is responsible for the quality and truthfulness of the Content
            he/she publishes.
        </p>
        <p>
            The Clowns dans la science Troupe cannot be held responsible for direct or indirect damage caused to the user's equipment when accessing the
            website, and resulting either from the use of equipment that does not meet the specifications in point "Contractual limitations on technical data",
            either the appearance of a bug or an incompatibility.
        </p>
        <p>
            The Clowns dans la sciure Troupe cannot also be held liable for indirect damage (such as, for example, a loss of market or
            loss of a chance) resulting from the use of the Site. Interactive spaces (possibility to ask questions in the space
            contact) are available to users. The Company reserves the right to remove, without prior notice, any content deposited in this space which would
            contravene the applicable law in France, in particular the provisions relating to the protection of data.
            Where applicable, the Troupe also reserves the right to question the civil and / or criminal liability of the user,
            in particular in the event of a racist, abusive, defamatory or pornographic message, whatever the medium used (text, photograph, etc.).
        </p>

        <h3>Personal data management</h3>

        <p>
            The Client is informed of the regulations concerning marketing communication, the law of 21 June 2014 for confidence in the Digital Economy, the
            Data Protection Act of 6 August 2004 and the General Data Protection Regulation (GDPR: n° 2016-679).
        </p>

        <h4>Persons in charge of the collection of personal data</h4>

        <p>
            For the Personal Data collected in the context on the navigation on the Website, the responsible for the processing of Personal Data is: Françoise N.
            The Clowns dans la sciure Troupe is represented by Françoise N. its legal representative.
        </p>
        <p>
            As the person responsible for processing the data it collects, the Company undertakes to respect the framework of the legal provisions in force. In
            particular, it is the Client's responsibility to establish the purposes of its data processing, to provide its prospects and clients, from the
            collection of their consents, with complete information on the processing of their personal data and to maintain a register of the processing
            operations. Whenever the Troupe processes Personal Data, it takes all reasonable steps to ensure that the Personal Data is accurate and relevant to
            the purposes for which it is processed.
        </p>

        <h4>Purpose of the data collected</h4>

        <p>
            The Clowns dans la sciure Troupe is likely to process all or part of the data :

            <ol>
                <li>To conduct communication campaigns (email): email address</li>
                .
                <li>To recontact the Client (SMS, call, email): phone number, email address</li>
            </ol>
        </p>
        <p>
            The Troupe does not market your personal data which is therefore only used by necessity or for statistical and analysis purposes.
        </p>

        <h4>Access Rights, rectification and opposition</h4>

        <p>
            In accordance with current European regulations, Users of the Site have the following rights:
            <ol>
                <li>
                    Right of access (Article 15 GDPR) and rectification (Article 16 GDPR), update, completeness of Users' data right to
                    blocking or deletion of Users' personal data (Article 17 GDPR), when they are inaccurate,
                    incomplete, equivocal, outdated, or whose collection, use, communication or storage is prohibited;
                </li>
                <li>
                    Right to withdraw consent at any time (Article 13-2c GDPR);
                </li>
                <li>
                    Right to limit the processing of Users' data (Article 18 GDPR);
                </li>
                <li>
                    Right to object to the processing of Users' data (Article 21 GDPR);
                </li>
                <li>
                    Right to portability of data provided by Users, where such data are subject to automated processing based
                    based on their consent or on a contract (Article 20 GDPR);
                </li>
                <li>
                    Right to define the fate of Users' data after their death and to choose to whom the Company shall communicate (or not) their
                    data to a third party that they have previously designated.
                </li>
            </ol>
        </p>
        <p>
            As soon as the Troupe becomes aware of the death of a User and in the absence of instructions from the User, the Troupe undertakes to destroy the
            User's data, except if their retention is necessary for evidential purposes or to meet a legal obligation.
        </p>
        <p>
            If the User wishes to know how the Troupe uses his Personal Data, to ask to rectify them or to oppose their
            If the User wishes to know how the Troupe uses his/her Personal Data, to ask to rectify them or to oppose their processing, the User may contact
            the Troupe in writing at the following address:
            <br/>
            Clowns dans la sciure <br/>
            1, chemin du Luquet <br/>
            11800 Marseillette <br/>

            Or by electronic means :
            <br/>
            <a href="mailto:clownsdanslasciure@gmail.com" className='uk-link-text'>clownsdanslasciure@gmail.com</a>
        </p>
        <p>
            In this case, the User must indicate the Personal Data that they would like the Troupe to correct, update or delete,
            identifying himself/herself precisely with a copy of an identity document (identity card, passport or driving licence).
        </p>
        <p>
            Requests for deletion of Personal Data will be subject to the obligations that are imposed on the Troupe by law,
            in particular with regard to the conservation or archiving of documents. Finally, Users of the Site may file a complaint
            to the supervisory authorities, and in particular to the CNIL (
            <a href='https://www.cnil.fr/fr/plaintes' target='_blank' rel='noopener noreferrer' className='uk-link'> https://www.cnil.fr/fr/plaintes</a>).
        </p>

        <h4>Non-disclosure of personal data</h4>

        <p>
            The Troupe will not process, host or transfer the Information collected on its Customers to a country outside the European Union or it
            will not process, host or transfer Customer Information to a country outside the European Union or recognized as "non-adequate" by the European
            Commission without prior notice to the Customer. However, it remains free to choose its technical and commercial subcontractors provided that they
            present sufficient guarantees with regard to the requirements of the General Data Protection Regulation (GDPR: n° 2016-679)
        </p>
        <p>
            The Troupe undertakes to take all necessary precautions to preserve the security of the Information and in particular
            that it is not communicated to unauthorized persons. However, if an incident affecting the integrity or confidentiality of the Customer's
            information of the Customer is brought to the attention of the Responsible Entity, the latter shall inform the Customer as soon as possible and
            communicate the corrective measures taken. Moreover, the Site does not collect any "sensitive data".
        </p>
        <p>
            Within the limits of their respective attributions and for the purposes mentioned above, the main persons likely to have access to the data of the
            Users of the Site are data of the Users of the Site are mainly the managers of the Troupe.
        </p>

        <h3>Incident notification</h3>

        <p>
            No matter how hard you try, no method of transmission over the Internet and no method of electronic storage is completely secure. secure. Therefore,
            we cannot guarantee absolute security. If we become aware of a security breach, we will notify the users concerned affected users so that they can
            take appropriate action. Our incident notification procedures take into account our legal obligations, whether legal obligations, whether at
            national or European level. We are committed to fully informing our customers of all We are committed to keeping our clients fully informed of all
            matters relating to the security of their account and to providing them with all the information necessary to help them meet their own regulatory
            reporting obligations. regulatory reporting requirements.
        </p>
        <p>
            No personal information of the user of the Site is published without the user's knowledge, exchanged, transferred, ceded or sold on any medium to
            third parties. to third parties. Only the hypothesis of the repurchase of the Troupe and its rights would allow the transmission of the
            aforementioned information to the eventual purchaser who would be in turn held of the same obligation of conservation and modification of the data
            with respect to the user of the website.
        </p>

        <p className='uk-text-bold'>Security</p>
        <p>
            When processing Personal Data, the Company takes all reasonable steps to protect it from loss, misuse, unauthorized access, disclosure, alteration
            or destruction. loss, misuse, unauthorized access, disclosure, alteration or destruction.
        </p>

        <h3>Hypertext links, cookies and internet tags</h3>
        <p>
            The Site contains a certain number of hypertext links to other sites, set up with the authorization of the Troupe. However, the Troupe
            does not have the possibility to check the content of the sites thus visited, and consequently will not assume any responsibility for this fact.
        </p>
        <p>
            Unless you choose to disable cookies, you agree that the site may use them. You can deactivate these cookies at any time and free of charge free of
            charge from the deactivation options that are offered to you and recalled below, knowing that this may reduce or prevent accessibility to all or
            part of the Services offered by the Site.
        </p>

        <h4>"COOKIES"</h4>
        <p>
            A "cookie" is a small information file sent to the User's browser and stored in the User's terminal (e.g. computer, smartphone), (hereinafter
            "Cookies"): computer, smartphone), (hereinafter "Cookies"). This file includes information such as the User's domain name, the User's Internet
            service provider and the Internet service provider, the User's operating system, and the date and time of access. Cookies will not in any way damage
            the User's terminal.
        </p>
        <p>
            The Troupe may process information about the User's visit to the Site, such as the pages consulted, the searches made consulted, searches made.
            This information allows the Troupe to improve the content of the Site and the User's navigation.
        </p>
        <p>
            Cookies facilitate navigation and/or the provision of services offered by the Site, the User can configure his browser so that it allow him to
            decide whether or not to accept them so that Cookies are stored in the terminal or, on the contrary, that they are rejected, either systematically
            or according to their sender, that they are rejected, either systematically or according to their sender. The User may also configure his or her
            browser software so that the acceptance or rejection of that the acceptance or refusal of Cookies is proposed to him punctually, before a Cookie is
            likely to be recorded in his terminal. in his terminal. The Site informs the User that, in this case, it is possible that the functionalities of his
            or her browsing software may not be available.
        </p>
        <p>
            If the User refuses to accept the storage of cookies in his terminal or browser, or if the User deletes the cookies stored there the User is
            informed that his/her navigation and experience on the Site may be limited. This could also be the case when the Company or one of its service
            providers cannot recognize, for technical compatibility purposes, the type of browser used by the terminal, the language and display settings or the
            country from which the terminal seems to be connected to the Internet.
        </p>
        <p>
            Where applicable, the Troupe declines all responsibility for the consequences related to the degraded functioning of the Site and the services
            services offered by the Troupe, resulting from the refusal of Cookies by the User or the impossibility for the for the Company to record or consult
            the Cookies necessary for their operation due to the User's choice. For the management of Cookies and the User's choices, the configuration of each
            browser is different. It is described in the menu It is described in the browser's help menu, which will show how the User can modify his or her
            wishes regarding Cookies.
        </p>
        <p>
            At any time, the User may choose to express and modify his or her wishes regarding Cookies. The Troupe may use the services of external service
            providers to help it collect and process the information described in this section.
        </p>
        <p>
            Finally, by clicking on the icons dedicated to the social networks Instagram, Facebook and Youtube on the Website and if the User has accepted the
            deposit of cookies by continuing to browse the Website, Intagram, Facebook and Youtube may also deposit cookies on your terminals (computer, tablet,
            cell phone).
        </p>
        <p>
            These types of cookies are deposited on your terminals only if you consent to them, by continuing your navigation on the Site. At any However, at
            any time, the User may revoke his or her consent to the Site depositing this type of cookie.
        </p>

        <h4>Internet tags</h4>

        <p>
            The Site may occasionally employ web beacons (also known as "tags," action tags, single-pixel GIFs, clear GIFs, invisible GIFs, and one-to-one GIFs)
            and deploy them through a web analytics partner who may be able to provide you with information about the Site. GIFs, clear GIFs, invisible GIFs,
            and one-to-one GIFs) and deploy them through a web analytics partner that may be located (and therefore store information, including the IP address,
            of the user) on the Site. may be located (and therefore store the related information, including the User's IP address) in a foreign country.
        </p>
        <p>
            These tags are placed both in the online advertisements allowing Internet users to access the Site, and on the various pages of the Site. pages of
            the Site.
        </p>
        <p>
            This technology allows the Troupe to evaluate visitors' responses to the Site and the effectiveness of its actions (for example, the number of
            times a page is opened and the information viewed), as well as the User's use of the Site. for example, the number of times a page is opened and the
            information consulted), as well as the use of this Site by the User.
        </p>
        <p>
            The external service provider may collect information about visitors to the Site and other websites through these tags, compile reports on Site
            activity for the Troupe, and provide other services related to the use of the Site and the Internet. the use of the Site and the Internet.
        </p>

        <h3>Applicable law and jurisdiction</h3>
        <p>
            Any dispute arising from the use of the Clowns dans la sciure Troupe website is subject to French law. Except in cases where the law does not
            allow it, the competent courts of Carcassonne have exclusive jurisdiction.
        </p>
    </>
}

export default enTermOfUse