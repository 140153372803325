const esMaintenance = {
    maintenanceMetaTitle: 'Clowns dans la sciure - Maintenance',
    maintenanceMetaDescription: '¡El sitio web de Clowns in the Sawdust está actualmente en mantenimiento, pero volverá pronto!',

    loginText: 'Entrar en el sistema',
    closeText: 'Cerrar',

    loginInput: 'Identificador',
    passwordInput: 'Contraseña',

    loginError: <>Nombre de usuario o contraseña incorrectos</>,
    loginEmpty: 'Por favor, rellene todos los campos',

    maintenanceMessage: 'El sitio está actualmente en mantenimiento.',
    maintenanceWaiting: '¡Volveremos para traerte aún más diversión!',
    maintenanceSocialMedia: 'Mientras tanto, ¡encuéntrenos en nuestras redes sociales!',

    maintenanceDaysLeft: 'Días',
    maintenanceHoursLeft: 'Horas',
    maintenanceMinutesLeft: 'Actas',
    maintenanceSecondsLeft: 'Segundos',
}

export default esMaintenance