import frError404 from "./texts/404"
import frArticles from "./texts/articles"
import frContact from "./texts/contactText"
import frCookieDisabled from "./texts/cookieDisabled"
import frFooter from "./texts/footerText"
import frHeader from "./texts/headerText"
import frHome from "./texts/homeText"
import frHomeCarousel from "./texts/homeCarouselText"
import frMaintenance from "./texts/maintenance"
import frPartners from "./texts/partnersText"
import frShows from "./texts/showsText"
import frTermsOfUse from "./texts/termOfuseText"
import frTroupe from "./texts/troupeText"
import frWorkshop from "./texts/workshopText"

const fr = {
    moreDetails: 'En savoir plus',
    readMore: 'Lire',
    emptyList: "Il n'y a rien ici pour le moment",
    send: 'Envoyer',
    playVideo: 'Voir la vidéo',
    linkOpenImage: "Lien: Ouvrir l'image",

    ...frMaintenance,
    ...frCookieDisabled,

    ...frHeader,
    ...frFooter,

    ...frHome,
    ...frHomeCarousel,

    ...frShows,
    ...frWorkshop,

    ...frTroupe,

    ...frContact,

    ...frArticles,
    ...frPartners,
    ...frTermsOfUse,

    ...frError404
}

export default fr