import Link from "next/link"
import {GetSVG} from "../../../providers/Language"
import path from '../../../contents/paths'

import beblibop from 'img/partners/troupes/logo_beblibop.png'
import elCircus from 'img/partners/troupes/el_circus.png'
import whiteTigers from 'img/partners/troupes/white_tiger_logo.png'

const esPartners = {
    partnersTitle: 'Partenaires',
    partnerDescription: <>
        <h3 className='uk-text-bold'>Conviértete en un partenaire</h3>

        <p>
            Ser socio de &quot;Clowns dans la Sciure&quot; significa asociar la imagen de su empresa con la nuestra en el sector de las artes escénicas de la
            región y
            contribuir a la influencia cultural de Midi-Pyrénées y Languedoc-Roussillon.
        </p>
        <p>
            Ser socio de la Compagnie Clowns dans la Sciure significa beneficiarse de una relación privilegiada y de varias ventajas:
        </p>

        <ul>
            <li>Invitaciones a espectáculos para sus clientes, relaciones comerciales, colaboradores, etc ...,</li>
            <li>Trabajar mano a mano para satisfacer a los organizadores y por supuesto al público</li>
            <li>Descubrimiento de la temporada en anticipado y acceso prioritario a vuestros artistas en los espectáculos</li>
        </ul>

        <h3 className='uk-text-bold'>Compañías partenaires</h3>

        <ul>
            <li>
                <p>
                    Compañía <a href='https://el-circus.fr/' target="_blank" rel='noopener noreferrer' className='uk-link-text uk-text-italic text-hover-gold'>
                    El Circus
                </a>&nbsp;
                    (Circo, strip, intérprete, mascota)
                    <br/>
                    Sección de espectáculos <Link href={path.striptease} className='text-black text-hover-gold'>
                    <GetSVG id={'stripteaseTitle'} classes='uk-width-small'/>
                </Link>
                    <br/>
                    <a href='https://el-circus.fr/' target="_blank" rel='noopener noreferrer' className='uk-text-italic text-hover-gold'>
                        Sitio web
                    </a>
                </p>
                <img src={elCircus} alt={elCircus.text} loading='lazy' className='uk-width-medium' data-uk-img=''
                     onDragStart={(event => event.preventDefault())}/>
            </li>
            <li>
                <p>
                    Compañía Beblibop (Circo)
                    <br/>
                    Sección espectáculos
                    <Link href={path.clowns} className='text-black text-hover-gold'>
                        <GetSVG id={'clownsTitle'} classes='uk-width-small'/>
                    </Link>
                    y
                    <Link href={path.rosalie} className='text-black text-hover-gold'>
                        <GetSVG id={'rosalieTitle'} classes='uk-width-small'/>
                    </Link>
                </p>
                <img src={beblibop} alt={beblibop.text} loading='lazy' className='uk-width-small' data-uk-img=''
                     onDragStart={(event => event.preventDefault())}/>
            </li>
            <li>
                <p>
                    Andy Klein (Strip)<br/>
                    Sección de espectáculos
                    <Link href={path.striptease} className='text-black text-hover-gold'>
                        <GetSVG id={'stripteaseTitle'} classes='uk-width-small'/>
                    </Link>
                </p>
            </li>
            <li>
                <p>
                    White-Tigers Performance (circo, strip, artista)
                    <br/>
                    Sección de espectáculos Striptease
                    <Link href={path.striptease} className='text-black text-hover-gold'>
                        <GetSVG id={'stripteaseTitle'} classes='uk-width-small'/>
                    </Link>
                </p>
                <img src={whiteTigers} alt={whiteTigers.text} loading='lazy' className='uk-width-small' data-uk-img=''
                     onDragStart={(event => event.preventDefault())}/>
            </li>
        </ul>
    </>,
    partnersSlogan: "Únase a nosotros y trabaje de la mano. ¡Hagamos equipo por nuestro público!"
}

export default esPartners