import Link from 'next/link'

import path from '../../../contents/paths'

import alienTitle from 'img/general/svg/shows/Alien.svg'
import augustinTitleFR from 'img/general/svg/shows/Augustin_FR.svg'
import blackPantherTitle from 'img/general/svg/shows/Black.svg'
import bondyTitle from 'img/general/svg/shows/Bondy.svg'
import calissonTitle from 'img/general/svg/shows/Clowns.svg'
import diaDeLosMuertosTitle from 'img/general/svg/shows/Alejandro.svg'
import fireTitle from 'img/general/svg/shows/Fire.svg'
import hordeTitleFR from 'img/general/svg/shows/La_Horde_FR.svg'
import ladyGentleTitle from 'img/general/svg/shows/Lady.svg'
import lightMeUpTitle from 'img/general/svg/shows/Light_Me_Up.svg'
import lutineryTitle from 'img/general/svg/shows/Lutinery_FR.svg'
import momiesTitleFR from 'img/general/svg/shows/Momies_FR.svg'
import pyrotechnicsShowTitleFR from 'img/general/svg/shows/PyrotechnicsShow_FR.svg'
import rainbowManTitleFR from 'img/general/svg/shows/L_homme_FR.svg'
import reveBlancFR from 'img/general/svg/shows/Le_REVE_FR.svg'
import rosalieTitleFR from 'img/general/svg/shows/Rosalie_FR.svg'
import stripteaseTitle from 'img/general/svg/shows/Striptaese.svg'
import tarzanTitleFR from 'img/general/svg/shows/Tarzan_FR.svg'
import timTitleFR from 'img/general/svg/shows/Tim_FR.svg'
import wadiouiTitleFR from 'img/general/svg/shows/WadOuiFR.svg'
import wotanTitle from 'img/general/svg/shows/Wotan.svg'

const frShows = {
    // General
    allShowsTitle: 'Tous les spectacles',
    allShowsMetaDescription: 'Liste de tous les spectacles proposés par la compagnie Clowns dans la sciure',

    newShowsTitle: 'Nouveautés',

    eventMetaDescription: 'Un événement à organiser ? Venez voir ce que nous proposons comme spectacles !',
    themeMetaDescription: "Nous vous proposons une grande variété d'univers pouvant s'accorder à n'importe quelle occasion !",
    activitiesMetaDescription: "Vous souhaitez de l'interaction avec le public ou organiser des ateliers ? C'est ici !",

    videoShow: 'Vidéo',
    videoShowDescription: 'Choisissez la vidéo !',
    galleryShow: 'Galerie photos',

    eventFileTitle: "Créer l'évènement qui vous ressemble !",
    fileClickMessage: "Cliquez sur l'image pour voir l'aperçu",
    askBrochure: 'Demande de plaquettes / brochures',
    askQuote: 'Demander un devis',

    prevShow: 'Spectacle précédent',
    nextShow: 'Spectacle suivant',

    showDataTechnics: 'Techniquement autonome',
    showPyrotechnicsAvailable: 'Option pyrotechnie',
    showDurationVariable: 'Variable',
    showPositionAmbulatory: 'Déambulatoire avec saynètes fixes',
    showStiltAvailable: 'Au sol ou en échasses',
    showSantaAvailable: 'Option Père Noël',
    showFireAvailable: 'Feu',
    showFireOption: 'Option Feu',
    showFloatAvailable: 'Char',
    showMusicAvailable: 'Musical (sonorisé)',

    // Alien & Ripley
    alienRipleyTitle: alienTitle,
    alienRipleyMetaTitle: 'Alien and Ripley',
    alienRipleyShortDescription: <>
        Embarquez pour de Grands Frissons <br/>dans cet univers de Science-Fiction!
    </>,
    alienRipleyDescription: <>
        <p>
            Depuis le Film Culte <span className='uk-text-italic'>Alien « le 8ème Passager »</span>, embarquez avec nous à bord du Nostromo,<br/>
            pour un <span className='uk-text-bold'>show unique mêlant du Feu et de la Pyrotechnie</span>, dans l'Univers fantastique <br/>
            et incontournable du grand <span className='uk-text-bold'>Maître Giger !</span>
        </p>

        <p>
            Notre Alien est une pièce unique, cet exosquelette se déplace, bave, ouvre la gueule, <span
            className='uk-text-bold'>une vraie performance</span>.. <br/>
            sublimée par une <span className='uk-text-bold'>cracheuse de Feu avec bien sûr, « Ripley »</span> tout droit sortie des années fin 70 !
        </p>

        <p>
            Voici une déambulation et un cosplay qui saura ravir toutes tranches d'âges !!
            <br/>
            Souvenir, découverte pour les plus jeunes mais le tout sans peur grâce à nos talents de comédien.
        </p>

        <p>
            Spectacle déambulatoire "Cosplay" diurne ou nocturne.
            <br/>
            Réservez dès maintenant pour un <span className='uk-text-bold'>SHOW EXCEPTIONNEL</span> !!
        </p>
    </>,
    alienRipleyTechnics: 'Autonome si 2 artistes présents',
    alienRipleyDataCostume: 'Costume impressionnant',
    alienRipleyNbArtists: '1 à 2 artistes',

    // Augustin
    augustinTitle: augustinTitleFR,
    augustinMetaTitle: "Augustin l'Épouvantail",
    augustinShortDescription: <>
        Déambulation d'un personnage atypique <br/>mélangeant curiosité et poésie.
    </>,
    augustinDescription: <>
        <p>Personnage fantastique déambulatoire sonore au sol ou en échasse.</p>

        <p>Vous voulez une <span className='uk-text-bold'>ambiance feutrée et magique ?</span></p>

        <p>
            <span className='uk-text-bold'>Impact à l'environnement</span> : éco-conception et écologique car conçu à la main quasiment sans assistance
            électrique (moins de 10 % sur l'ensemble du personnage) <br/>
            dans l'ambiance de <span className='uk-text-bold'>Mr Tim Burton, des contes de Grimm et de Perrault</span>...
        </p>

        <p>
            « Augustin » est un personnage attachant <span
            className='uk-text-bold'>faisant apparaître des bonbons tout en magie par-ci par-là dans son chapeau</span> <br/>
            et <span className='uk-text-bold'>avec une joie de vivre hors normes</span>...
            <br/>
            Descendu de sa planète musicale pour arriver dans le monde des humains, cet épouvantail saura faire vibrer petits et grands par son humour et sa
            gentillesse.
        </p>

        <p>
            Vous ne voudrez plus qu'il retourne sur sa planète...
            <br/>
            On fait le test ?
        </p>
    </>,
    augustinDataCostume: 'Costume impressionnant',
    augustinDataMusic: 'musique au sol electro à partir de 3 artistes',
    augustinNbArtist: '1 artiste',

    // Black Panther
    blackPantherTitle: blackPantherTitle,
    blackPantherMetaTitle: 'Black Panther',
    blackPantherShortDescription: <>
        Un Cosplay évoluant tel un félin <br/>de table en table.
    </>,
    blackPantherDescription: <>
        <p>
            Voici le <span className='uk-text-bold'>cosplay de « T'Challa »</span>, alias la Panthère noire,
            <br/>
            personnage de fiction emblématique de l'<span className="uk-text-bold">univers "<span className='uk-text-italic'>Marvel Comics</span>"</span>,
        </p>

        <p>
            Que vous faudrait il pour votre événement "Cinéma" ? <span className='uk-text-bold'>Un super-héros acrobate</span> aimé
            <br/>
            de tous avec une allure digne d'un félin ?
            <br/>
            Ne cherchez plus, nous l'avons !!
        </p>

        <p>
            Plus qu'un costume, « <span className='uk-text-italic'>Black Panther</span> » saura se faufiler et donner des émotions à votre public
            <br/>
            aussi bien par ses prouesses techniques que par sa gentillesse.
        </p>

        <p>
            Entré dans la pop-culture depuis la sortie du film, <br/>
            De nombreux fans souhaitent être pris en photos par le <span className='uk-text-bold'>célèbre "<span className='uk-text-italic'>Black panther</span>"</span>, <br/>
            le vœu peut se réaliser au royaume de Wakanda ou... chez vous !! <br/>
            Il est maintenant ICI pour ravir et inspirer petits et grands <br/>
        </p>
    </>,
    blackPantherDataCostume: 'Cosplay',
    blackPantherNbArtist: '1 artiste',

    // Bondy & Maya
    bondyMaiaTitle: bondyTitle,
    bondyMetaTitle: 'Bondy & Maïa',
    bondyMayaShortDescription: <>
        Retrouvez notre duo taquin <br/>dans l'univers du BDSM.
    </>,
    bondyMayaDescription: <>
        <p>Oubliez vos préjugés, vos convictions !!</p>

        <p>
            Venez découvrir un monde où cravache et caresses se mélangent.
            <br/>
            Où peau et chaînes s'entrechoquent pour que le plaisir et les cordes "<span className='uk-text-bold'>Shibari</span>", <br/>
            s'unissent sous le regard de votre public.
        </p>

        <p>
            Au sol, moulée de <span className='uk-text-bold'>latex et de vinyle</span>, la belle "Maïa" ne se laissera pas faire !!
            <br/>
            C'est elle, qui soumet l'échassier "Bondie" à ses plus folles envies.
            <br/>
            Le tout !! avec <span className='uk-text-bold'>un amour fou</span>...
        </p>

        <p>
            Immergez-vous avec eux dans l'<span className='uk-text-bold'>univers du BDSM</span> :
            <br/>
            Bondage, Domination et Soumission...
            <br/>
            Il y a forcément une discipline qui <span className='uk-text-bold'>réveillera vos fantasmes</span>.
            <br/>
            Prêt à jouer ? C'est Maintenant !!
        </p>
    </>,
    bondyMayaDataCostume: 'Costume latex et vinyl',
    bondyMayaNbArtist: '2 artistes',

    // Clowns
    clownsTitle: calissonTitle,
    clownsMetaTitle: 'Clowns',
    clownsShortDescription: <>
        Indémodable, <br/>remplis d'humour et de surprises, <br/>pour raviver les émotions de tous
    </>,
    clownsDescription: <>
        <p>Quel personnage saura vous donner le <span className='uk-text-bold'>maximum d'émotions</span> ?</p>

        <p>
            Oui !! C'est bien le clown, la Compagnie vous propose des <span
            className='uk-text-bold'>artistes haut en couleurs pour une bonne dose d'humour</span>.
            <br/>
            Dans le rien, il y a un tout !
            <br/>
            Dans l'infiniment petit, nous avons un <span className='uk-text-bold'>océan de bonne humeur</span>... et <span className='uk-text-bold'>nous saurons bien vous le montrer</span>.
        </p>

        <p>
            Au sol, en échasse, en roller, en monocycle ou en moto, le clown comme vous le savez, sait tout faire !
            <br/>
            Il peut être jongleur, acrobate ou danseur, ce qui est sûr! Son art saura vous <span className='uk-text-bold'>procurer de belles sensations</span>.
            <br/>
            Vous souhaitez <span className='uk-text-bold'>faire plaisir à vos invités de tout âge</span>, c'est le bon choix !
        </p>

        <p><span className='uk-text-bold'>Tendresse et fous rires</span>, nos clowns vous font la <span className='uk-text-bold'>révérence du bonheur</span> !
        </p>
    </>,
    clownsDataCostume: 'Costume Rigolo',
    clownsNbArtist: '1 à 2 artistes',

    // Cracheur de feu et pyrotechnie
    pyrotechnicsShowTitle: pyrotechnicsShowTitleFR,
    pyrotechnicsShowMetaTitle: 'Cracheur de Feu & Jongleur Pyrotechnie',
    pyrotechnicsShowShortDescription: <>
        Pyrotechnie, porté acrobatique <br/>et cracheur de feu... tout y est !
    </>,
    pyrotechnicsShowDescription: <>
        <p>
            Veuillez découvrir cette « Unité » exceptionnel !
            <br/>
            <span className='uk-text-bold'>D'apparence classe</span> et <span className='uk-text-bold'>raffinée</span>, je peux vous assurer qu'ils ne font pas
            dans la dentelle !
            <br/>
            Nous avons nos « artistes du feu », tous des <span className='uk-text-bold'>jongleurs et manipulateurs prodigieux</span>, <br/>
            sachant manier leurs instruments enflammés avec une aisance inégalée.
        </p>

        <p>
            Vous l'aurez remarqués, il manque quelqu’un !
            <br/>
            La belle aussi est de la fête, avouez le, sa <span className='uk-text-bold'>beauté flamboyante</span> <br/>
            et son regard de braise vous ont déjà conquis !
        </p>

        <p>
            Laissez-votre cœur d'enfant prendre le relais à la vue de ces flammes gigantesques, <br/>
            de ces <span className='uk-text-bold'>artifices scintillants et de ces portés acrobatiques</span> que nous vous proposerons !
            <br/>
            Le tout en sécurité !! 3...,2...,1 !!
            <br/>
            Nos « burners » vont vous réchauffer !
        </p>
    </>,
    pyrotechnicsNbArtist: '2 à 5 artistes',

    // Dia de los Muertos
    diaDeLosMuertosTitle: diaDeLosMuertosTitle,
    diaDeLosMuertosMetaTitle: 'Alejandro & Gabriella - Dia de los Muertos',
    diaDeLosMuertosShortDescription: <>
        Un parfum d'élégance dans cette journée <br/>de la fête des morts.
    </>,
    diaDeLosMuertosDescription: <>
        <p>
            Revenu d'entre les morts, vous êtes invité à rejoindre le monde coloré <br/>
            de ce charmant couple de défunt.
            <br/>
            <span className='uk-text-bold'>Pouvant se manifester au sol ou en échasse</span>, <br/>
            ces amants vous envoûteront par leur présence incontournable.
        </p>

        <p>
            Ces deux personnages adorent attirer l'attention, et pour l'occasion, <br/>
            ils ont revêtu leurs plus beaux apparats et vous réservent <span className='uk-text-bold'>leurs danses les plus ensorcelantes</span> !
            <br/>
            Issus du milieu de la « Royauté Funèbre », leurs attitudes princières <br/>
            ajouteront une <span className='uk-text-bold'>touche très classe à votre événement</span> !
        </p>

        <p>
            Plus la peine de chercher ! Alejandro et Gabriella sont déjà là...!
            <br/>
            Que la fête commence !
        </p>
    </>,
    diaDeLosMuertosDataCostume: 'Costume lumineux',
    diaDeLosMuertosNbArtists: '2 artistes',

    // Fire's Coal
    fireCoalTitle: fireTitle,
    fireCoalMetaTitle: "Fire's Coal",
    fireCoalShortDescription: <>
        Cette famille aux allures victoriennes <br/>saura raviver la flamme <br/>de nos feux de joie.
    </>,
    fireCoalDescription: <>
        <p>
            Imaginez-vous dans un <span className='uk-text-bold'>univers victorien et de science-fiction</span>, <br/>
            entre H.G Welles et Jules Verne, mais pas que...
            <br/>
            On y rajoute le <span className='uk-text-bold'>charme Anglais avec ses costumes « rétros » chics et élégants</span>,
            <br/>
            du feu, des bulles et de la vapeur sur notre char...
        </p>

        <p>
            Nous voilà au cœur d'un cocktail explosif qui vous transportera <br/>
            dans un <span className='uk-text-bold'>spectacle fantastique et décoiffant</span>.
        </p>

        <p>
            Le tout adapté aux petits et grands en totale sécurité.
            <br/>
            Les artistes sauront vous <span className='uk-text-bold'>émerveiller par leurs prouesses d'échasses</span>, <br/>
            <span className='uk-text-bold'>pyrotechnie, jongleur et cracheur de feu</span>.
            <br/>
            Laissez-vous embarquer dans notre univers « Steampunk » fantastique.
        </p>
    </>,
    fireCoalDataCostume: 'Costume univers steampunk',
    fireCoalNbArtists: '4 à 6 artistes',

    // La Horde
    hordeTitle: hordeTitleFR,
    hordeMetaTitle: 'La Horde',
    hordeShortDescription: <>
        Un univers mythologique fantastique <br/>de créatures tout droit venues <br/>du grand nord
    </>,
    hordeDescription: <>
        <p>
            Rusés et reconnaissables parmi toute une tribu, <br/>
            Ces <span className='uk-text-bold'>créatures légendaires</span> se distinguent par leur sens de l'accueil <br/>
            et leurs <span className='uk-text-bold'>prouesses techniques</span>.
            <br/>
            Descendant tout droit de Helheim, <br/>
            l'un des Neuf Mondes de la mythologie nordique, <br/>
            ils sauront <span className='uk-text-bold'>réchauffer votre public par leur musique, le jeu</span> <br/>
            et bien sûr … le <span className='uk-text-bold'>feu</span>.
        </p>

        <p>
            « Un <span className='uk-text-bold'>mélange harmonieux et fantastique</span> entre l’art de la jonglerie,
            les échasses, la magie du feu et d'un animal à 3 têtes... Le Cerbère. »
        </p>
    </>,
    hordeDataCostume: 'Costume impressionnant',
    hordeNbArtists: '5 à 7 artistes',

    // Lady & Gentle-leds
    ladyGentleTitle: ladyGentleTitle,
    ladyGentleMetaTitle: 'Lady & Gentle-leds',
    ladyGentleShortDescription: <>
        Un trio comique et lumineux! <br/>Mettez de l'élégance <br/>pour votre évènement.
    </>,
    ladyGentleDescription: <>
        <p>
            Les Hommes de Pie, <span className='uk-text-bold'>deux mimes gentlemen chics et charmants</span> <br/>
            accompagnés de leur dame à la couleur « rouge amour » danseuse et rayonnante, <br/>
            déambulent avec leurs énergies scintillantes de mille feux.
        </p>

        <p>
            Vêtus de leurs plus beaux costumes d'or et d'argent, <br/>
            ils sauront <span className='uk-text-bold'>accueillir vos invités</span>, <br/>
            provoquer de l'<span className='uk-text-bold'>interaction avec votre public</span> et même déambuler sur échasses (selon la demande).
            <br/>
            Donnons encore plus de hauteur à votre événement !
        </p>

        <p>
            « Lady & Gentle-led's » arrivent pour <span className='uk-text-bold'>pimenter et émerveiller vos invités</span> <br/>
            dans une parfaite harmonie sous le signe de l'<span className='uk-text-bold'>ultra chic</span> !!!
        </p>
    </>,
    ladyGentleDataCostume: 'Costume lumineux',
    ladyGentleNbArtists: '2 à 4 artistes',

    // Light Me Up
    lightMeUpTitle: lightMeUpTitle,
    lightMeUpMetaTitle: 'Light Me Up',
    lightMeUpShortDescription: <>
        Des LEDs, des lasers et beaucoup de joie, <br/>pour une ambiance ultra lumineuse !
    </>,
    lightMeUpDescription: <>
        <p>
            Voici un mélange de tous nos personnages, plus resplendissant les uns que les autres, <br/>
            ET ce soir..., ils mettent leurs plus beaux habits de lumière !
        </p>

        <p>
            Noyés dans une nappe intense de <span className='uk-text-bold'>LEDs, lasers et fumigènes</span>, <br/>
            nos <span className='uk-text-bold'>Robots</span> « breakdancers », <span className='uk-text-bold'>poupées désarticulées, circassiens</span> au sol
            ou en <span className='uk-text-bold'>échasses</span> <br/>
            viendront vous régaler artistiquement et lumineusement !
        </p>

        <p>
            Quand les performances de <span className='uk-text-bold'>l'art de rue se marient avec le monde de la nuit</span>, <br/>
            Les étoiles sont sur la piste et vont briller de mille feux !
        </p>

        <p>Laissez-vous éblouir et... Que la fête commence !!!</p>
    </>,
    lightMeUpDataCostume: 'Costume lumineux LEDs et laser',
    lightMeUpNbArtist: '2 artistes minimum',

    // Lutinery
    lutineryTitle: lutineryTitle,
    lutineryMetaTitle: 'Lutinery',
    lutineryShortDescription: <>
        Un monde fantastique s'offre à vous,<br/>
        accueillez nos lutins fantastiques <br/>
        pour une belle dose de féérie
    </>,
    lutineryDescription: <>
        <p>
            Voici nos <span className="uk-text-bold">lutins farceurs</span>, <span className="uk-text-bold">musiciens</span>, <span
            className="uk-text-bold">troubadours</span>... <br/>
            toujours prêts à donner <span className="uk-text-bold">émotions et sourires</span> là où ils passent !
        </p>
        <p>
            <span className="uk-text-bold">Leur monde est fantastique</span> : <br/>
            Entre <span className="uk-text-bold">bulles</span>, <span className="uk-text-bold">jongleries</span>, et <span
            className="uk-text-bold">échasses</span>, vêtus de leurs plus beaux habits,<br/>
            ils sauront mener à bien votre événement et <span className='uk-text-bold'>divertir votre public</span>.
        </p>
        <p>
            Venant tous d'un pays différent, avec leurs légendes et leur gros nez,<br/>
            ils vous embarqueront dans une <span className="uk-text-bold">déambulation élégante</span>, <span>poétique</span> et le tout avec <span className='uk-text-bold'>humour</span>.
        </p>
        <p>
            N'attendez plus, ils sont ici ! <br/>
            Approchez !! Approchez !! <span className='uk-text-bold'>La Féerie arrive</span> !
        </p>
    </>,
    lutineryDataCostume: 'Costume fantastique',
    lutineryNbArtists: '3 à 7 artistes',
    lutineryDataMusic: 'Musical avec instruments et fond sonore',

    // Les Momies
    momiesTitles: momiesTitleFR,
    momiesMetaTitle: 'Les Momies',
    momiesShortDescription: <>
        Bien que leur aspect soit poussiéreux <br/>prenez garde, elles ont plus d'un tour <br/>sous leurs bandelettes...
    </>,
    momiesDescription: <>
        <p>
            Sorties tout droit de leurs tombeaux au cœur des Pyramides de Gizeh, <br/>
            découvrez Khéops et Louxor nos deux Momies.
            <br/>
            Au rythme saccadé de Thriller de Mickael Jackson, <br/>
            elles vous feront découvrir l'<span className='uk-text-bold'>art du Mime</span>, <br/>
            de la <span className='uk-text-bold'>Danse Contemporaine</span>, des <span className='uk-text-bold'>Portés Acrobatiques</span> et de la <span
            className='uk-text-bold'>Contorsion</span>.
        </p>

        <p>
            Drôles, attachantes et un peu flippantes, vous ne pourrez que les aimer !
            <br/>
            Spectacle et déambulation circassienne <span className='uk-text-bold'>atypique et unique</span> loin des clichés !
        </p>

        <p>
            Un vrai régal pour les amateurs de l’Égypte Antique et de films à sensations.
            <br/>
            Ne les cherchez plus !! Vos momies sont ici !
        </p>
    </>,
    momiesNbArtists: '2 artistes',

    // L'Homme arc-en-ciel et ses anges
    rainbowManTitle: rainbowManTitleFR,
    rainbowManMetaTitle: "L'Homme arc-en-ciel et ses Anges",
    rainbowManShortDescription: <>
        Vêtus de leur 31, ils arrivent de la lune <br/>pour vous sublimer de couleur
    </>,
    rainbowManDescription: <>
        <p>
            Personnage échassier sauteur et danseur rempli de couleurs, <br/>
            un <span className='uk-text-bold'>sentiment de douceur, de joie et de bien-être</span> vous envahira quand vous ferez sa connaissance.
            <br/>
            Il sera accompagné de ses anges bienveillants et mystérieux pour vous amener <br/>
            dans un tourbillon de satin, de <span className='uk-text-bold'>pureté et légèreté</span>...
        </p>

        <p>
            Deux Anges Siamois pour une <span className='uk-text-bold'>douce proximité bulleur et jongleur</span> <br/>
            sans oublier leur papa qui sera de la partie chaussé d'échasses et de grandes ailes, <br/>
            le tout sous une <span className='uk-text-bold'>pluie de bulles</span>.
        </p>

        <p>
            Déambulation avec un <span className='uk-text-bold'>char à bulles et sonorisé</span> poussé par leur « Chat'crobate » curieux, jongleurs et pitres à
            ces heures.
            <br/>
            Une harmonie de couleurs s'offre à vous.
        </p>
    </>,
    rainbowManDataCostume: 'Costume lumineux',
    rainbowManNbArtists: '4 à 5 artistes',

    // Le Rêve Blanc
    reveBlancTitle: reveBlancFR,
    reveBlancMetaTitle: 'Le Rêve Blanc',
    reveBlancShortDescription: <>
        Quand le blanc sublime l'élégance, <br/>l'excellence n'en est que comblée.
    </>,
    reveBlancDescription: <>
        <p>Le <span className='uk-text-bold'>Luxe et le Blanc</span> dans toute leur splendeur.</p>

        <p>
            La Marquise vous convie à son <span className='uk-text-bold'>élégante déambulation</span>.
            <br/>
            Entouré d'extravagants personnages, laissez-vous embarquer à bord du rêve blanc.
            <br/>
            Une immersion dans leur univers, entre hauteurs et jongleries, <br/>
            <span className='uk-text-bold'>humour et distinction</span>, ils vous communiqueront <span className='uk-text-bold'>énergie et bonne humeur</span> !
        </p>

        <p>
            Protagonistes hauts en couleurs, <span className='uk-text-bold'>échassiers majestueux</span>, ils captivent la foule <br/>
            avec leurs <span className='uk-text-bold'>réparties cinglantes et délirantes</span>.
            <br/>
            Faite place, « le rêve blanc » arrive pour émerveiller petits et grands <br/>
            dans un <span className='uk-text-bold'>tourbillon de douceur et d’élégance</span>.
        </p>
    </>,
    reveBlancDataCostume: 'Costume lumineux',
    reveBlancNbArtists: '2 à 5 artistes',

    // M'zelle Rosalie
    rosalieTitle: rosalieTitleFR,
    rosalieMetaTitle: "M'zelle Rosalie",
    rosalieShortDescription: <>
        Déambulation farfelue <br/>avec nos 3 compères... <br/>Bienvenue dans les années 30
    </>,
    rosalieDescription: <>
        <p>
            Tout droit sorti des Années 30 des studios de cinéma, <br/>
            <span className='uk-text-bold'>La belle Rosalie est de sortie !!!</span>
            <br/>
            Elle accompagne nos 3 compères dans les allées de votre événement <br/>
            et qui n'hésiteront pas à improviser des <span className='uk-text-bold'>saynètes dignes des plus grands films d'Hollywood</span>.
            <br/>
            Tout cela... <span className='uk-text-bold'>grâce à vous !!</span>
            <br/>
            Cette voiture en surprendra et charmera plus d'un avec ses <span className='uk-text-bold'>multiples surprises</span>.
            <br/>
            Hector, Oscar et Bidule ont plus d'un tour <br/>
            dans leur rosalie et sauront <span className='uk-text-bold'>amuser petits et grands</span>.
        </p>

        <p>
            Une <span className='uk-text-bold'>déambulation farfelue</span> parsemée d'intermèdes impromptus <br/>
            qui amuseront la foule.
            <br/>
            3,2,1... Moteur, ça tourne !!!
        </p>

        <p className='uk-text-bold uk-text-italic'>En <Link href={path.partners} legacyBehavior><a className='uk-link-reset text-hover-gold'>partenariat</a></Link>&nbsp; avec
            la compagnie "Beblibop"</p>
    </>,
    rosalieDataCostume: '',
    rosalieNbArtist: '3 artistes',

    // Striptease / Effeuillage
    stripteaseTitle: stripteaseTitle,
    stripteaseMetaTitle: 'Striptease',
    stripteaseShortDescription: <>
        L'art de l'effeuillage, sensuel <br/>et sensationnel, <br/>pour un moment mémorable.
    </>,
    stripteaseDescription: <>
        <p>
            Vous vous êtes déjà demandé comment <span className='uk-text-bold'> booster votre soirée ?</span> Nous mettons de la musique, un bon verre à
            partager
            entre amis, quelques jeux...
        </p>

        <p>
            Et là..., vous ressentez qu'il manque quelque chose de <span className='uk-text-bold'> pétillant pour faire groover vos invités ?</span>
        </p>

        <p>
            C'est le moment !!
            <br/>
            <span className='uk-text-bold'>Faite place à la délicatesse et à la sensualité !</span>
        </p>

        <p>
            Laissez-vous absorber par le charisme, la beauté et la prestance de nos artistes danseurs aux corps de rêves sans oublier <span
            className='uk-text-bold'>le brin de folie qui surprendra vos invités</span>.
        </p>

        <p>
            C’est à travers toutes ces qualités que <span className='uk-text-bold'>nos artistes sauront pimenter les souvenirs</span> de votre anniversaire,
            enterrement de vie de jeune fille et garçon, ou simple soirée entre amis.
            <br/>
            Manipulation de feu, contorsion, pole dance... tout ceci peut avoir lieu et c'est la raison pour laquelle, <span className='uk-text-bold'>l'art de l'effeuillage fait partie de notre compagnie de spectacle</span>.
        </p>

        <p>
            <span className='uk-text-bold'>Festivité et bonne ambiance</span> accompagneront l’uniforme ou le costume qui vous fera fantasmer !
            <br/>
            N’attendez plus pour faire <span className='uk-text-bold'>monter la température de votre soirée</span>.
        </p>

        <p className='uk-text-bold uk-text-italic'>
            En <Link href={path.partners} legacyBehavior><a className='uk-link-reset text-hover-gold'>partenariat</a></Link>&nbsp;
            avec les compagnies "El Circus", "White Tigers" et Andy Klein
        </p>
    </>,
    stripteaseDataCostume: 'Costume lumineux',
    stripteaseNbArtist: '1 artiste minimum',

    // Tarzan, Jane & Cheeta
    tarzanTitle: tarzanTitleFR,
    tarzanMetaTitle: 'Tarzan, Jane et le Gorille',
    tarzanShortDescription: <>
        Un cosplay qui ravira toute tranche d'âge <br/>avec un humour bien décalé
    </>,
    tarzanDescription: <>
        <p>
            Voici un spectacle "<span className='uk-text-bold'>Cosplay</span>" mélangeant le jeu de l'aérien,<br/>
            des portés acrobatiques et bien sûr le jeu de comédien <br/>
            <span className='uk-text-bold'>Une belle dose d'humour</span> et <span className='uk-text-bold'>de complicité</span> pour ces 3 artistes <br/>
            qui vont vous faire voyager tout en vous remémorant un classique du cinéma !
        </p>

        <p>
            Un accueil, une <span className='uk-text-bold'>soirée à thème "Cinéma"</span>, <br/>
            un festival d'art de rue, une soirée privée...
            <br/>
            Ils sauront <span className='uk-text-bold'>mettre à l'aise votre public</span> <br/>
            dans un esprit décontracté et chic à la fois.
        </p>
    </>,
    tarzanDataCostume: 'Cosplay',
    tarzanNbArtists: '3 artistes',

    // Tim Burton
    timBurtonTitle: timTitleFR,
    timBurtonMetaTitle: "Bim Turton n'est pas loin !",
    timBurtonShortDescription: <>
        Personnage atypique <br/>au caractère multiple. <br/>Bienvenue dans cet imaginaire...
    </>,
    timBurtonDescription: <>
        <p>
            Voici une <span className='uk-text-bold'>création fantastique "Burtonesque"</span> mélangeant l'art des échasses, <br/>
            de la jonglerie, du lumineux et de la musique pour un <span className='uk-text-bold'>moment inoubliable</span>.
        </p>

        <p>
            Laissez-vous guider par « Oscar » l'échassier, <br/>
            personnage au <span className='uk-text-bold'>charme exubérant et hypnotique</span> accompagné de son acolyte « Mister Bim », le jongleur fou.
            <br/>
            Sans oublier le Cyborg lumineux leds et lasers « Lx-1 », <br/>
            leur ami danseur <span className='uk-text-bold'>charismatique et excentrique</span>.
        </p>

        <p>
            Dringgg !! drinnng !! dringgg !!
            <br/>
            L'heure de la belle « Rose » a sonné !! poupée affriolante, ensorcelante et remontée à bloc, <br/>
            <span className='uk-text-bold'>saurez-vous l'aider à garder le rythme ?</span>
            <br/>
            Nous avons la solution !! « Mimo » notre mime-musicien saura vous aider !! Promis !
        </p>

        <p>
            Des moments de poésie, des prouesses techniques avec surtout... <span className='uk-text-bold'>un univers Unique</span> !
            <br/>
            Vous êtes les invités de ce monde enchanté !
        </p>
    </>,
    timBurtonDataCostume: 'Costume lumineux LEDs et laser',
    timBurtonNbArtist: '2 à 5 artistes',

    // Wadioui
    wadiouiTitle: wadiouiTitleFR,
    wadiouiMetaTitle: "Les WadiOui, les lutins d'Or et d'Argent",
    wadiouiShortDescription: <>
        Cette bande de joyeux compagnons <br/>vous feront vivre le plus magique <br/>des instants.
    </>,
    wadiouiDescription: <>
        <p>
            <span className='uk-text-bold'>Lutins de lumière</span>, échassiers bondissants, jongleurs et bulleurs déambulent <br/>
            avec leurs <span className='uk-text-bold'>énergies scintillantes de mille feux</span>.
        </p>

        <p>
            Les « WadiOui » sont un peuple de petits êtres surprenants qui investissent <br/>
            le monde des humains avec leur <span className='uk-text-bold'>univers coloré et magique</span>.
            <br/>
            De toutes tailles, ces êtres malicieux et rieurs vous surprendront par <span className='uk-text-bold'>leur bonne humeur</span>.
            <br/>
            Vous les reconnaîtrez facilement grâce à leurs p'tites oreilles <br/>
            et leurs sourires charmeurs.
        </p>

        <p>
            La neige virevolte, gilets rouges et pompons blancs sont de la partie.
            <br/>
            Les « WadiOui » arrivent pour <span className='uk-text-bold'>émerveiller grands et petits</span> !!!
        </p>
    </>,
    wadiouiDataCostume: 'Costume lumineux',
    wadiouiDataMusic: 'Musical (sonorisé)',
    wadiouiNbArtists: '5 à 7 artistes',

    // Wotan
    wotanTitle: wotanTitle,
    wotanMetaTitle: 'Wotan Project',
    wotanShortDescription: <>
        Replongez-vous dans un univers rétro <br/>et laissez-vous emporter... par leurs bulles
    </>,
    wotanDescription: <>
        <p>
            Une rencontre de <span className='uk-text-bold'>2 clowns et 1 demoiselle bien liés par le domaine du cirque</span>, <br/>
            des échasses, du mime, de la comédie et de la marionnette.
        </p>

        <p>
            Voici « Wotan Project » <span className='uk-text-bold'>Spectacle-déambulatoire sonore</span>.
            <br/>
            1 clown échassier tenant 1 marionnette humaine avec sa croix en bois en marionnette <br/>
            qui tient lui même sa marionnette, <br/>
            plusieurs hauteurs pour encore plus de <span className='uk-text-bold'>plaisir et de participation avec le public</span>.
        </p>

        <p>
            Sans oublier, Miss Mary qui nous fera une <span className='uk-text-bold'>multitude de bulles</span> et qui donnera du fil à retordre à Mr
            Calisson, <br/>
            le clown échassier.
        </p>

        <p>
            Alors n'attendez plus !
            <br/>
            <span className='uk-text-bold'>Poésie, Sourire et émotions sont de la partie</span> !
        </p>
    </>,
    wotanDataCostume: 'Costume univers steampunk',
    wotanNbArtist: '2 à 3 artistes',
}

export default frShows
