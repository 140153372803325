const esHomeCarousel = {
    // January
    carouselJanuary1Title: 'NUEVO SITIO WEB',
    carouselJanuary1Description: <>
        ¡Todo nuevo, todo bonito! Fácil e intuitivo <br/>
        <span className='uk-text-bold'>¡Venga a descubrir nuestra empresa!</span> <br/>
        Encontrará algo que le convenga durante todo el año.
    </>,

    carouselJanuary2Title: 'FELIZ AÑO NUEVO',
    carouselJanuary2Description: <>
        La compañía le desea un feliz año nuevo <br/>
        llena de amor, alegría y, por supuesto, salud <br/>
        Y para empezar, aquí hay algunos <span className='uk-text-bold'>todos nuestros espectáculos</span> !
    </>,

    carouselJanuary3Title: 'CON CAPACIDAD DE RESPUESTA Y MUCHO MÁS.',
    carouselJanuary3Description: <>
        Nuestro nuevo sitio web se adapta a todos los soportes <br/>
        Un sitio, 22 espectáculos, ¡todo junto para usted! <br/>
        Y, por supuesto, las redes sociales para conocer toda la actualidad en tiempo real <br/>
        Únase a nosotros
    </>,

    carouselJanuary4Title: 'LOVE and FIRE',
    carouselJanuary4Description: <>
        Prepárese para hacer brillar su evento <br/>
        El chic, el fuego y los fuegos artificiales forman parte del espectáculo <br/>
        Esto es "<span className='uk-text-bold uk-text-italic'>Cracheurs de feu et jongleur pyrotechnie</span>"
    </>,

    carouselJanuary5Title: 'DÍA DE SAN VALENTÍN',
    carouselJanuary5Description: <>
        Presentamos 3 espectáculos <br/>
        bajo el signo de la elegancia en los colores del amor. <br/>
        ¡Cita para un momento de locura, ultra chic y glamuroso!
    </>,

    carouselJanuary6Title: '¡AL RITMO DE TU CORAZÓN!',
    carouselJanuary6Description: <>
        Steampunk en el punto de mira con "Fire's Coal",<br/>
        Es hora de arder de envidia con Un show único<br/>
        ¡que despertará a su público!
    </>,

    // February
    carouselFebruary1Title: 'CARNAVAL Y COTILLÓN',
    carouselFebruary1Description: <>
        Es hora de divertirse <br/>
        con personajes de gran colorido. <br/>
        ¡Resérvanos para pasar momentos felices!
    </>,

    carouselFebruary2Title: 'ARTE CALLEJERO',
    carouselFebruary2Description: <>
        Inmersión total en las burbujas <br/>
        y en la locura de nuestros actores <br/>
        Esto es "<span className='uk-text-bold'>M'zelle Rosalie</span>" y "<span className='uk-text-bold'>Wotan project</span>"
    </>,

    carouselFebruary3Title: 'TALLERES',
    carouselFebruary3Description: <>
        Venga a descubrir nuestros talleres adaptados para grandes y pequeños.<br/>
        Alegría y buen humor garantizados para una explosión de creatividad,<br/>
        En Semana Santa... o durante todo el año
    </>,

    // March
    carouselMarch1Title: '¡SUBE LA TEMPERATURA!',
    carouselMarch1Description: <>
        La delicadeza y la sensualidad están a la orden del día,<br/>
        Aquí está el "showtopper" para animar tu noche. <br/>
        ¡Nuestros polifacéticos artistas le entusiasmarán !
    </>,

    carouselMarch2Title: 'FESTIVAL DE LA CALLE',
    carouselMarch2Description: <>
        Nuestros espectáculos están aquí para deleitar a grandes y pequeños,<br/>
        Planifica tu verano y haz feliz a tu público. <br/>
        ¡No espere más para vivir momentos fantásticos! <br/>
    </>,

    carouselMarch3Title: 'Gala, Reunión de negocios, Boda,...',
    carouselMarch3Description: <>
        Este es el gran día, ¡no busques más!<br/>
        Estamos aquí para alegrar sus noches <br/>
        Ultra chic, láseres y leds son parte del juego <br/>
    </>,

    // April
    carouselApril1Title: 'CINE... ¡DE GIRA!',
    carouselApril1Description: <>
        ¿Su tema es la "Gran Pantalla" o una fiesta en la "Jungla"?<br/>
        Acróbatas y cómicos podrán encarnar nuestro "Cosplay" con brío. <br/>
        ¿Un recuerdo inolvidable? Llévate tu foto o vídeo <br/>
    </>,

    carouselApril2Title: 'TALLERES',
    carouselApril2Description: <>
        Venga a descubrir nuestros talleres adaptados para grandes y pequeños. <br/>
        Alegría y buen humor garantizados para una explosión de creatividad,<br/>
        ¡La elección es suya! "<span className='uk-text-bold'>zancos</span>", "<span className='uk-text-bold'>burbujas</span>", "<span
        className='uk-text-bold'>malabares</span>", ...
    </>,

    carouselApril3Title: 'FESTIVAL DE CINE',
    carouselApril3Description: <>
        ¡No estás preparado! Sorprende a tus invitados <br/>
        Con uno de los monstruos más populares de la ciencia ficción. <br/>
        ¡Un <span className='uk-text-bold'>"Cosplay" mítico</span> para un evento excepcional!
    </>,

    // MAY
    carouselMay1Title: 'FESTIVALES',
    carouselMay1Description: <>
        ¡Llega el verano! Planifique su entretenimiento con nuestros espectáculos.<br/>
        Te hacen reír, ¡salta de un lado a otro! <br/>
        <span className='uk-text-bold'>Resérvanos para los momentos de locura</span><br/>
    </>,

    carouselMay2Title: 'FESTIVAL DE LA CALLE',
    carouselMay2Description: <>
        Imaginamos fuego, engranajes, burbujas<br/>
        trajes impresionantes con humor <br/>
        ¡He aquí <span className='uk-text-bold'>un universo chic e insólito</span> para sus eventos!<br/>
    </>,

    // September Cinema
    carouselSeptemberCinemaName: 'Cinéma',
    carouselSeptemberCinemaDescription: <>
        Prévoyez votre rentrée Cinéma ! <br/>
        Avec les cosplay "Black Panther", <br/>
        "Tarzan", "Alien", ...
    </>,

    carouselMay3Title: 'DÍA DE LA MADRE Y DEL PADRE',
    carouselMay3Description: <>
        Lo planeamos todo. Y sí, ¡está ocurriendo rápidamente!<br/>
        Así que no esperes más, aquí tienes "<span className='uk-text-bold'>L'homme Arc en ciel et ses Anges</span>" <br/>
        Y "<span className='uk-text-bold'>Lady et Gentleled's</span>" para una fragancia de delicadeza y elegancia<br/>
    </>,

    // JUNE
    carouselJune1Title: 'BODAS...',
    carouselJune1Description: <>
        Este es tu día más importante, ¡no busques más!<br/>
        Estamos aquí para <span className='uk-text-bold'>alegrar tu maravilloso día</span> <br/>
        Elegancia en la recepción y láseres y leds para la noche<br/>
    </>,

    carouselJune2Title: 'VACACIONES BANCARIAS',
    carouselJune2Description: <>
        Prepárese para hacer brillar su evento<br/>
        Chic, <span className='uk-text-bold'>fuego y fuegos artificiales están en la mezcla</span>. ¡Es el gran día!<br/>
        Esto es "Cracheurs de feu et jongleur pyrotechnie"<br/>
    </>,

    carouselJune3Title: 'FESTIVAL DE MÚSICA (STEAMPUNK)',
    carouselJune3Description: <>
        Imaginamos fuego, engranajes,<br/>
        trajes impresionantes al ritmo de una música alocada<br/>
        Aquí tienes un <span className='uk-text-bold'>universo chic e insólito</span> para tus eventos<br/>
    </>,

    // JULY
    carouselJuly1Title: "¡VENGA! VAMOS A BAILAR.",
    carouselJuly1Description: <>
        Y aquí vamos por un verano loco<br/>
        Nuestros artistas están ahí para incendiar la pista de baile <br/>
        <span className="uk-text-bold">Fuego, luces, robots, malabaristas, etc...</span> Estamos aquí.
    </>,

    carouselJuly2Title: 'FESTIVALES',
    carouselJuly2Description: <>
        ¡Es agradable y cálido! Nuestros espectáculos están fuera<br/>
        <span className='uk-text-bold'>burbujas, zancudos, malabaristas con humor y poesía.</span> <br/>
        Si se te ha olvidado reservarnos, ¡todavía queda el año que viene!<br/>
    </>,

    carouselJuly3Title: 'FIESTA DE LAS BRUJAS',
    carouselJuly3Description: <>
        Y sí, ¡está a punto de llegar! Es hora de pensar en ello<br/>
        <span className='uk-text-bold'>Varios universos para un máximo de sensaciones</span> <br/>
        No esperes. ¡Resérvanos ahora!<br/>
    </>,

    // August
    carouselAugust1Title: "TALLERES",
    carouselAugust1Description: <>
        Venga a descubrir nuestros talleres adaptados para grandes y pequeños.<br/>
        Alegría y buen humor garantizados para una explosión de creatividad, <br/>
        ¡La elección es suya! "<span className='uk-text-bold'>zancos</span>", "<span className='uk-text-bold'>burbujas</span>", "<span className='uk-text-bold'>malabares</span>"...<br/>
    </>,

    carouselAugust2Title: "¡SUBE LA TEMPERATURA!",
    carouselAugust2Description: <>
        <span className='uk-text-bold'>El verano ya está aquí</span>, encuéntranos en los salones eróticos,<br/>
        ¡tatuajes o para realizar insólitos stripteases! <br/>
        <span className='uk-text-bold'>Choisissez votre univers</span> et pimenter votre évènement.<br/>
    </>,

    carouselAugust3Title: "VERANO e INAUGURACIÓN",
    carouselAugust3Description: <>
        ¡Está caliente! Muestre a sus invitados lo mejor<br/>
        <span className='uk-text-bold'>burbujas, zancudos, malabaristas con humor y poesía</span>.<br/>
        ¿Una inauguración? ¿Un lanzamiento de producto? Es AQUÍ<br/>
    </>,

    // SEPTEMBER
    carouselSeptember1Title: "ES LA VUELTA AL COLE",
    carouselSeptember1Description: <>
        ¡Planifica tu <span className='uk-text-bold'>entrada al cine</span>!<br/>
        Varios "Cosplay" a tu disposición: "Black Panther",<br/>
        "Tarzán", "Alien" <span className="uk-text-bold">o para una fiesta</span>, ¡la elección es tuya!<br/>
    </>,

    carouselSeptember2Title: "ESPECTÁCULO ITINERANTE",
    carouselSeptember2Description: <>
        "M'zelle Rosalie" ha vuelto, ¡diviértete!<br/>
        Con, por supuesto, todos los demás que le esperan aquí.<br/>
        <span className='uk-text-bold'>Anime sus eventos futuros... ¡ahora!</span><br/>
    </>,

    carouselSeptember3Title: "¡FUERA DE LA CIRCULACIÓN!",
    carouselSeptember3Description: <>
        Planifica tus <span className='uk-text-bold'>festejos medievales para el próximo año</span>,<br/>
        <span className='uk-text-bold'>Celebraciones medievales para el próximo año</span> y por supuesto <span>Halloween</span> !<br/>
        "La horde" te hará temblar de placer<br/>
    </>,

    // OCTOBRE
    carouselOctober1Title: "¡HALLOWEEN! ES AHORA",
    carouselOctober1Description: <>
        "La Horde", ¡un paseo de diablillos, un cerbero y un esqueleto saltarín!<br/>
        Sin olvidar a "Alien", un loco cosplay que sorprenderá a tus invitados<br/>
        <span className='uk-text-bold'>He aquí algunos disfraces impresionantes para este mes de octubre</span><br/>
    </>,

    carouselOctober2Title: "EL FUEGO Y LAS BURBUJAS DEAMBULAN",
    carouselOctober2Description: <>
        Steampunk en el punto de mira con "Fire's Coal",<br/>
        ¡un espectáculo único <span className='uk-text-bold'>con carroza y actuaciones</span>!<br/>
        Anime sus futuros eventos bajo el signo del Industrial-Chic...<br/>
    </>,

    carouselOctober3Title: "¡ESPECTÁCULOS EMOCIONANTES!",
    carouselOctober3Description: <>
        ¡Planifica tu fiesta de Halloween! ¡Las reservas ya están abiertas!<br/>
        ¡El "Día de Muertos" viene directamente de México.!<br/>
        Y no te olvides de nuestras "mamás" que te harán pasar una noche loca<br/>
    </>,

    // NOVEMBER
    carouselNovember1Title: "¡LLEGAN LAS CELEBRACIONES DE FIN DE AÑO!",
    carouselNovember1Description: <>
        Te presentamos 3 espectáculos de senderismo para Navidad<br/>
        siempre <span className='uk-text-bold'>bajo el signo de la elegancia y la locura</span>,<br/>
        aquí está "L'homme Arc-en-ciel et ses Anges" y el "Sueño blanco" y por supuesto el "Wadioui".<br/>
    </>,

    carouselNovember2Title: "SE ACERCA LA VÍSPERA DE AÑO NUEVO",
    carouselNovember2Description: <>
        <span className='uk-text-bold'>Es hora de planificar también tu Nochevieja</span>,<br/>
        ¡LEDs, fuego, chic para que el último día del año sea un éxito!<br/>
        "Light me Up", "Fire's Coal y "Cracheurs de feu"<br/>
    </>,

    carouselNovember3Title: "¡SE ACERCA LA NAVIDAD!",
    carouselNovember3Description: <>
        Es la recta final de su temporada festiva.<br/>
        Exige lo mejor con <span className='uk-text-bold'>técnica y humor para una Navidad-Chic</span><br/>
        Los sprites dorados y plateados los "<span className='uk-text-bold'>Wadioui</span>" están en el juego<br/>
    </>,

    // DECEMBER
    carouselDecember1Title: "FELICES VACACIONES",
    carouselDecember1Description: <>
        Le deseamos unas felices fiestas <br/>
        <span className='uk-text-bold'>Síguenos en nuestras redes sociales</span> y sobre todo... <br/>
        No olvides reservarnos <span className='uk-text-bold'>para el próximo año</span>
    </>,

    carouselDecember2Title: "AÑO NUEVO",
    carouselDecember2Description: <>
        ¡Ponga a sus invitados en la luz! <br/>
        ¡LEDs, fuego, chic para que <span className='uk-text-bold'>el último día del año sea un éxito</span>! <br/>
        "Light me Up" y "Cracheur de feu et Jongleur Pyrotechnie"
    </>,

    carouselDecember3Title: "¡ESTRATEGIA FINAL!",
    carouselDecember3Description: <>
        Una bienvenida ultra chic para tus invitados de Nochevieja...  <br/>
        <span className='uk-text-bold'>Elegancia, técnica y locura</span>, <br/>
        Esto es "Lady and Gentleled's" y el "Rêve blanc"
    </>,

    carouselDecember4Title: "¡SIN OLVIDAR!",
    carouselDecember4Description: <>
        La magia del fuego con "Fire's Coal", <br/>
        Un espectáculo único con una carroza y un espectáculo pirotécnico. <br/>
        Entra en el universo <span className='uk-text-bold'>Chic-Steampunk para tu Nochevieja</span>.
    </>,
}

export default esHomeCarousel