const enHome = {
    // HOME PAGE
    homeMetaTitle: 'Clowns dans la sciure - Home',
    homeMetaDescription: 'The company Clowns dans la sciure works in the street and night arts. It combines circus techniques and theater to offer you original and magical shows in multiple universes.',

    introductionMessage: <>
        <p>
            The CDLS Company is a troupe working in street and night arts.
            <br/>
            It joins technics from acting and circus in order to offer you enchanting and <br/>original shows in many universes.</p>
        <p>
            With style and crazy vigour for the enjoyment of young and old alike !
            <br/>
            Our most spectacular greetings !
        </p>
    </>,

    // HOME TEXTS
    // Universe Grid
    universeTitle: 'Our Universes',
    universeGridFirstDescription: <>Choose the « Color » <br/>of your party</>,
    universeGridSecondDescription: <>This is YOUR day;<br/> We will sublimate it!</>,
    universeGridThirdDescription: <>The art of entertaining your audience. <br/>Fun and enchantment are part of the party.</>,
    universeGridFourth: 'Workshop',
    universeGridFourthDescription: <>The art of building and learning <br/>Join us!</>,
    universeGridFifth: 'New show',
    universeGridFifthDescription: <>Don't stay in a rut, <br/>find originality!</>,
    universeGridSixth: 'Behind the scenes',
    universeGridSixthDescription: <>Moments of madness, the place to keep secret revealed here!</>,
}

export default enHome