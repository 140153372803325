const esTermOfUse = {
    termsOfUseTitle: 'Aviso legal y política de privacidad',
    termsOfUseMetaDescription: 'Aviso legal y política de privacidad del sitio web https://clownsdanslasciure.com',

    termsOfUseText: <>
        <h2>Créditos</h2>

        <p>
            Cualquier profesional, persona física o jurídica capaz en el sentido de los artículos 1123 y siguientes del Código Civil que visite el Sitio objeto
            de las presentes condiciones generales términos y condiciones pone a disposición de los Clientes :
        </p>
        <p>
            <span className='uk-text-bold'>Contenido</span>: Todos los elementos que constituyen la información presente en el Sitio, en particular los textos,
            imágenes y imágenes y vídeos.
        </p>
        <p>
            <span className='uk-text-bold'>Información del cliente</span>: En adelante se denominará "Información" que corresponde a todos los datos
            personales los datos personales que puedan estar en posesión de los Payasos en el Sitio del Serrín para la gestión de la relación con el cliente
            para su análisis, con fines estadísticos y de marketing.
        </p>

        <p>
            <span className='uk-text-bold'>Usuario</span>: Usuario de Internet que se conecta, utilizando el sitio mencionado.
        </p>

        <p>
            <span className='uk-text-bold'>Información personal</span>: "Información que permite, en cualquier forma, directa o identificación de las personas
            físicas a las que se aplican" (artículo 4 de la Ley nº 78-17 de 6 de enero de 1978).
        </p>

        <p>
            Los términos "datos personales", "sujeto de los datos" y "datos sensibles" tienen el significado definido por la Ley General de Datos Protección
            (GDPR: n°2016-679)
        </p>

        <h3>Presentación del sitio web</h3>

        <p>
            De conformidad con el artículo 6 de la Ley nº 2004-575, de 21 de junio de 2004, sobre la confianza en la economía digital, los usuarios del sitio
            web <a href='https://clownsdanslasciure.com' rel='noopener noreferrer'>https://clownsdanslasciure.com</a> la identidad de los distintos que
            participan en su creación y seguimiento:
        </p>

        <h4>Editor</h4>
        <p>
            Clowns dans la sciure <br/>
            Correo electrónico: <a href={`mailto:${process.env.NEXT_PUBLIC_CDLS_MAIL}`} className='uk-link-text'>{process.env.NEXT_PUBLIC_CDLS_MAIL}</a> <br/>
            Teléfono: <a href={`tel:${process.env.NEXT_PUBLIC_CDLS_PHONE}`}>{process.env.NEXT_PUBLIC_CDLS_PHONE}</a>
        </p>

        <h4>Director de la publicación</h4>
        <p>
            Françoise N., Presidente de Clowns dans la Sciure
        </p>

        <h4>Diseño y producción</h4>
        <p>
            Amandine TOURNAY <br/>
            <a href='https://www.linkedin.com/in/amandine-tournay/' target='_blank' rel='noopener noreferrer' className='uk-link-text uk-text-italic'>
                Página de LinkedIn
            </a> <br/>
            E-mail: amandine.tournay@outlook.fr
        </p>

        <h4>Créditos de las fotos</h4>
        <p>
            Créditos de las fotos : © Clowns dans la sciure, a menos que se indique lo contrario.
        </p>

        <h4>Anfitrión</h4>
        <p>
            o2switch <br/>
            222-224 Boulevard Gustave Flaubert <br/>
            63000 Clermont-Ferrand <br/>
            SIRET : 510 909 80700024 <br/>
            Tel : <a href="tel:+33444446040" className='uk-link-text'>+33 4 44 44 60 40</a>
        </p>

        <h2>Aviso legal</h2>

        <h3>Condiciones generales de uso del sitio y de los servicios ofrecidos</h3>

        <p>
            El Sitio constituye una obra intelectual protegida por las disposiciones del Código de la Propiedad Intelectual y la normativa internacional
            aplicable. reglamentos. El Cliente no podrá en ningún caso reutilizar, ceder o explotar por cuenta propia la totalidad o parte de los elementos o
            trabajos del el Sitio.
        </p>
        <p>
            El uso del Sitio implica la plena aceptación de las condiciones generales de uso descritas a continuación. Estas condiciones de uso pueden ser
            modificados o complementados en cualquier momento, por lo que se invita a los Usuarios del Sitio a consultarlos regularmente.
        </p>
        <p>
            El Sitio constituye una obra intelectual protegida por las disposiciones del Código de la Propiedad Intelectual y los Reglamentos Internacionales
            aplicables. Reglamentos. El Cliente no podrá en ningún caso reutilizar, transferir o explotar por cuenta propia la totalidad o parte de los
            elementos o trabajos del el Sitio.
        </p>
        <p>
            Este sitio web es normalmente accesible para los usuarios en todo momento. Sin embargo, La Troupe Clowns dans la sciure puede decidir interrumpir
            el sitio por motivos de mantenimiento técnico. decidido por La Troupe Clowns dans la sciure, que se esforzará por informar a los usuarios con
            antelación de las fechas y horarios de de la intervención.
            La empresa actualiza periódicamente el sitio web. Del mismo modo, los avisos legales pueden ser modificados en cualquier momento: no obstante, son
            No obstante, son vinculantes para el usuario, al que se invita a remitirse a ellas con la mayor frecuencia posible para leerlas.
        </p>

        <h3>Descripción de los servicios prestados</h3>

        <p>
            El objetivo de este sitio web es proporcionar información sobre las actividades de la empresa. Los payasos de la compañía del serrín se esfuerza por
            proporcionar información en el sitio que sea lo más precisa posible. Sin embargo, no se hace responsable de las omisiones, inexactitudes
            inexactitudes y deficiencias en la actualización, ya sean causadas por la empresa o por terceros asociados que proporcionan la información.
            información.
        </p>
        <p>
            Toda la información que aparece en el Sitio se da a título indicativo y es susceptible de cambiar. Además, la información en el Sitio no es
            exhaustiva. Se da a condición de que se hayan hecho modificaciones desde que se puso en línea.
        </p>

        <h3>Limitaciones contractuales de los datos técnicos</h3>

        <p>
            El sitio web utiliza la tecnología JavaScript. El sitio web no se hace responsable de los daños materiales relacionados con el uso del sitio web.
            Desde Además, el usuario del sitio se compromete a acceder al mismo utilizando equipos recientes, libres de virus y con un navegador actualizado de
            última generación. El Sitio está alojado en un proveedor de servicios de la Unión Europea de acuerdo con las disposiciones del Reglamento General de
            Protección de Datos (RGPD). Reglamento General de Protección de Datos (RGPD: n° 2016-679)
        </p>
        <p>
            El objetivo es ofrecer un servicio que garantice la mejor tasa de accesibilidad. El anfitrión garantiza la continuidad de su servicio las 24 horas
            del día 24 horas al día, todos los días del año. No obstante, se reserva el derecho de interrumpir el servicio de alojamiento durante el menor
            tiempo posible por motivos de mantenimiento, para mejorar su infraestructura, en caso de fallo de la misma o si los Servicios generan un tráfico que
            se considera y Servicios generan un tráfico considerado anormal.
        </p>
        <p>
            El Sitio y el Anfitrión no podrán ser considerados responsables en caso de mal funcionamiento de la red de Internet, de las líneas telefónicas o de
            los equipos informáticos y telefónicos, especialmente en caso de congestión de la red que impida el acceso al servidor. o equipos informáticos y
            telefónicos, especialmente en caso de congestión de la red que impida el acceso al servidor.
        </p>

        <h3>Propiedad intelectual y falsificación</h3>

        <p>
            La Troupe Clowns dans la sciure es propietaria de los derechos de propiedad intelectual y posee los derechos de uso sobre todos los elementos
            accesibles en el sitio web, en particular los textos, imágenes, gráficos, logotipos, vídeos, iconos y sonidos. Queda prohibida cualquier
            reproducción, representación, modificación, publicación, adaptación de la totalidad o parte de los elementos del sitio, cualquiera que sea el medio
            o el procedimiento utilizado, salvo previa autorización por escrito de la compañía.
        </p>
        <p>
            Toda utilización no autorizada del sitio o de cualquiera de los elementos que contiene se considerará constitutiva de infracción y será perseguida
            de conformidad con las disposiciones de los artículos L.335-2 y siguientes. perseguido de acuerdo con las disposiciones de los artículos L.335-2 y
            siguientes del Código de la Propiedad Intelectual.
        </p>

        <p>
            Copyright © 2021 The Clowns in the Sawdust Troupe Todos los derechos reservados.
        </p>

        <h3>Limitaciones de responsabilidad</h3>

        <p>
            La compañía Clowns in Sawdust Troupe actúa como editor del sitio. El gestor de contenidos es responsable de la calidad y veracidad de los Contenido
            que publica.
        </p>
        <p>
            La Troupe Clowns dans la sciure no se hace responsable de los daños directos e indirectos causados al equipo del usuario al acceder al sitio web, y
            que resulten bien del uso de un equipo que no cumpla las especificaciones indicadas en la sección "Limitaciones". el acceso al sitio web, y que
            resulten bien de la utilización de equipos que no cumplan las especificaciones indicadas en el apartado "Limitaciones contractuales de los datos
            técnicos", bien de la aparición de un fallo o limitaciones contractuales de los datos técnicos", o la aparición de un error o incompatibilidad.
        </p>
        <p>
            La Troupe Clowns dans la sciure no se hace responsable de los daños directos e indirectos causados al equipo del usuario al acceder al sitio web, y
            que resulten bien del uso de un equipo que no cumpla las especificaciones indicadas en la sección "Limitaciones". el acceso al sitio web, y que
            resulten bien de la utilización de equipos que no cumplan las especificaciones indicadas en el apartado "Limitaciones contractuales de los datos
            técnicos", bien de la aparición de un fallo o limitaciones contractuales de los datos técnicos", o la aparición de un error o incompatibilidad.
        </p>

        <h3>Gestión de datos personales</h3>

        <p>
            El Cliente está informado de la normativa relativa a la comunicación de marketing, la ley de 21 de junio de 2014 para la confianza en la Economía
            Digital, la Ley de Protección de Datos de 6 de agosto de 2004 y el Reglamento General de Protección de Datos (RGPD: n° 2016-679).
        </p>

        <h4>Responsables de la recogida de datos personales</h4>

        <p>
            Para los Datos Personales recogidos en el marco de su navegación en el Sitio, el responsable del tratamiento de los Datos Personales es : Françoise N. .
            La compañía Clowns dans la sciure está representada por Françoise N., su representante legal.
        </p>
        <p>
            Como responsable del tratamiento de los datos que recoge, la Troupe se compromete a respetar las disposiciones legales en vigor. En particular, es
            responsabilidad del Cliente establecer las finalidades de su tratamiento de datos, proporcionar a sus prospectos y clientes, desde el momento en que
            se recoge su consentimiento, información completa sobre el tratamiento de sus datos personales y garantizar que los datos sean exactos y completos.
            consentimiento, información completa sobre el tratamiento de sus datos personales y a mantener un registro del tratamiento de acuerdo con de las
            operaciones de transformación de acuerdo con la realidad. Siempre que la empresa procesa datos personales, toma todas las medidas razonables para
            garantizar que los datos personales sean exactos y pertinentes para los fines para los que se tratan.
        </p>

        <h4>Finalidad de los datos recogidos</h4>

        <p>
            The Clowns in Sawdust Troupe podrá tratar la totalidad o parte de los datos:

            <ol>
                <li>Para las campañas de comunicación (correo electrónico): dirección de correo electrónico</li>
                <li>Para volver a contactar con el cliente (SMS, llamada, correo electrónico): número de teléfono, dirección de correo electrónico</li>
            </ol>
        </p>
        <p>
            La Troupe no vende sus datos personales, que sólo se utilizan con fines estadísticos y de análisis. para fines de análisis.
        </p>

        <h4>Derecho de acceso, rectificación y oposición</h4>

        <p>
            De acuerdo con la normativa europea vigente, los Usuarios del Sitio tienen los siguientes derechos:
            <ol>
                <li>
                    Derecho de acceso (artículo 15 RGPD) y rectificación (artículo 16 RGPD), actualización, integridad de los datos de los usuarios derecho a
                    bloqueo o supresión de los datos personales de los usuarios (artículo 17 del RGPD), cuando sean inexactos incompleta, ambigua, obsoleta, o
                    cuya recogida, utilización, comunicación o almacenamiento esté prohibido;
                </li>
                <li>
                    Derecho a retirar el consentimiento en cualquier momento (artículo 13-2c del RGPD);
                </li>
                <li>
                    Derecho a limitar el tratamiento de los datos del usuario (artículo 18 del RGPD);
                </li>
                <li>
                    Derecho a oponerse al tratamiento de los datos de los usuarios (artículo 21 del RGPD);
                </li>
                <li>
                    Derecho a la portabilidad de los datos facilitados por los Usuarios, cuando dichos datos sean objeto de un tratamiento automatizado basado
                    en basado en su consentimiento o en un contrato (artículo 20 del RGPD);
                </li>
                <li>
                    El derecho a determinar el destino de los datos de los Usuarios después de su muerte y a elegir a quién comunicará (o no) la Empresa sus
                    datos a un tercero que haya designado previamente. (o no) a un tercero que hayan designado previamente.
                </li>
            </ol>
        </p>
        <p>
            Tan pronto como la compañía tenga conocimiento del fallecimiento de un usuario y en ausencia de instrucciones por su parte, se compromete a destruir
            sus datos, excepto si su conservación es necesaria a efectos probatorios o para cumplir una obligación legal.
        </p>
        <p>
            Si el Usuario desea conocer el uso que la Troupe hace de sus Datos Personales, solicitar la rectificación de los mismos u oponerse a su tratamiento,
            podrá dirigirse por escrito a la Troupe en la siguiente dirección el usuario puede ponerse en contacto con la compañía por escrito en la siguiente
            dirección :
            <br/>
            Clowns dans la sciure <br/>
            1, chemin du Luquet <br/>
            11800 Marseillette <br/>

            O electrónicamente:
            <br/>
            <a href="mailto:clownsdanslasciure@gmail.com" className='uk-link-text'>clownsdanslasciure@gmail.com</a>
        </p>
        <p>
            En este caso, el Usuario deberá indicar los Datos Personales que desea que la Empresa corrija, actualice o elimine, identificándose con precisión
            con una copia de un documento de identidad (tarjeta de identidad, pasaporte o permiso de conducir).
        </p>
        <p>
            Las solicitudes de supresión de Datos Personales estarán sujetas a las obligaciones impuestas por la ley a la Troupe, en particular en lo que
            respecta a la conservación o el archivo de documentos. Por último, los Usuarios del Sitio pueden presentar una reclamación ante las autoridades de
            control, y en particular ante la CNIL (
            <a href='https://www.cnil.fr/fr/plaintes' target='_blank' rel='noopener noreferrer' className='uk-link'> https://www.cnil.fr/fr/plaintes</a>).
        </p>

        <h4>No divulgación de datos personales</h4>

        <p>
            La Troupe no tratará, alojará o transferirá la Información recogida sobre sus Clientes a un país situado fuera de la Unión Europea o reconocido como
            "no adecuado" por la Comisión Europea sin informar previamente al cliente. la Unión Europea o reconocidos como "no adecuados" por la Comisión
            Europea sin informar previamente al cliente. Sin embargo, la es libre de elegir a sus subcontratistas técnicos y comerciales siempre que presenten
            garantías suficientes respecto a la requisitos del Reglamento General de Protección de Datos (GDPR: n° 2016-679).
        </p>
        <p>
            La compañía se compromete a tomar todas las precauciones necesarias para preservar la seguridad de la información y, en particular que no se
            comunique a personas no autorizadas. No obstante, si se produce un incidente que afecte a la integridad o confidencialidad de los datos del Cliente
            información se ponga en conocimiento de la Entidad Responsable, ésta informará al Cliente a la mayor brevedad posible y le comunicará las medidas
            correctoras las medidas correctoras adoptadas. Además, el Sitio no recoge ningún "dato sensible".
        </p>
        <p>
            Dentro de los límites de sus respectivas atribuciones y para los fines mencionados anteriormente, las principales personas susceptibles de tener
            acceso a los datos de los Usuarios del Sitio son Los datos de los Usuarios del Sitio son principalmente los de los gestores de la Troupe.
        </p>

        <h3>Notificación de incidentes</h3>

        <p>
            A pesar de nuestros esfuerzos, ningún método de transmisión por Internet y ningún método de almacenamiento electrónico es completamente seguro. Por
            lo tanto, no podemos garantizar una seguridad absoluta. Si tenemos conocimiento de una violación de la seguridad, lo notificaremos a los usuarios
            afectados para que puedan tomar las medidas oportunas. Nuestros procedimientos de notificación de incidentes tienen en cuenta nuestras obligaciones
            legales, tanto a nivel nacional como europeo. Nos comprometemos a mantener a nuestros clientes plenamente informados de todos los Nos comprometemos
            a mantener a nuestros clientes plenamente informados de todas las cuestiones relacionadas con la seguridad de su cuenta y a proporcionarles toda la
            información necesaria para ayudarles a cumplir sus propias obligaciones de información reglamentaria. requisitos de información reglamentaria.
        </p>
        <p>
            Ninguna información personal del usuario del Sitio se publica sin el conocimiento del usuario, se intercambia, se transfiere o se vende en cualquier
            medio a terceros. a terceros. Sólo la hipótesis de la recompra de la Troupe y de sus derechos permitiría la transmisión de la citada información a
            el eventual comprador que, a su vez, estaría obligado a la misma obligación de conservación y modificación de los datos con respecto al usuario del
            sitio web.
        </p>

        <p className='uk-text-bold'>Seguridad</p>
        <p>
            Al procesar los Datos Personales, la Troupe toma todas las medidas razonables para protegerlos de pérdida, uso indebido, acceso no autorizado,
            divulgación, alteración o destrucción.
        </p>

        <h3>Enlaces de hipertexto, cookies y etiquetas de Interne</h3>
        <p>
            El Sitio contiene un cierto número de enlaces de hipertexto hacia otros sitios, establecidos con la autorización de la Compañía. Sin embargo, la
            Troupe La Troupe no está en condiciones de comprobar el contenido de los sitios así visitados y, en consecuencia, no asume ninguna responsabilidad
            al respecto.
        </p>
        <p>
            A menos que decida desactivar las cookies, usted acepta que el sitio las utilice. Puede desactivar estas cookies en cualquier momento y Puede
            desactivar estas cookies en cualquier momento, de forma gratuita, utilizando las opciones de desactivación que se le ofrecen y que se recuerdan a
            continuación, sabiendo que esto puede reducir o impedir el acceso a la totalidad o parte de los Servicios ofrecidos por el Sitio.
        </p>

        <h4>COOKIES</h4>
        <p>
            Una "cookie" es un pequeño archivo de información que se envía al navegador del Usuario y se almacena en el terminal del mismo (por ejemplo,
            ordenador, smartphone), (en adelante, "Cookies"). Este archivo incluye información como el nombre de dominio del usuario, el proveedor de servicios
            de Internet del usuario y la dirección de correo electrónico del usuario. El archivo incluye información como el nombre de dominio del usuario, el
            proveedor de servicios de Internet del usuario, el sistema operativo del usuario y la fecha y hora de acceso. Las cookies no suponen en ningún caso
            un riesgo para dañar el terminal del usuario.
        </p>
        <p>
            La Troupe podrá tratar la información del Usuario relativa a su visita al Sitio, como las páginas consultadas, las búsquedas realizadas.
            consultados, búsquedas realizadas. Esta información permite a La Troupe mejorar el contenido del Sitio y la experiencia de navegación del Usuario.
        </p>
        <p>
            Las cookies facilitan la navegación y/o la prestación de los servicios ofrecidos por el Sitio, el Usuario puede configurar su navegador para que le
            permita decidir si las acepta o no para que las cookies se almacenen en el terminal o, por el contrario, para que las rechace, ya sea
            sistemáticamente o en función de su emisor, que son rechazados, ya sea sistemáticamente o según su emisor. El Usuario también puede configurar su
            software de navegación para que la aceptación o el rechazo de que la aceptación o el rechazo de las Cookies se proponga puntualmente, antes de que
            una Cookie pueda ser registrada en su terminal. El Sitio informa al Usuario que, en este caso, es posible que las funcionalidades de su software de
            navegación no estén totalmente disponibles. puede no estar disponible.
        </p>
        <p>
            Si el Usuario se niega a aceptar el almacenamiento de Cookies en su terminal o navegador, o si el Usuario elimina las almacenadas en él el Usuario
            queda informado de que su navegación y experiencia en el Sitio puede ser limitada. También podría ser el caso cuando la compañía o uno de sus
            proveedores de servicios no puede reconocer, por razones de compatibilidad técnica, el tipo de navegador utilizado por el terminal, el idioma y la
            configuración de la pantalla o el país desde el que el terminal parece estar conectado a Internet.
        </p>
        <p>
            En su caso, la Troupe declina toda responsabilidad por las consecuencias vinculadas al funcionamiento degradado del Sitio y de los servicios
            servicios eventualmente ofrecidos por la Troupe, resultantes del rechazo de las Cookies por parte del Usuario o de la imposibilidad para la Troupe
            de para que la Troupe registre o consulte las Cookies necesarias para su funcionamiento por elección del Usuario. Para la gestión de las Cookies y
            las elecciones del Usuario, la configuración de cada navegador es diferente. Se describe en el menú Se describe en el menú de ayuda del navegador,
            que mostrará cómo el Usuario puede modificar sus deseos con respecto a las Cookies.
        </p>
        <p>
            En cualquier momento, el Usuario puede optar por expresar y modificar sus deseos con respecto a las Cookies. La Troupe puede también utiliza los
            servicios de proveedores de servicios externos para ayudarle a recoger y procesar la información descrita en esta sección.
        </p>
        <p>
            Enfin, en cliquant sur les icônes dédiées aux réseaux sociaux Instagram, Facebook et Youtube figurant sur le Site et
            si l’Utilisateur a accepté le dépôt de cookies en poursuivant sa navigation sur le Site internet, Intagram, Facebook et Youtube peuvent également
            déposer des cookies sur vos terminaux (ordinateur, tablette, téléphone portable).
        </p>
        <p>
            Por último, al hacer clic en los iconos dedicados a las redes sociales Instagram, Facebook y Youtube en el Sitio Web y si el Usuario ha aceptado el
            depósito de cookies al continuar navegando por el Sitio Web, Intagram, Facebook y Youtube podrán también depositar cookies en sus terminales
            (ordenador, tableta, teléfono móvil).
        </p>

        <h4>Etiquetas de Internet</h4>

        <p>
            El Sitio puede emplear ocasionalmente balizas web (también conocidas como "etiquetas", etiquetas de acción, GIF de un solo píxel, GIF transparentes,
            GIF invisibles y GIF uno a uno) y desplegarlas a través de un socio de análisis web que puede proporcionarle información sobre el Sitio. GIFs, GIFs
            transparentes, GIFs invisibles y GIFs uno a uno) y desplegarlos a través de un socio de análisis web que puede estar localizado (y por tanto
            almacenar información, incluyendo la dirección IP del usuario) en el Sitio. puede estar ubicado (y por lo tanto almacenar la información
            relacionada, incluyendo la dirección IP del Usuario) en un país extranjero.
        </p>
        <p>
            Estas etiquetas se colocan tanto en los anuncios en línea que permiten a los usuarios de Internet acceder al Sitio, como en las distintas páginas
            del mismo. páginas del Sitio.
        </p>
        <p>
            Esta tecnología permite a la Troupe evaluar las respuestas de los visitantes al Sitio y la eficacia de sus acciones (por ejemplo, el número de veces
            que se abre una página y la información consultada), así como el uso de este Sitio por parte del Usuario. por ejemplo, el número de veces que se
            abre una página y la información consultada), así como el uso de este Sitio por parte del Usuario.
        </p>
        <p>
            El proveedor de servicios externo puede recoger información sobre los visitantes del Sitio y de otros sitios web a través de estas etiquetas,
            elaborar informes sobre la actividad del Sitio a la atención de la Troupe, y prestar otros servicios relacionados con el uso del Sitio y de
            Internet.
        </p>

        <h3>Ley aplicable y jurisdicción</h3>
        <p>
            Todo litigio relativo a la utilización del sitio web Clowns dans la sciure está sometido al derecho francés. Salvo en los casos en que la ley no lo
            permite, los tribunales competentes de Carcassonne tienen jurisdicción exclusiva
        </p>
    </>
}

export default esTermOfUse