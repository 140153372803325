import Link from 'next/link'

import path from '../../../contents/paths'

import alienTitle from 'img/general/svg/shows/Alien.svg'
import augustinTitleFR from 'img/general/svg/shows/Augustin_FR.svg'
import blackPantherTitle from 'img/general/svg/shows/Black.svg'
import bondyTitle from 'img/general/svg/shows/Bondy.svg'
import calissonTitle from 'img/general/svg/shows/Clowns.svg'
import diaDeLosMuertosTitle from 'img/general/svg/shows/Alejandro.svg'
import fireTitle from 'img/general/svg/shows/Fire.svg'
import hordeTitleFR from 'img/general/svg/shows/La_Horde_FR.svg'
import ladyGentleTitle from 'img/general/svg/shows/Lady.svg'
import lightMeUpTitle from 'img/general/svg/shows/Light_Me_Up.svg'
import lutineryTitle from 'img/general/svg/shows/Lutinery_FR.svg'
import momiesTitleFR from 'img/general/svg/shows/Momies_FR.svg'
import pyrotechnicsShowTitleFR from 'img/general/svg/shows/PyrotechnicsShow_FR.svg'
import rainbowManTitleFR from 'img/general/svg/shows/L_homme_FR.svg'
import reveBlancFR from 'img/general/svg/shows/Le_REVE_FR.svg'
import rosalieTitleFR from 'img/general/svg/shows/Rosalie_FR.svg'
import stripteaseTitle from 'img/general/svg/shows/Striptaese.svg'
import tarzanTitleFR from 'img/general/svg/shows/Tarzan_FR.svg'
import timTitleFR from 'img/general/svg/shows/Tim_FR.svg'
import wadiouiTitleFR from 'img/general/svg/shows/WadOuiFR.svg'
import wotanTitle from 'img/general/svg/shows/Wotan.svg'

const esShows = {
    // General
    allShowsTitle: 'Todos los espectáculos',
    allShowsMetaDescription: 'Lista de todos los espectáculos ofrecidos por la compañía Clowns dans la sciure',

    newShowsTitle: 'Nouveautés',

    eventMetaDescription: '¿Un evento para organizar? ¡Venga a ver lo que le ofrecemos!',
    themeMetaDescription: '¡Ofrecemos una gran variedad de universos que se adaptan a cualquier ocasión!',
    activitiesMetaDescription: '¿Quiere interactuar con el público u organizar talleres? ¡Este es el lugar donde hay que estar!',

    videoShow: 'Vídeo',
    videoShowDescription: '¡Elige el vídeo!',
    galleryShow: 'Galería de fotos',

    eventFileTitle: "¡Crear el evento que más le parezca!",
    fileClickMessage: "Haga clic en la imagen para ver la vista previa",
    askBrochure: 'SOLICITUD DE FOLLETOS / CATALOGOS',
    askQuote: 'SOLICITE UN PRESUPUESTO',

    prevShow: 'Spectacle précédent',
    nextShow: 'Spectacle suivant',

    showDataTechnics: 'Técnicamente autónomo',
    showPyrotechnicsAvailable: 'Opción pirotécnica',
    showDurationVariable: 'Variable',
    showPositionAmbulatory: 'Ambulatorio con escenas fijas',
    showStiltAvailable: 'En el suelo o sobre pilotes',
    showSantaAvailable: 'Opción de Papá Noel',
    showFireAvailable: 'Fuego',
    showFireOption: 'Opción Fuego',
    showFloatAvailable: 'Carro',
    showMusicAvailable: 'Musical (sonido)',

    // Alien & Ripley
    alienRipleyTitle: alienTitle,
    alienRipleyMetaTitle: 'Alien and Ripley',
    alienRipleyShortDescription: <>
        Embárcate en grandes emociones <br/>en este universo de ciencia ficción!
    </>,
    alienRipleyDescription: <>
        <p>
            ¡Desde la película de culto <span className='uk-text-italic'>Alien "el 8vo pasajero"</span>, embarque con nosotros a bordo del Nostromo,<br/>
            par un <span className='uk-text-bold'>espectáculo único que mezcla Fuego y Pirotecnia</span>, en el Universo Fantástico <br/>
            y esencial del gran <span className='uk-text-bold'>Maestro Giger!</span>
        </p>

        <p>
            ¡Nuestro Alien es una pieza única, este exoesqueleto se mueve, babea, abre la boca, <span
            className='uk-text-bold'>una verdadera actuación</span>.. <br/>
            sublimado por una <span className='uk-text-bold'>tragafuegos con, por supuesto, "Ripley"</span> directamente de finales de los años 70!
        </p>

        <p>
            ¡¡Aquí una deambulación y un cosplay que hará las delicias de cualquier grupo de edad!!
            <br/>
            Recuerdo, descubrimiento para los más pequeños pero todo sin miedo gracias a nuestros talentos de actor.
        </p>

        <p>
            Spectacle déambulatoire "Cosplay" diurne ou nocturne.
            <br/>
            Réservez des maintenant pour un <span className='uk-text-bold'>SHOW EXCEPTIONNEL</span> !!
        </p>
    </>,
    alienRipleyTechnics: 'Autónoma si 2 artistas presentes',
    alienRipleyDataCostume: 'Traje impresionante',
    alienRipleyNbArtists: '1 à 2 artistas',

    // Augustin
    augustinTitle: augustinTitleFR,
    augustinMetaTitle: "Augustin l'Épouvantail",
    augustinShortDescription: <>
        Deambulación de un personaje atípico <br/>Deambulación de un personaje atípico.
    </>,
    augustinDescription: <>
        <p>Fantástico personaje deambulatorio sonoro en suelo o sobre zancos.</p>

        <p>¿Quieres un <span className='uk-text-bold'>ambiente acogedor y mágico</span>?</p>

        <p>
            <span className='uk-text-bold'>Impacto en el medio ambiente</span> : ecodiseño y ecológico porque diseñado a mano casi sin asistencia eléctrica
            (menos del 10% sobre el conjunto del personaje) <br/>
            en el ambiente del <span className='uk-text-bold'>señor Tim Burton, de los cuentos de Grimm y de Perrault</span>...
        </p>

        <p>
            "Augustin" es un personaje entrañable <span
            className='uk-text-bold'>que hace que los dulces aparezcan mágicamente aquí y allá en su sombrero</span> <br/>
            y <span className='uk-text-bold'>con una alegría de vivir extraordinaria</span>...
            <br/>
            Descendido desde su planeta musical para llegar al mundo de los humanos, este espantapájaros emocionará a grandes y pequeños con su humor y
            amabilidad.
        </p>

        <p>
            Ya no querrás que vuelva a su planeta...
            <br/>
            ¿Hacemos la prueba?
        </p>
    </>,
    augustinDataCostume: 'Traje impresionante',
    augustinDataMusic: 'música electro en el suelo a partir de 3 artistas',
    augustinNbArtist: '1 artista',

    // Black Panther
    blackPantherTitle: blackPantherTitle,
    blackPantherMetaTitle: 'Black Panther',
    blackPantherShortDescription: <>
        Un cosplay evolucionando como un felino <br/>de mesa en mesa.
    </>,
    blackPantherDescription: <>
        <p>
            Aquí está el <span className='uk-text-bold'>cosplay de "T'Challa"</span>, cosplay de "T'Challa",
            <br/>
            personaje de ficción icónico del <span className="uk-text-bold">universo "<span className='uk-text-italic'>"Marvel Comics"</span>"</span>,
        </p>

        <p>
            ¿Qué necesitarías para tu evento "Cine"? ¿<span className='uk-text-bold'>Un superhéroe acróbata</span> amado
            <br/>
            por todos con una presencia digna de un felino?
            <br/>
            ¡¡No busques más, lo tenemos!!
        </p>

        <p>
            Más que un disfraz, "<span className='uk-text-italic'>Black Panther</span>" sabrá acercarse sigilosamente y darle emociones a su público
            <br/>
            tanto por su proezas técnicas como por su amabilidad.
        </p>

        <p>
            tanto por su proezas técnicas como por su amabilidad, <br/>
            muchos fans desean ser fotografiados con la <span className='uk-text-bold'>famoso "<span
            className='uk-text-italic'>Pantera Negra</span>"</span>, <br/>
            el deseo puede hacerse realidad en el reino de Wakanda o ... ¡¡en casa !!<br/>
            Ahora está AQUÍ para deleitar e inspirar a jóvenes y mayores.
        </p>
    </>,
    blackPantherDataCostume: 'Cosplay',
    blackPantherNbArtist: '1 artista',

    // Bondy & Maya
    bondyMaiaTitle: bondyTitle,
    bondyMetaTitle: 'Bondy & Maïa',
    bondyMayaShortDescription: <>
        Encuentra nuestro dúo acelerado <br/>en el mundo del BDSM.
    </>,
    bondyMayaDescription: <>
        <p>¡¡Olvida tus prejuicios, tus convicciones!!</p>

        <p>
            Ven a descubrir un mundo donde el látigo y las caricias se mezclan.
            <br/>
            Ven a descubrir un mundo donde el látigo y las caricias se mezclan "<span className='uk-text-bold'>Shibari</span>", <br/>
            se únan bajo la mirada de vuestro público.
        </p>

        <p>
            ¡¡En el suelo, moldeada en <span className='uk-text-bold'>látex y vinilo</span>, la bella "Maïa" no se dejará pasar!!
            <br/>
            Es ella, quien somete al zancudo "Bondie" a sus deseos más locos.
            <br/>
            ¡¡El Todo!! con <span className='uk-text-bold'>un amor loco</span>...
        </p>

        <p>
            Sumérgete con ellos en el <span className='uk-text-bold'>universo del BDSM</span>:
            <br/>
            Bondage, Dominación y Sumisión
            <br/>
            Seguro que habrá una disciplina que <span className='uk-text-bold'>despertará tus fantasías</span>.
            <br/>
            ¿Listo para jugar? ¡¡Es ahora !!
        </p>
    </>,
    bondyMayaDataCostume: 'Traje de látex y vinilo',
    bondyMayaNbArtist: '2 artistas',

    // Clowns
    clownsTitle: calissonTitle,
    clownsMetaTitle: 'Clowns',
    clownsShortDescription: <>
        en este universo de ciencia ficción! <br/>plenos de humor y de sorpresas, <br/>para reavivar las emociones de todos
    </>,
    clownsDescription: <>
        <p>¿Qué personaje te dará el <span className='uk-text-bold'>máximo de emociones</span> ?</p>

        <p>
            ¡¡Sí!! Es el payaso, la Compañía ofrece <span
            className='uk-text-bold'>artistas coloridos para una buena dosis de humor</span>.
            <br/>
            ¡¡En la nada, hay un todo !!
            <br/>
            En lo infinitamente pequeño, tenemos un <span className='uk-text-bold'>océano de buen humor</span>... y <span className='uk-text-bold'> sabremos mostrárselo bien</span>.
        </p>

        <p>
            En el suelo, sobre zancos, en patines, en monociclo o en moto, el payaso, como ya sabes, ¡sabe hacer de todo!
            <br/>
            Puede ser malabarista, acróbata o bailarín, ¡lo cual es seguro! su arte te <span className='uk-text-bold'>dará grandes sensaciones</span>.
            <br/>
            Quieres <span className='uk-text-bold'>complacer a tus invitados de todas las edades</span>, ¡esta es la elección correcta!
        </p>

        <p>
            <span className='uk-text-bold'>Ternura y risas alocadas</span>, ¡nuestros payasos le hacen la <span className='uk-text-bold'>reverencia de la felicidad</span>!
        </p>
    </>,
    clownsDataCostume: 'Traje divertido',
    clownsNbArtist: '1 à 2 artistas',

    // Cracheur de feu et pyrotechnie
    pyrotechnicsShowTitle: pyrotechnicsShowTitleFR,
    pyrotechnicsShowMetaTitle: 'Cracheur de Feu & Jongleur Pyrotechnie',
    pyrotechnicsShowShortDescription: <>
        Pirotecnia, levantamiento acrobático <br/>y tragafuegos ... ¡todo está ahí!
    </>,
    pyrotechnicsShowDescription: <>
        <p>
            ¡Descubra esta "Unidad" excepcional!
            <br/>
            <span className='uk-text-bold'>De apariencia elegante</span> y <span className='uk-text-bold'>refinada</span>, ¡puedo asegurarles que no son de
            encaje!
            <br/>
            Tenemos nuestros "artistas del fuego", todos <span className='uk-text-bold'>malabaristas y manipuladores prodigiosos</span>, <br/>
            sabiendo manejar sus flameantes instrumentos con una facilidad sin igual.
        </p>

        <p>
            ¡Te habrás dado cuenta, falta alguien!
            <br/>
            La bella también es parte de la fiesta, admítelo, su <span className='uk-text-bold'>extravagante belleza</span> <br/>
            ¡Y su mirada ardiente ya te ha conquistado!
        </p>

        <p>
            Deje que su corazón de niño tome el control a la vista de estas llamas gigantes, <br/>
            de esto <span className='uk-text-bold'>artificios resplandecientes y de estos actos acrobáticos</span> que te ofreceremos!
            <br/>
            ¡¡Todo en seguridad!! ¡¡3 ..., 2 ..., 1!!
            <br/>
            ¡Nuestros "quemadores" te calentarán!
        </p>
    </>,
    pyrotechnicsNbArtist: '2 a 5 artistas',

    // Dia de los Muertos
    diaDeLosMuertosTitle: diaDeLosMuertosTitle,
    diaDeLosMuertosMetaTitle: 'Alejandro & Gabriella - Dia de los Muertos',
    diaDeLosMuertosShortDescription: <>
        Un perfume de elegancia en este día <br/>dUn perfume de elegancia en este día.
    </>,
    diaDeLosMuertosDescription: <>
        <p>
            Regresados de entre los muertos, estás invitado a unirte al colorido mundo. <br/>
            de esta encantadora pareja de difuntos.
            <br/>
            <span className='uk-text-bold'>Pudiendo aparecer en el suelo o sobre un zanco</span>, <br/>
            estos amantes te encandilarán con su ineludible presencia.
        </p>

        <p>
            A estos dos personajes les encanta llamar la atención, y para la ocasión, <br/>
            ¡Ellos hacen su mejor espectáculo y reservan <span className='uk-text-bold'>sus bailes más fascinantes</span> para ti!
            <br/>
            ¡Surgidos del medio de la "Realeza Funeraria", sus actitudes principescas <br/>
            agregarán un <span className='uk-text-bold'>toque muy elegante a su evento</span>!
        </p>

        <p>
            ¡No más búsquedas! ¡Alejandro y Gabriella ya están aquí ...!
            <br/>
            ¡Que comience la fiesta !
        </p>
    </>,
    diaDeLosMuertosDataCostume: 'Traje luminoso',
    diaDeLosMuertosNbArtists: '2 artistas',

    // Fire's Coal
    fireCoalTitle: fireTitle,
    fireCoalMetaTitle: "Fire's Coal",
    fireCoalShortDescription: <>
        Esta familia de estilo victoriano <br/>sabrá reavivar la llama <br/>de nuestras hogueras.
    </>,
    fireCoalDescription: <>
        <p>
            Imagínese en un <span className='uk-text-bold'>Imagínese en un</span>, <br/>
            entre H.G Welles y Jules Verne, pero no solo eso...
            <br/>
            Añadimos el <span className='uk-text-bold'>encanto inglés con sus trajes "retro" chics y elegantes,</span>,
            <br/>
            fuego, burbujas y vapor en nuestro carro ...
        </p>

        <p>
            Aquí estamos en el corazón de un cóctel explosivo que te transportará <br/>
            en un <span className='uk-text-bold'>espectáculo fantástico e impresionante</span>.
        </p>

        <p>
            Todo apto para grandes y pequeños con total seguridad.
            <br/>
            Los artistas te <span className='uk-text-bold'>sorprenderán con su destreza en zancos</span>, <br/>
            <span className='uk-text-bold'>pirotecnia, malabarismo y tragafuegos</span>.
            <br/>
            Embárcate en nuestro fantástico universo "Steampunk".
        </p>
    </>,
    fireCoalDataCostume: 'Traje del universo steampunk',
    fireCoalNbArtists: '4 a 6 artista',

    // La Horde
    hordeTitle: hordeTitleFR,
    hordeMetaTitle: 'La Horde',
    hordeShortDescription: <>
        Un universo mitológico fantástico <br/>de criaturas de venidas directamente <br/>desde el lejano norte.
    </>,
    hordeDescription: <>
        <p>
            Astuto y reconocible entre toda una tribu, <br/>
            Estas <span className='uk-text-bold'>criaturas legendarias </span> se distinguen por su sentido de la hospitalidad. <br/>
            y sus <span className='uk-text-bold'>destrezas técnicas</span>.
            <br/>
            Descendiente directo de Helheim, <br/>
            uno de los Nueve Mundos de la mitología nórdica, <br/>
            sabrán cómo <span className='uk-text-bold'>recalentar a tu audiencia con su música, el juego</span> <br/>
            y por supuesto ...el <span className='uk-text-bold'>fuego</span>.
        </p>

        <p>
            "Una <span className='uk-text-bold'>armoniosa y fantástica mezcla</span> del arte del malabarismo, los zancos, la magia del fuego y un
            animal de 3 cabezas ... El Cerberus. "
        </p>
    </>,
    hordeDataCostume: 'Traje impresionante',
    hordeNbArtists: '5 a 7 artistas',

    // Lady & Gentle-leds
    ladyGentleTitle: ladyGentleTitle,
    ladyGentleMetaTitle: 'Light Me Up',
    ladyGentleShortDescription: <>
        ¡Un trío cómico y luminoso! <br/>¡Un trío cómico y luminoso! <br/>en su evento.
    </>,
    ladyGentleDescription: <>
        <p>
            Los Hombres de Pie, <span className='uk-text-bold'>dos mimos caballeros elegantes y encantadores</span> <br/>
            acompañados de su dama en color "rojo amor" bailarina y radiante, <br/>
            deambulan con sus energías centelleantes de mil luces.
        </p>

        <p>
            Vestidos con sus mejores trajes de oro y plata, <br/>
            sabrán <span className='uk-text-bold'>recibir a tus invitados</span>, <br/>
            recibir a tus invitados <span className='uk-text-bold'>interacción con su público</span> e incluso pasear sobre zancos (según la demanda).
            <br/>
            ¡Demos aún más altura a tu evento!
        </p>

        <p>
            ¡¡¡Llegan "Lady & Gentle-led's" para <span className='uk-text-bold'>animar y sorprender a sus invitados</span> <br/>
            en perfecta armonía bajo el signo de <span className='uk-text-bold'>ultra chic</span>!!!
        </p>
    </>,
    ladyGentleDataCostume: 'Traje luminoso',
    ladyGentleNbArtists: '2 a 4 artistas',

    // Light Me Up
    lightMeUpTitle: lightMeUpTitle,
    lightMeUpMetaTitle: 'Light Me Up',
    lightMeUpShortDescription: <>
        ¡LEDs, láseres y mucha diversión, <br/>para un ambiente ultra luminoso!
    </>,
    lightMeUpDescription: <>
        <p>
            Aquí hay una mezcla de todos nuestros personajes, cada uno más resplandeciente que el otro, <br/>
            Y esta noche ... ¡se ponen sus mejores ropas iluminadas!
        </p>

        <p>
            ¡Sumergidos en una intensa capa de <span className='uk-text-bold'>LED, láseres y humo</span>, <br/>
            nuestros <span className='uk-text-bold'>Robots</span> "breakdancers", <span className='uk-text-bold'>muñecos desarticulados, circenses</span> en el
            suelo o sobre <span className='uk-text-bold'>zancos</span> <br/>
            vendrán a agasajarte artística y luminosamente!
        </p>

        <p>
            Cuando las actuaciones <span className='uk-text-bold'>del arte callejero se mezclan con el mundo nocturno</span>, <br/>
            ¡Las estrellas están en la pista y brillarán de mil luces!
        </p>

        <p>Déjate deslumbrar y ... ¡¡¡Que empiece la fiesta !!!</p>
    </>,
    lightMeUpDataCostume: 'Traje luminoso LED y láser',
    lightMeUpNbArtist: '2 artistas minimo',

    // Lutinery
    lutineryTitle: lutineryTitle,
    lutineryMetaTitle: 'Lutinery',
    lutineryShortDescription: <>
        Un mundo fantástico te espera,<br/>
        bienvenidos nuestros fantásticos elfos <br/>
        para una buena dosis de encanto
    </>,
    lutineryDescription: <>
        <p>
            He aquí nuestros <span className="uk-text-bold">duendes bromistas</span>, <span className="uk-text-bold">músicos</span>, <span
            className="uk-text-bold">trovadores</span>... <br/>
            siempre listos a brindar <span className="uk-text-bold">brindar emociones y sonrisa</span> donde quiera que vayan !
        </p>
        <p>
            <span className="uk-text-bold">Su mundo es fantástico</span>: <br/>
            Entre <span className="uk-text-bold">burbujas</span>, <span className="uk-text-bold">malabares </span>, y <span
            className="uk-text-bold">zancos</span>, vestidos con sus más bellos hábitos,<br/>
            ellos sabrán conducir bien vuestro evento y <span className='uk-text-bold'>divertir a vuestro público</span>.
        </p>
        <p>
            Viniendo todos de un país diferente, con sus leyendas y sus grandes narices,<br/>
            lo embarcarán en una <span className="uk-text-bold">deambulación elegante, poética</span> y todo con <span className='uk-text-bold'>humor</span>.
        </p>
        <p>
            No espere más, ellos están Aquí !<br/>
            Venid !! Venid !! <span className='uk-text-bold'>El Hada llega </span> !
        </p>
    </>,
    lutineryDataCostume: 'Disfraz fantástico',
    lutineryNbArtists: '2 a 3 artistas',
    lutineryDataMusic: 'Musical con instrumentos y música de fondo',

    // Les Momies
    momiesTitles: momiesTitleFR,
    momiesMetaTitle: 'Les Momies',
    momiesShortDescription: <>
        Aunque su apariencia sea polvorienta <br/>Aunque su apariencia sea polvorienta <br/>bajo su vendaje ...
    </>,
    momiesDescription: <>
        <p>
            Salidas directamente de sus tumbas en el corazón de las pirámides de Giza, <br/>
            descubre Keops y Luxor nuestras dos Momias.
            <br/>
            Al ritmo desigual del Thriller de Mickael Jackson, <br/>
            ellas te harán descubrir el <span className='uk-text-bold'>arte del mimo</span>, <br/>
            de la <span className='uk-text-bold'>Danza Contemporánea</span>, de los <span className='uk-text-bold'>Levantamientos Acrobáticos</span> y de
            la <span
            className='uk-text-bold'>Contorsión</span>.
        </p>

        <p>
            Divertidas, entrañables y un poco espeluznantes, ¡solo puedes amarlas!
            <br/>
            ¡Espectáculo y paseo de circo <span className='uk-text-bold'>atípico y único</span> lejos de los clichés!
        </p>

        <p>
            Un verdadero placer para los amantes del Antiguo Egipto y de los films sensacionalistas.
            <br/>
            ¡¡No los busques más !! ¡Tus momias están aquí!
        </p>
    </>,
    momiesNbArtists: '2 artistas',

    // L'Homme arc-en-ciel et ses anges
    rainbowManTitle: rainbowManTitleFR,
    rainbowManMetaTitle: "L'Homme arc-en-ciel et ses Anges",
    rainbowManShortDescription: <>
        Vestidos de élegancia, vienen de la luna <br/>para sublimarlo con color.
    </>,
    rainbowManDescription: <>
        <p>
            Personaje zancudo saltarín y bailarín lleno de colores, <br/>
            una <span className='uk-text-bold'>sensación de dulzura, alegría y bienestar</span> te invadirá cuando te encuentres con él.
            <br/>
            Estará acompañado por sus ángeles guardianes y misteriosos para traerte <br/>
            en un remolino de satén, de <span className='uk-text-bold'>pureza y ligereza</span>...
        </p>

        <p>
            Dos ángeles siameses para una <span className='uk-text-bold'>suave proximidad burbujeante y malabarista</span> <br/>
            sin olvidar a su papá que será parte de la fiesta vistiendo zancos y alas grandes, <br/>
            todo bajo una <span className='uk-text-bold'>lluvia de burbujas</span>.
        </p>

        <p>
            Paseo con un <span className='uk-text-bold'>carro de burbujas y sonorizado</span> impulsado por sus curiosos "Chat'crobate", malabaristas y
            descuidados en esos momentos.
            <br/>
            Una armonía de colores se ofrece a usted.
        </p>
    </>,
    rainbowManDataCostume: 'Traje luminoso',
    rainbowManNbArtists: '4 a 5 artistas',

    // Le Rêve Blanc
    reveBlancTitle: reveBlancFR,
    reveBlancMetaTitle: 'Le Rêve Blanc',
    reveBlancShortDescription: <>
        Cuando el blanco sublima la elegancia, <br/>la excelencia solo se cumple.
    </>,
    reveBlancDescription: <>
        <p>El <span className='uk-text-bold'>Lujo y el Blanco</span> en todo su esplendor.</p>

        <p>
            La Marquesa te invita a su <span className='uk-text-bold'>elegante paseo</span>.
            <br/>
            Rodeado de personajes extravagantes, déjate embarcar en el sueño blanco.
            <br/>
            Una inmersión en su universo, entre alturas y malabares, <br/>
            <span className='uk-text-bold'>humor y distinción</span>, te darán <span className='uk-text-bold'>energía y buen humor</span>!
        </p>

        <p>
            energía y buen humor, <span className='uk-text-bold'>zancudos majestuosos</span>, zancudos majestuosos <br/>
            con sus <span className='uk-text-bold'>arranques mordaces y delirantes</span>.
            <br/>
            Abran paso, "el sueño blanco" llega para sorprender a grandes y pequeños <br/>
            en un <span className='uk-text-bold'>torbellino de suavidad y elegancia</span>.
        </p>
    </>,
    reveBlancDataCostume: 'Traje luminoso',
    reveBlancNbArtists: '2 a 5 artistas',

    // M'zelle Rosalie
    rosalieTitle: rosalieTitleFR,
    rosalieMetaTitle: "M'zelle Rosalie",
    rosalieShortDescription: <>
        Con nuestros 3 amigos ... <br/>Bienvenidos a los años de 1930.
    </>,
    rosalieDescription: <>
        <p>
            Directamente de los años 30 de los estudios de cine, <br/>
            <span className='uk-text-bold'>¡¡¡La hermosa Rosalie está de paseo !!!</span>
            <br/>
            Ella acompaña a nuestros 3 amigos en los pasillos de tu evento <br/>
            y quienes no dudarán en improvisar <span className='uk-text-bold'>escenas dignas de las más grandes películas de Hollywood</span>.
            <br/>
            ¡¡Todo esto... <span className='uk-text-bold'>Todo esto</span>!!
            <br/>
            Este coche sorprenderá y encantará a más de uno con sus <span className='uk-text-bold'>múltiples sorpresas</span>.
            <br/>
            Héctor, Oscar y Bidule tienen más de una vuelta <br/>
            en su rosalie y <span className='uk-text-bold'>sabrán divertir a grandes y pequeños</span>.
        </p>

        <p>
            Un <span className='uk-text-bold'>paseo loco</span> plagado de interludios improvisados <br/>
            que entretendrán a la multitud.
            <br/>
            ¡¡¡3, 2, 1 ... Motor, está funcionando !!!
        </p>

        <p className='uk-text-bold uk-text-italic'>En <Link href={path.partners} legacyBehavior><a className='uk-link-reset text-hover-gold'>asociación</a></Link>&nbsp; con la compañía "Beblibop"</p>
    </>,
    rosalieDataCostume: '',
    rosalieNbArtist: '3 artistas',

    // Striptease / Effeuillage
    stripteaseTitle: stripteaseTitle,
    stripteaseMetaTitle: 'Striptease',
    stripteaseShortDescription: <>
        El arte del striptease, sensual <br/>y sensacional, <br/>para un momento memorable.
    </>,
    stripteaseDescription: <>
        <p>
            ¿Alguna vez te has preguntado <span className='uk-text-bold'> cómo puedo potenciar mi fiesta ?</span> Ponemos algo de música, un buen trago para
            compartir con los amigos, unos juegos ...
        </p>

        <p>
            Y ahí ..., ¿sientes que falta algo <span className='uk-text-bold'> brillante para que tus invitados se diviertan</span>?
        </p>

        <p>
            ¡¡Es la hora!!
            <br/>
            <span className='uk-text-bold'>¡Abran paso a la delicadeza y la sensualidad!</span>
        </p>

        <p>
            Déjate absorber por el carisma, la belleza y la presencia de nuestros artistas bailarines con cuerpos de ensueño sin olvidar <span
            className='uk-text-bold'>el toque de locura que sorprenderá a tus invitados</span>.
        </p>

        <p>
            Es a través de todas estas cualidades que <span className='uk-text-bold'>nuestros artistas animarán los recuerdo</span> de su cumpleaños, despedida
            de soltera o una simple velada con amigos.
            <br/>
            Manipulación de fuego, contorsiones, pole dance ... todo esto puede ocurrir y por eso, <span className='uk-text-bold'>stripping forma parte de nuestra compañía de espectáculos</span>.
        </p>

        <p>
            ¡<span className='uk-text-bold'>La celebración y el buen ambiente</span> acompañarán al uniforme o disfraz que te hará fantasear!
            <br/>
            No esperes más para <span className='uk-text-bold'>aumentar la temperatira de tu fiesta</span>.
        </p>

        <p className='uk-text-bold uk-text-italic'>
            En <Link href={path.partners} legacyBehavior><a className='uk-link-reset text-hover-gold'>colaboración</a></Link>&nbsp;
            con las compañías "El Circus", "White Tigers" y Andy Klein
        </p>
    </>,
    stripteaseDataCostume: 'Traje luminoso',
    stripteaseNbArtist: '1 artista mínimo',

    // Tarzan, Jane & Cheeta
    tarzanTitle: tarzanTitleFR,
    tarzanMetaTitle: 'Tarzan, Jane et le Gorille',
    tarzanShortDescription: <>
        Un cosplay que hará las delicias de cualquier grupo de edad <br/>con un humor poco convencional
    </>,
    tarzanDescription: <>
        <p>
            ¡Aquí un programa de "<span className='uk-text-bold'>Cosplay</span>" que mezcla el juego de lo aéreo,<br/>
            De los levantamientos acrobáticos y por supuesto la actuación <br/>
            <span className='uk-text-bold'>Una buena dosis de humor</span> y <span className='uk-text-bold'>complicidad</span> para estos 3 artistas <br/>
            que te hará viajar mientras recuerdas un clásico del cine!
        </p>

        <p>
            Una recepción, una <span className='uk-text-bold'>velada temática de "Cine"</span>, <br/>
            un festival de arte callejero, una fiesta privada...
            <br/>
            Sabrán cómo hacer que <span className='uk-text-bold'>tu audiencia se sienta cómoda</span> <br/>
            con un espíritu relajado y chic al mismo tiempo.
        </p>
    </>,
    tarzanDataCostume: 'Cosplay',
    tarzanNbArtists: '3 artistas',

    // Tim Burton
    timBurtonTitle: timTitleFR,
    timBurtonMetaTitle: "Bim Turton n'est pas loin !",
    timBurtonShortDescription: <>
        Personaje atípico <br/>Personaje atípico, <br/>Bienvenido a este imaginario ...
    </>,
    timBurtonDescription: <>
        <p>
            Aquí una <span className='uk-text-bold'>creación fantástica "Burtonesca"</span> que mezcla el arte de los zancos, <br/>
            malabares, luz y música para un <span className='uk-text-bold'>momento inolvidable</span>.
        </p>

        <p>
            Déjate guiar por "Oscar" el zancudo, <br/>
            personaje de <span className='uk-text-bold'>exuberante e hipnótico encanto</span> acompañado de su compinche "Mister Bim", el loco malabarista.
            <br/>
            Sin olvidar el Cyborg luminoso de leds y láseres "Lx-1", <br/>
            su amigo bailarín <span className='uk-text-bold'>carismático y excéntrico</span>.
        </p>

        <p>
            ¡¡Dringgg !! ¡¡bebiendo !! ¡¡dringgg !!
            <br/>
            ¡¡La hora de la hermosa "Rose" ha sonado !! muñeca seductora, hechizante y encantada, <br/>
            <span className='uk-text-bold'>¿puedes ayudarla a mantener el ritmo?</span>
            <br/>
            ¡Tenemos la solución !! "Mimo" nuestro mimo-músico podrá ayudarte !! ¡Prometido!
        </p>

        <p>
            Momentos de poesías, de proezas técnicas con sobre todo... <span className='uk-text-bold'>¡Un universo Único!</span>
            <br/>
            ¡Ustedes son los invitados de este mundo encantado!
        </p>
    </>,
    timBurtonDataCostume: 'Traje de luz LED y láser',
    timBurtonNbArtist: '2 à 5 artistas',

    // Wadioui
    wadiouiTitle: wadiouiTitleFR,
    wadiouiMetaTitle: "Les WadiOui, les lutins d'Or et d'Argent",
    wadiouiShortDescription: <>
        Los elfos de oro y plata <br/>Este grupo de alegres compañeros <br/> le harán vivir el más mágico <br/>de los momentos.
    </>,
    wadiouiDescription: <>
        <p>
            <span className='uk-text-bold'>Elfos de luz</span>, zancudos saltantes, malabaristas y burbujeadores pasean <br/>
            con sus <span className='uk-text-bold'>energías centelleantes de mil luces</span>.
        </p>

        <p>
            Los "WadiOui" son un pueblo de pequeños seres sorprendentes que invierten <br/>
            el mundo de los humanos con su <span className='uk-text-bold'>universo colorido y mágico</span>.
            <br/>
            De todos los tamaños, estos seres traviesos y risueños te sorprenderán con <span className='uk-text-bold'>su buen humor</span>.
            <br/>
            Los reconocerás fácilmente gracias a sus orejitas <br/>
            y sus encantadoras sonrisas.
        </p>

        <p>
            La neige virevolte, gilets rouges et pompons blancs sont de la partie.
            <br/>
            Les « WadiOui » arrivent pour <span className='uk-text-bold'>émerveiller grands et petits</span> !!!
        </p>
    </>,
    wadiouiDataCostume: 'Traje luminoso',
    wadiouiNbArtists: '5 a 7 artistas',

    // Wotan
    wotanTitle: wotanTitle,
    wotanMetaTitle: 'Wotan Project',
    wotanShortDescription: <>
        Sumérgete en un universo retro <br/>Sumérgete en un universo retro.
    </>,
    wotanDescription: <>
        <p>
            Un encuentro de <span className='uk-text-bold'>2 payasos y 1 señorita bien vinculados por el dominio del circo</span>, <br/>
            de los zancos, de la mímica, de la comedia y de las marionetas.
        </p>

        <p>
            Aquí está el <span className='uk-text-bold'>espectáculo ambulatorio sonoro</span> "Wotan Project".
            <br/>
            1 payaso zancudo sosteniendo 1 marioneta humana con su cruz de marioneta de madera <br/>
            que sostiene él mismo su marioneta, <br/>
            varias alturas para una mayor <span className='uk-text-bold'>diversión y participación con el público</span>.
        </p>

        <p>
            Sin olvidar a la señorita Mary que nos regalará una <span className='uk-text-bold'>multitud de pompas</span> y que hará pasar un mal rato al señor
            Calisson, <br/>
            el payaso zancudo.
        </p>

        <p>
            ¡Así que no esperes más!
            <br/>
            <span className='uk-text-bold'>¡Poesía, sonrisas y emociones son parte del juego!</span>
        </p>
    </>,
    wotanDataCostume: 'Traje del universo steampunk',
    wotanNbArtist: '2 a 3 artistas',
}

export default esShows
