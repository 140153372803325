import {Text} from "../providers/Language"
import path from './paths'

const articles = [
    {
        title: <Text id={'title240521'}/>,
        shortDescription: <Text id={'shortDescription240521'}/>,
        date: <Text id={'publishDate240521'}/>,
        path: path.article1,
        content: <Text id={'content240521'}/>,
    },
    {
        title: <Text id={'title250521'}/>,
        shortDescription: <Text id={'shortDescription250521'}/>,
        date: <Text id={'publishDate250521'}/>,
        path: path.article2,
        content: <Text id={'content250521'}/>,
    },
    {
        title: <Text id={'title010621'}/>,
        shortDescription: <Text id={'shortDescription010621'}/>,
        date: <Text id={'publishDate010621'}/>,
        path: path.article3,
        content: <Text id={'content010621'}/>,
    },
    {
        title: <Text id={'title270421'}/>,
        shortDescription: <Text id={'shortDescription270421'}/>,
        date: <Text id={'publishDate270421'}/>,
        path: path.article4,
        content: <Text id={'content270421'}/>,
    },
]

export default articles