const esHeader = {
    // HEAD BAR
    headbarDesc: 'Espectáculos de circo/compañías de teatro internacionales/vagabundos',

    // HEADER MENU
    navbarHome: 'Home',
    navbarShows: 'Nuestros espectáculos',
    navbarWorkshops: 'Talleres',
    navbarTroupe: 'La compañía',
    navbarContact: 'Contacto',

    // MOBILE MENU
    navAllShows: 'Todos los espectáculos',
    navAllWorkshops: 'Todos los talleres',
    navAllEvents: 'Todos los eventos',
    navAllThemes: 'Todos los temas',
    navAllActivities: 'Todas las animaciones',

    navDesktopAllShows: 'Ver todos los espectáculos',

    // OUR SHOWS MENU
    navEventsColumn: 'EVENTOS',
    navEventValentine: 'Día de San Valentín',
    navEventHalloween: 'Halloween',
    navEventWedding: 'Boda',
    navEventCarnival: 'Carnaval',
    navEventProduct: 'Inauguración / Lanzamiento de producto',
    navEventChristmasMarket: 'Mercado de Navidad',
    navEventClubbing: 'Discoteca',
    navEventStreetFestival: 'Festival de rue',
    navEventMusicFestival: 'Festival de Música',
    navEventMovieFestival: 'Festival de Cine',
    navEventWitchDay: 'Fiesta de las brujas',
    navEventTattooFair: 'Salón de tatuajes',
    navEventBirthday: 'Anniversaire',
    navEventEroticismFair: 'Salón erótico',
    navEventPrivateShow: 'Veladas privadas, C.E., gala ...',
    navEventNewYear: 'Año Nuevo',
    navEventSkiResort: 'Estación de esquí',
    navEventParentsDay : 'Día del Padre / Madre',
    navEventNationalDay: 'Fiesta Nacional',
    navEventEaster: 'Pascuas',

    navThemeColumn: 'TEMAS',
    navThemeChristmas: 'Navidad',
    navThemeMedieval: 'Medieval',
    navThemeSteampunk: 'Steampunk',
    navThemeCinema: 'Cine',
    navThemeDiaDeLosMuertos: 'Día de los Muertos',
    navThemeWhite: 'Blanco',
    navThemeLight: 'Luminoso',
    navThemeFantasy: 'Fantástico',
    navThemeMusic: 'Musique',
    navThemeFuturistic: 'Futurista',
    navThemeChic: 'Chic / Elegante',
    navThemeLatexVinyl: 'Látex y Vinilo',
    navThemeAngelImp: 'Ángel y diablillo',
    navThemeLove: 'Amor',
    navThemeHorror: 'Escalofríos / Pavor',
    navThemeCosplay: 'Cosplay',

    navActivityColumn: 'ANIMACIONES',
    navActivityStilt: 'Zancos',
    navActivityJuggling: 'Malabarismo',
    navActivityMusic: 'Música',
    navActivityMime: 'Mímica',
    navActivityClown: 'Payaso',
    navActivityMakeup: 'Maquillaje',
    navActivitySoapBubbles: 'Burbujas de jabón',
    navActivityPerformer: 'Actuación',
    navActivityBalloonSculpture: 'Escultura con globos',
    navActivityMagic: 'Magia',
    navActivityFireBreather: 'Tragafuegos',
    navActivityCharAmbulatory: 'Tanque ambulatorio',
    navActivityWorkshop: 'Talleres de trabajo',
    navActivityTheater: 'Teatro',
    navActivityStrip: 'Strip artístico',
    navActivityPyrotechnics: 'Fuego / pirotecnia',
    navActivityDance: 'Danza',
    navActivityShoppingGallery: 'Centro comercial',
}

export default esHeader