const frHeader = {
    // HEAD BAR
    headbarDesc: 'Compagnie de cirque/théâtre international spectacles/déambulations',

    // HEADER MENU
    navbarHome: 'Accueil',
    navbarShows: 'Nos spectacles',
    navbarWorkshops: 'Activités',
    navbarTroupe: 'La compagnie',
    navbarContact: 'Contact',

    // MOBILE MENU
    navAllShows: 'Tous les spectacles',
    navAllWorkshops: 'Toutes les activités',
    navAllEvents: 'Tous les évènements',
    navAllThemes: 'Tous les thèmes',
    navAllActivities: 'Toutes les animations',

    navDesktopAllShows: 'Voir tous les spectacles',

    // OUR SHOWS MENU
    navEventsColumn: 'Par évènements',
    navEventValentine: 'Saint-Valentin',
    navEventHalloween: 'Halloween',
    navEventWedding: 'Mariage',
    navEventCarnival: 'Carnaval',
    navEventProduct: 'Inauguration / Lancement de produits',
    navEventChristmasMarket: 'Marché de Noël',
    navEventClubbing: 'Discothèque',
    navEventStreetFestival: 'Festival de rue',
    navEventMusicFestival: 'Festival de musique',
    navEventMovieFestival: 'Fête du cinéma',
    navEventWitchDay: 'Fête des sorcières',
    navEventTattooFair: 'Salon du tatouage',
    navEventBirthday: 'Anniversaire',
    navEventEroticismFair: 'Salon érotisme',
    navEventPrivateShow: 'Soirées privées, C.E., gala...',
    navEventNewYear: 'Nouvel An',
    navEventSkiResort: 'Station de ski',
    navEventParentsDay : 'Fêtes des pères / mères',
    navEventNationalDay: 'Fête Nationale',
    navEventEaster: 'Pâques',

    navThemeColumn: 'Par thèmes',
    navThemeChristmas: 'Noël',
    navThemeMedieval: 'Médiéval',
    navThemeSteampunk: 'Steampunk',
    navThemeCinema: 'Cinéma',
    navThemeDiaDeLosMuertos: 'Dia de los Muertos',
    navThemeWhite: 'Blanc',
    navThemeLight: 'Lumineux',
    navThemeFantasy: 'Fantastique',
    navThemeMusic: 'Musique',
    navThemeFuturistic: 'Futuriste',
    navThemeChic: 'Chic / Élégant',
    navThemeLatexVinyl: 'Latex et Vinyl',
    navThemeAngelImp: 'Ange et diablotin',
    navThemeLove: 'Amour',
    navThemeHorror: 'Frissons / Épouvante',
    navThemeCosplay: 'Cosplay',

    navActivityColumn: 'Par animations',
    navActivityStilt: 'Échasses',
    navActivityJuggling: 'Jonglerie',
    navActivityMusic: 'Musique',
    navActivityMime: 'Mime',
    navActivityClown: 'Clown',
    navActivityMakeup: 'Maquillage',
    navActivitySoapBubbles: 'Bulles de savon',
    navActivityPerformer: 'Performeur',
    navActivityBalloonSculpture: 'Sculpture sur ballons',
    navActivityMagic: 'Magie',
    navActivityFireBreather: 'Cracheur de feu',
    navActivityCharAmbulatory: 'Char déambulatoire',
    navActivityWorkshop: 'Ateliers',
    navActivityTheater: 'Théâtre',
    navActivityStrip: 'Strip / effeuillage',
    navActivityPyrotechnics: 'Feu / pyrotechnie',
    navActivityDance: 'Danse',
    navActivityShoppingGallery: 'Galerie Marchande',
}

export default frHeader